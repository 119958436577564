import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { CashDiscountInterface, PaymentTermsInterface } from './payment-terms.interface';

@Component({
  selector: 'payment-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit, OnChanges {
  open = false;

  forceOpen = false;

  label = '';

  @Input() terms: PaymentTermsInterface;

  @Input() numberOfCashDiscounts = 1;

  @Input() enabled = true;

  @Output() termsSaved = new EventEmitter<PaymentTermsInterface>();

  constructor() {}

  ngOnInit(): void {
    this.label = this.generateLabel(this.terms);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.terms = changes.terms?.currentValue;
    this.label = this.generateLabel(this.terms);
  }

  onOpen(state) {
    this.open = state;
  }

  toggleOpen() {
    if (!this.enabled) {
      return;
    }
    this.open = !this.open;
    this.forceOpen = this.open;
  }

  showPlaceholder(): boolean {
    return !this.terms || _.isNull(this.terms.net) || this.terms.net === 0;
  }

  generateLabel(terms: PaymentTermsInterface): string {
    let label = `${terms.net} net`;
    _.forEach(terms.discounts, (discount: CashDiscountInterface) => {
      if (!_.isNull(discount.days) && !_.isNull(discount.rate)) {
        label += ` / ${discount.days}: ${discount.rate}%`;
      }
    });

    return label;
  }

  termsChanged(terms: PaymentTermsInterface) {
    this.terms = terms;
    this.label = this.generateLabel(this.terms);
    this.termsSaved.emit(this.terms);
    this.toggleOpen();
  }
}
