<div class="collapse-section">
  <div style="display: flex; flex: 1" (click)="project.isOpen = !project.isOpen">
    <troi-icon
      *ngIf="project.isOpen"
      class="collapse-section__icon"
      icon="icon-bold-arrow-down"
    ></troi-icon>
    <troi-icon
      *ngIf="!project.isOpen"
      class="collapse-section__icon"
      icon="icon-bold-arrow-right"
    ></troi-icon>
    <span>{{ 'Common.labels.project' | translate }} {{ index }}</span>
  </div>
  <troi-icon
    class="icon-trash-can troi-dropdown-select"
    icon="icon-trash-can"
    (click)="delete()"
    [small]="true"
    *ngIf="enabled"
  ></troi-icon>
</div>
<div class="project" [ngClass]="{ hidden: !project.isOpen }">
  <troi-modal-row>
    <div row-label>{{ 'Common.labels.customer' | translate }}</div>
    <div row-value class="project__row">
      <troi-dropdown-select-lazy
        [filterObject]="client"
        dataType="CUSTOMERS"
        [initValue]="project?.getCustomerId()"
        [predefinedOptions]="projectAssignmentData.generateDropdownOption(project?.customer)"
        (selected)="onCustomerChange($event)"
        [withSearch]="true"
        [enabled]="enabled"
        [clearButton]="false"
      >
      </troi-dropdown-select-lazy>
    </div>
  </troi-modal-row>
  <troi-modal-row>
    <div row-label>{{ 'Common.labels.project' | translate }}</div>
    <div row-value class="project__row">
      <troi-dropdown-select-lazy
        [enabled]="project.getCustomerId() !== null && enabled && !hasSelectedAutoCustomer"
        [filterObject]="project?.getCustomerId()"
        dataType="PROJECTS_BY_CUSTOMER"
        [reloadInitData]="project?.getCustomerId()"
        [translatableName]="true"
        [initValue]="project?.getProjectId()"
        (selected)="onProjectChange($event)"
        [predefinedOptions]="projectAssignmentData.generateDropdownOption(project?.project)"
        [withSearch]="true"
        [lang]="projectAssignmentData.languagesService.getLanguage()"
        [clearButton]="false"
      >
      </troi-dropdown-select-lazy>
    </div>
  </troi-modal-row>
  <troi-pa-subproject
    *ngFor="let subproject of project?.subprojects; let i = index"
    [subproject]="subproject"
    [parent]="project?.getProjectId()"
    [client]="client"
    [index]="i + 1"
    (remove)="removeSubproject($event)"
    [customer]="project?.getCustomerId()"
    (taxChanged)="taxChanged.emit($event)"
    [unassignedAmount]="unassignedAmount"
    [isNet]="isNet"
    [netTotalAmount]="netTotalAmount"
    [enabled]="enabled && !hasSelectedAutoCustomer"
    [hasAutoCustomer]="hasSelectedAutoCustomer"
  ></troi-pa-subproject>
  <troi-modal-row
    class="add-subproject"
    [ngClass]="{ 'add-subproject__disabled': project.getProjectId() === null }"
    *ngIf="enabled && !hasSelectedAutoCustomer"
  >
    <div row-label></div>
    <div row-value *ngIf="!hasSelectedAutoCustomer">
      <div (click)="addSubproject()">
        <troi-icon [small]="true" icon="icon-plus"></troi-icon>
        {{ 'Booking.labels.addSubproject' | translate }}
      </div>
    </div>
  </troi-modal-row>
</div>
