import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AddProjectResultInterface } from '../../../../interfaces/add-project-result.interface';
import { AddProjectByKnumberService } from '../../../../services/add-project-by-knumber.service';
import { AddProjectSubscriber } from '../add-project.subscriber';
import { CPOptionInterface } from '../../../../interfaces/project-list.interface';

@Component({
  selector: 'troi-add-project-by-knumber',
  templateUrl: './add-project-by-knumber.component.html',
  styleUrls: ['./add-project-by-knumber.component.scss'],
})
export class AddProjectByKnumberComponent implements OnInit, OnDestroy {
  searchInputSubject = new Subject<string>();

  open = false;

  private addProjectSubscription: Subscription;

  @Input() client: number;

  @Input() enabled = true;

  @Output() public dataSelected = new EventEmitter<AddProjectResultInterface>();

  constructor(
    public addProjectByKnumber: AddProjectByKnumberService,
    public addProjectSubscriber: AddProjectSubscriber,
  ) {
    this.searchInputSubject.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      if (this.addProjectByKnumber.search.length > 0) {
        this.addProjectByKnumber.initialLoadCPs();
        this.openChange(true);
      }
    });
  }

  openChange(state: boolean) {
    if (!this.enabled) {
      return;
    }
    this.open = state;
  }

  getValueForSelectedOption(): string {
    return this.addProjectByKnumber.getTransValue(this.addProjectByKnumber.selectedCP.name);
  }

  clearValue() {
    this.addProjectByKnumber.clearValue();
  }

  addProjectAssignment() {
    if (!this.addProjectByKnumber.selectedCP) {
      return;
    }
    this.dataSelected.emit({
      projectReactivation: this.addProjectByKnumber.projectReactivation,
      customer: this.addProjectByKnumber.selectedCP.customer,
      project: this.addProjectByKnumber.selectedCP.project,
      subproject: this.addProjectByKnumber.selectedCP.subproject,
      cp: this.addProjectByKnumber.selectedCP.id,
    });
    this.clearValue();
  }

  ngOnInit(): void {
    this.addProjectSubscription = this.addProjectSubscriber.selectCalculationPosition.subscribe(
      (selectedCp: CPOptionInterface) => {
        this.addProjectByKnumber.selectedCP = selectedCp;
      },
    );
    this.addProjectByKnumber.clearValue();
    this.addProjectByKnumber.client = this.client;
  }

  ngOnDestroy() {
    this.addProjectSubscription.unsubscribe();
  }
}
