<form class="add-edit" *ngIf="addEditService.form" [formGroup]="addEditService.form">
  <troi-modal-header [isSecond]="isOpenedAsSecond">
    <span *ngIf="modalObject.type !== ModalType.EDIT">{{
      'Booking.labels.newAccount' | translate
    }}</span>
    <span *ngIf="modalObject.type === ModalType.EDIT">{{
      'Booking.labels.editAccount' | translate
    }}</span>
  </troi-modal-header>
  <troi-modal-content style="overflow-y: auto">
    <troi-modal-section label="{{ 'Booking.labels.baseInfo' | translate }}" [forceOpen]="true">
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.accountNumber' | translate }}</div>
        <div row-value class="add-edit__row">
          <troi-input-field
            row-value
            [numbersOnly]="true"
            [fieldInvalid]="addEditService.form.controls.number.errors && submitted"
            [validationEnabled]="true"
            formControlName="number"
          ></troi-input-field>
        </div>
      </troi-modal-row>
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.accountGroup' | translate }}</div>
        <div row-value class="add-edit__row">
          <troi-dropdown-select
            [search]="true"
            [noBorder]="true"
            formControlName="group"
            [size]="'11px'"
            [fieldInvalid]="addEditService.form.controls.group.errors && submitted"
            [validationEnabled]="true"
            placeholder="{{ 'Booking.labels.pleaseSelect' | translate }}"
            class="troi-dropdown-select--full"
            [options]="accountingSettingsService.buildAccountGroupList()"
          ></troi-dropdown-select>
        </div>
      </troi-modal-row>
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.accountCompanyName' | translate }}</div>
        <div row-value class="add-edit__row">
          <troi-input-field
            [fieldInvalid]="addEditService.form.controls.name.errors && submitted"
            [validationEnabled]="true"
            row-value
            formControlName="name"
          ></troi-input-field>
        </div>
      </troi-modal-row>
    </troi-modal-section>
    <troi-modal-section label="{{ 'Booking.labels.details' | translate }}" [forceOpen]="true">
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.defaultCounterAccount' | translate }}</div>
        <div row-value class="add-edit__row">
          <troi-dropdown-select
            [noBorder]="true"
            [size]="'11px'"
            [search]="true"
            [clearButton]="true"
            formControlName="counterAccount"
            placeholder="{{ 'Booking.labels.pleaseSelect' | translate }}"
            class="troi-dropdown-select--full"
            [options]="buildAccountList()"
          ></troi-dropdown-select>
        </div>
      </troi-modal-row>
      <troi-modal-row
        *ngIf="
          accountingSettingsService.settings?.settings.isSummaryAccountEnabled &&
          displaySummaryAccountDropdown()
        "
      >
        <div row-label>{{ 'Booking.labels.summaryAccount' | translate }}</div>
        <div row-value class="add-edit__row">
          <troi-dropdown-select
            [validationEnabled]="true"
            [fieldInvalid]="!addEditService.form.controls.summaryAccount.value && submitted"
            [noBorder]="true"
            [size]="'11px'"
            [search]="true"
            [clearButton]="true"
            formControlName="summaryAccount"
            placeholder="{{ 'Booking.labels.pleaseSelect' | translate }}"
            class="troi-dropdown-select--full"
            [options]="buildAccountList()"
          ></troi-dropdown-select>
        </div>
      </troi-modal-row>
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.taxKey' | translate }}</div>
        <div row-value class="add-edit__row">
          <troi-dropdown-select
            [clearButton]="true"
            [noBorder]="true"
            [size]="'11px'"
            [search]="true"
            formControlName="tax"
            placeholder="{{ 'Booking.labels.pleaseSelect' | translate }}"
            class="troi-dropdown-select--full"
            [options]="accountingSettingsService.buildTaxKeyList()"
          ></troi-dropdown-select>
        </div>
      </troi-modal-row>
      <troi-modal-row *ngIf="addEditService.form.value.cashAccount">
        <div row-label>{{ 'Booking.labels.openingBalance' | translate }}</div>
        <div row-value class="add-edit__row add-edit__row__inline">
          <troi-dropdown-select
            [noBorder]="true"
            [size]="'11px'"
            formControlName="openingBalanceYear"
            placeholder="{{ 'Booking.labels.year' | translate }}"
            class="troi-dropdown-select--full add-edit__row__inline__year"
            [options]="accountingSettingsService.buildBookingYears()"
          ></troi-dropdown-select>
          <troi-input-field
            class="add-edit__row__inline__input"
            [value]="addEditService.getOpeningBalanceModelSelectedForYear().value.formattedValue"
            (change)="updateOpeningBalance($event.target.value)"
            money-format
            [money]="addEditService.getOpeningBalanceModelSelectedForYear().value"
            [currency]="accountingSettingsService.systemCurrency"
          ></troi-input-field>
          <div class="add-edit__row__inline__currency">
            {{ accountingSettingsService.systemCurrency.symbol }}
          </div>
        </div>
      </troi-modal-row>
    </troi-modal-section>
    <troi-modal-section
      label="{{ 'Booking.labels.administrativeDetails' | translate }}"
      [forceOpen]="true"
    >
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.cashAccount' | translate }}</div>
        <troi-switch row-value formControlName="cashAccount" small></troi-switch>
      </troi-modal-row>
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.bankAccount' | translate }}</div>
        <troi-switch row-value formControlName="bankAccount" small></troi-switch>
      </troi-modal-row>
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.automaticAccount' | translate }}</div>
        <troi-switch row-value formControlName="automaticAccount" small></troi-switch>
      </troi-modal-row>
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.depositAccount' | translate }}</div>
        <troi-switch row-value formControlName="depositAccount" small></troi-switch>
      </troi-modal-row>
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.datevLock' | translate }}</div>
        <troi-switch row-value formControlName="datevLock" small></troi-switch>
      </troi-modal-row>
      <troi-modal-row>
        <div row-label>{{ 'Booking.labels.active' | translate }}</div>
        <troi-switch row-value formControlName="active" small></troi-switch>
      </troi-modal-row>
    </troi-modal-section>
  </troi-modal-content>

  <troi-modal-footer>
    <troi-btn
      class="add-edit__button"
      *ngIf="modalObject.type === ModalType.EDIT"
      [disabled]="!canBeDeleted"
      [outline]="true"
      [disableTooltip]="canBeDeleted"
      [ngbTooltip]="prepareNotDeletableMessages()"
      (click)="canBeDeleted && delete()"
    >
      {{ 'Booking.labels.delete' | translate }}
    </troi-btn>
    <troi-btn class="add-edit__button" (click)="save()">
      {{ 'Booking.labels.save' | translate }}
    </troi-btn>
  </troi-modal-footer>
</form>
