import * as _ from 'lodash';
import { BookingCustomer, BookingProject } from '../interfaces/booking-receipt-full.interface';
import { SubprojectModel } from './subproject.model';
import { PABasicModel } from './PA-basic.model';

export class ProjectModel extends PABasicModel {
  public isOpen = true;

  constructor(
    public project: BookingProject,
    public customer: BookingCustomer,
    public client: number,
    public subprojects: SubprojectModel[],
    public defaultTax = null,
    public defaultCounterAcount = null,
  ) {
    super();
  }

  change(data: BookingProject) {
    this.project = data;
    this.subprojects = [];
  }

  customerChange(customer: BookingCustomer) {
    this.customer = customer;
    this.change(null);
  }

  getProjectId() {
    return this.getDropdownId(this.project);
  }

  getCustomerId() {
    return this.getDropdownId(this.customer);
  }

  private getDropdownId(data) {
    return data ? (data.id ? data.id : data) : null;
  }

  addNextSubproject() {
    this.subprojects.push(new SubprojectModel(null, [], this.defaultTax, this.client, this.defaultCounterAcount));
  }

  collapseSubprojectSections() {
    _.forEach(this.subprojects, (subproject: SubprojectModel) => {
      subproject.isOpen = false;
    });
  }

  removeSubproject(id: string) {
    this.subprojects = this.removeObject(this.subprojects, id);
  }
}
