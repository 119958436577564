import { ElementRef, EventEmitter, Injector } from '@angular/core';
import { TroiDropDownCloseService } from '../../../../../../shared/troi-dropdown-list/services/troi-dropdown-close.service';
import { BookingSettingsService } from '../../../services/booking-settings.service';
import { DomService } from '../../../../../../shared/troi-modals/dom.service';
import { AddProjectByKnumberService } from '../../../services/add-project-by-knumber.service';
import { AddProjectSubscriber } from '../add-project/add-project.subscriber';
import { find } from 'lodash';
import { CPOptionInterface } from '../../../interfaces/project-list.interface';
import { ModalService } from '../../../../../../shared/troi-modals/modal.service';
import { BookOnBlockedComponent } from '../add-project/book-on-blocked/book-on-blocked.component';
import { AddProjectByOrderNumberService } from '../../../services/add-project-by-order-number.service';

export abstract class BaseAddProjectListComponent {
  protected element: ElementRef;

  public openState = false;

  protected optionList: ElementRef;

  protected optionWrapper: ElementRef;

  protected openChange: EventEmitter<boolean>;

  protected troiDropDownCloseService: TroiDropDownCloseService;

  protected bookingSettingsService: BookingSettingsService;

  protected domService: DomService;

  protected addProjectSubscriber: AddProjectSubscriber;

  protected constructor(
    element: ElementRef,
    protected injector: Injector,
    public addProjectByService: AddProjectByKnumberService | AddProjectByOrderNumberService,
  ) {
    this.element = element;

    this.troiDropDownCloseService = injector.get(TroiDropDownCloseService);
    this.bookingSettingsService = injector.get(BookingSettingsService);
    this.domService = injector.get(DomService);
    this.addProjectSubscriber = injector.get(AddProjectSubscriber);

    this.troiDropDownCloseService.getEmitter().subscribe((event) => {
      const clickedElement = find(
        event.path || event.composedPath(),
        (path) => path === this.element.nativeElement || path === this.element.nativeElement.parentElement,
      );

      if (!clickedElement) {
        this.hideModal();
      }
      event.stopPropagation();
    });
  }

  public calculateScrollPosition() {
    const currentScrollValue =
      this.optionList?.nativeElement.scrollHeight +
      20 -
      (this.optionWrapper?.nativeElement.scrollTop + this.optionWrapper?.nativeElement.clientHeight);
    if (!this.addProjectByService.isLoading && currentScrollValue <= 15) {
      this.addProjectByService.incrementPageForCPs();
    }
  }

  protected hideModal() {
    this.openState = false;
    this.openChange.emit(this.openState);
  }

  public onOptionSelected(selectedCP: CPOptionInterface) {
    if (selectedCP.isBlocked && this.bookingSettingsService.settings.settings.bookOnBlockedProjectsAfterConfirmation) {
      const secondModalService = new ModalService(this.domService);
      secondModalService.init(
        BookOnBlockedComponent,
        {
          statuses: this.bookingSettingsService.settings.dropdowns.projectStatuses,
          selectedCalculationPosition: selectedCP,
        },
        {},
        '600px',
        '500px',
        true,
      );
    } else {
      this.addProjectSubscriber.selectCalculationPosition.next(selectedCP);
      this.hideModal();
    }
  }
}
