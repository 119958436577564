<div class="project-list" #optionWrapper (scroll)="calculateScrollPosition()">
  <div #optionList>
    <div
      class="project-list__elem"
      *ngFor="let project of searchService.projects"
      (click)="onOpenSublist(project)"
    >
      <span class="project-list__elem__title">{{
        languagesService.getLanguageValue(project.name)
      }}</span>
      <span *ngIf="hasProjectSubProjects(project)" class="project-list__elem__arrow"> > </span>
    </div>
    <troi-loading-spinner *ngIf="searchService.isLoading"></troi-loading-spinner>
  </div>
</div>
<troi-add-project-sublist
  *ngIf="openSublist"
  (openChange)="onSubprojectSelected($event)"
  [searchService]="searchService"
></troi-add-project-sublist>
