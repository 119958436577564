import { Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { DataTypeEnum } from '../../../../core/enums/data-type.enum';
import { BasicFiltersService } from '../../../../core/services/basic-filters.service';
import { FooterSettingsService } from '../../../../core/services/footer-settings.service';
import { ParentWindowRef } from '../../../../core/services/parent-window-ref.service';
import { TabIdsEnum, TabsService } from '../../../../core/services/tabs.service';
import { StorageService } from '../../../../core/storage/storage.service';
import { FiltersInterface } from '../../../../shared/troi-data-listing-filters/filters.interface';
import { TabInterface } from '../../../../shared/troi-data-listing-tabs/tab.interface';
import { TroiDropdownListModel } from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TroiFilterSetsNetworkService } from '../../../../shared/troi-filter-sets/network/troi-filter-sets.network';
import { FilterElementInterface } from '../../../../shared/troi-filter-with-modal/filter-element.interface';
import { FilterTypeEnum } from '../../../../shared/troi-filter-with-modal/filter-type.enum';
import { Money } from '../../../../shared/troi-money/money';
import { CommonFiltersService } from '../../../common/services/common-filters.service';
import { BookingsDataSelectedInterface } from '../interfaces/bookings-data-selected.interface';
import { BookingsCommonNetwork } from '../networks/bookings-common.network';
import { BookingSettingsService } from './booking-settings.service';
import { PaymentMethodsService } from './payment-methods.service';

@Injectable()
export abstract class BookingReceiptsFilters extends CommonFiltersService implements OnDestroy {
  protected applyKskFilter = false;

  activeTab: TabIdsEnum;

  protected fetchSelectedSubscription: Subscription;

  private paymentMethodFilter: FilterElementInterface = {
    type: FilterTypeEnum.DROPDOWN,
    label: 'Booking.labels.paymentMethod',
    formName: 'paymentMethod',
    value: '',
    defaultValue: '',
    chips: [
      {
        label: 'Booking.labels.paymentMethod',
        value: '',
        valueIsTranslatable: true,
      },
    ],
    dropdownData: [],
    preloadedOptions: [],
  };

  protected constructor(
    public basicFiltersService: BasicFiltersService,
    public parentWindowRef: ParentWindowRef,
    public footerSettingsService: FooterSettingsService,
    public settingsService: BookingSettingsService,
    public localStorage: StorageService,
    public paymentMethods: PaymentMethodsService,
    public tabsService: TabsService,
    public commonNetwork: BookingsCommonNetwork,
    protected filterSetsNetworkService: TroiFilterSetsNetworkService,
  ) {
    super(basicFiltersService, parentWindowRef, footerSettingsService, localStorage, filterSetsNetworkService);
  }

  resetFilters(clientId?: number) {
    this.actualFilters.filters = this.defaultFilterValues().filters;
    this.convertRangeFiltersToMoney();
    this.actualFilters.search = this.defaultFilterValues().search;
    this.actualFilters.dropdownFirst = clientId || this.getDefaultClientId();
    this.resetPagination();
  }

  convertRangeFiltersToMoney() {
    _.forEach(this.actualFilters.filters, (filterElement: FilterElementInterface) => {
      if (filterElement.type === FilterTypeEnum.RANGE) {
        _.forEach(filterElement.value, (value, index) => {
          if (!_.isNull(value) && value !== '') {
            filterElement.value[index] = this.createMoney(value);
          } else if (value === '') {
            filterElement.value[index] = null;
          }
        });
        _.forEach(filterElement.defaultValue, (value, index) => {
          if (!_.isNull(value)) {
            filterElement.defaultValue[index] = this.createMoney(value);
          }
        });
      }
    });
  }

  createMoney(value) {
    if (value instanceof Money) {
      return value;
    }
    return super.createMoney(
      value,
      this.settingsService.systemCurrency,
      this.settingsService.settings.settings.decimalPlacesNumber,
    );
  }

  getSelectedYear(): string {
    return this.actualFilters.dropdownSecond.toString();
  }

  generateSecondDropdown(): Array<TroiDropdownListModel> {
    return this.settingsService.buildBookingYears();
  }

  defaultFilterValues(): FiltersInterface {
    return {
      filters: this.defaultFilters(),
      search: '',
      dropdownFirst: null,
      dropdownSecond: new Date().getFullYear(),
      currentPage: 1,
      pageSize: this.getPageSize(),
      sortBy: 'number',
      sortingDir: 'desc',
    };
  }

  protected defaultFilters(): FilterElementInterface[] {
    const defaultFilters = [
      {
        type: FilterTypeEnum.RANGE_STRING,
        label: 'Booking.recorded.intNumber',
        formName: 'internalNumber',
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'Booking.labels.intNumberFrom',
            value: undefined,
            valueIsTranslatable: true,
          },
          {
            label: 'Booking.labels.intNumberTo',
            value: undefined,
            valueIsTranslatable: true,
          },
        ],
        withPlaceholderYear: true,
      },
      {
        type: FilterTypeEnum.RANGE,
        label: 'Booking.recorded.invoiceAmount',
        formName: 'amount',
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'Booking.labels.amountFrom',
            value: undefined,
          },
          {
            label: 'Booking.labels.amountTo',
            value: undefined,
          },
        ],
      },
      {
        type: FilterTypeEnum.RANGE_DATE,
        label: 'Booking.labels.receiptDate',
        formName: 'invoiceDate',
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'Booking.labels.from',
            value: undefined,
          },
          {
            label: 'Booking.labels.to',
            value: undefined,
          },
        ],
      },
      {
        type: FilterTypeEnum.RANGE_DATE,
        label: 'Booking.labels.createdAt',
        formName: 'createdAt',
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'Booking.labels.createdFrom',
            value: undefined,
          },
          {
            label: 'Booking.labels.createdTo',
            value: undefined,
          },
        ],
      },
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Common.labels.costCenter',
        formName: 'costCenter',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Common.labels.costCenter',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.COST_CENTERS,
        dropdownData: [],
        preloadedOptions: [],
      },
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Common.labels.account',
        formName: 'account',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Common.labels.account',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.ACCOUNTS,
        dropdownData: [],
        preloadedOptions: [],
      },
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Common.labels.counterAccount',
        formName: 'counterAccount',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Common.labels.counterAccount',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.ACCOUNTS,
        dropdownData: [],
        preloadedOptions: [],
      },
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Common.labels.accountGroup',
        formName: 'accountGroup',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Common.labels.accountGroup',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.ACCOUNT_GROUPS,
        dropdownData: [],
        preloadedOptions: [],
      },
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Common.labels.supplier',
        formName: 'supplier',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Common.labels.supplier',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.SUPPLIER,
        dropdownData: [],
        preloadedOptions: [],
      },
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Booking.labels.paidBy',
        formName: 'paidBy',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Booking.labels.paidBy',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.EMPLOYEES,
        dropdownData: [],
        preloadedOptions: [
          {
            label: 'Booking.labels.any',
            value: 'any',
            active: true,
          },
        ],
      },
      this.paymentMethodFilter,
    ];

    this.checkForClientRec();

    if (!this.applyKskFilter) {
      return defaultFilters;
    }

    const kskFilter = {
      type: FilterTypeEnum.DROPDOWN,
      label: 'Booking.protocol.ksk',
      formName: 'ksk',
      value: '',
      defaultValue: '',
      dropdownData: this.buildKSKOptionsList(),
      preloadedOptions: [],
      chips: [
        {
          label: 'Booking.protocol.ksk',
          value: '',
          valueIsTranslatable: true,
        },
      ],
    };

    return [kskFilter, ...defaultFilters];
  }

  assignApprovalsFilters() {
    if (!this.settingsService?.settings?.approval?.booking?.isEnabled) {
      return this.defaultFilters();
    }
    const approvalFilters = [
      {
        type: FilterTypeEnum.DROPDOWN,
        label: 'Booking.labels.approvalStatus',
        formName: 'approvalState',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Booking.labels.approvalStatus',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dropdownData: this.buildApprovalStatusesList(),
        preloadedOptions: [],
      },
      {
        type: FilterTypeEnum.SWITCH,
        label: 'Approval.labels.showOnlyMyPending',
        formName: 'showOnlyMyPending',
        // @ts-ignore
        value: false,
        // @ts-ignore
        defaultValue: false,
        chips: [
          {
            label: 'Approval.labels.showOnlyMyPending',
            value: '',
            valueIsTranslatable: true,
          },
        ],
      },
    ];
    return [...this.defaultFilters(), ...approvalFilters];
  }

  buildKSKOptionsList(): Array<TroiDropdownListModel> {
    const list = [];
    list.push({
      label: 'Booking.protocol.showAll',
      value: '',
      active: true,
    });
    list.push({
      label: 'Booking.protocol.onlyWithKSK',
      value: 'true',
      active: true,
    });
    list.push({
      label: 'Booking.protocol.withoutKSK',
      value: 'false',
      active: true,
    });

    return list;
  }

  buildApprovalStatusesList() {
    return [
      {
        label: 'Booking.labels.pleaseSelect',
        value: '',
        active: true,
      },
      {
        label: 'Approval.filters.state.notStarted',
        value: 'n',
        active: true,
      },
      {
        label: 'Approval.filters.state.pending',
        value: 'p',
        active: true,
      },
      {
        label: 'Approval.filters.state.declined',
        value: 'd',
        active: true,
      },
      {
        label: 'Approval.filters.state.approved',
        value: 'a',
        active: true,
      },
      {
        label: 'Approval.filters.state.cancelled',
        value: 'c',
        active: true,
      },
    ];
  }

  applyFiltersFromUrl() {
    this.prepareTempUrl();
    this.fetchSelectedData(this.actualFilters);
  }

  prepareTempUrl() {
    const tempUrl = new URL(this.pageUrl.href);

    this.actualFilters = this.applyCommonUrlFilters(tempUrl, this.actualFilters);

    if (tempUrl.searchParams.get('year')) {
      this.actualFilters.dropdownSecond = parseInt(tempUrl.searchParams.get('year'), 10);
    }

    if (tempUrl.searchParams.get('costCenter')) {
      const costCenterIndex = this.getFilterParamIndex('costCenter');
      this.actualFilters.filters[costCenterIndex].value = tempUrl.searchParams.get('costCenter');
    }

    if (tempUrl.searchParams.get('account')) {
      const accountIndex = this.getFilterParamIndex('account');
      this.actualFilters.filters[accountIndex].value = tempUrl.searchParams.get('account');
    }

    if (tempUrl.searchParams.get('counterAccount')) {
      const counterAccountIndex = this.getFilterParamIndex('counterAccount');
      this.actualFilters.filters[counterAccountIndex].value = tempUrl.searchParams.get('counterAccount');
    }

    if (tempUrl.searchParams.get('supplier')) {
      const supplierIndex = this.getFilterParamIndex('supplier');
      this.actualFilters.filters[supplierIndex].value = tempUrl.searchParams.get('supplier');
    }

    if (tempUrl.searchParams.get('paidBy')) {
      const paidByIndex = this.getFilterParamIndex('paidBy');
      this.actualFilters.filters[paidByIndex].value = tempUrl.searchParams.get('paidBy');
    }

    if (tempUrl.searchParams.get('accountGroup')) {
      const accountGroupIndex = this.getFilterParamIndex('accountGroup');
      this.actualFilters.filters[accountGroupIndex].value = tempUrl.searchParams.get('accountGroup');
    }

    if (tempUrl.searchParams.get('internalNumberFrom') || tempUrl.searchParams.get('internalNumberTo')) {
      const intNumberIndex = this.getFilterParamIndex('internalNumber');
      this.actualFilters.filters[intNumberIndex].value = [
        tempUrl.searchParams.get('internalNumberFrom') ? tempUrl.searchParams.get('internalNumberFrom') : null,
        tempUrl.searchParams.get('internalNumberTo') ? tempUrl.searchParams.get('internalNumberTo') : null,
      ];
    }

    if (tempUrl.searchParams.get('amountFrom') || tempUrl.searchParams.get('amountTo')) {
      const amountIndex = this.getFilterParamIndex('amount');
      this.actualFilters.filters[amountIndex].value = [
        tempUrl.searchParams.get('amountFrom') ? tempUrl.searchParams.get('amountFrom') : null,
        tempUrl.searchParams.get('amountTo') ? tempUrl.searchParams.get('amountTo') : null,
      ];
    }

    if (tempUrl.searchParams.get('invoiceDateFrom') || tempUrl.searchParams.get('invoiceDateTo')) {
      const invoiceDateIndex = this.getFilterParamIndex('invoiceDate');
      this.actualFilters.filters[invoiceDateIndex].value = [
        tempUrl.searchParams.get('invoiceDateFrom') ? tempUrl.searchParams.get('invoiceDateFrom') : null,
        tempUrl.searchParams.get('invoiceDateTo') ? tempUrl.searchParams.get('invoiceDateTo') : null,
      ];
    }

    if (tempUrl.searchParams.get('createdAtFrom') || tempUrl.searchParams.get('createdAtTo')) {
      const createdAtIndex = this.getFilterParamIndex('createdAt');
      this.actualFilters.filters[createdAtIndex].value = [
        tempUrl.searchParams.get('createdAtFrom') ? tempUrl.searchParams.get('createdAtFrom') : null,
        tempUrl.searchParams.get('createdAtTo') ? tempUrl.searchParams.get('createdAtTo') : null,
      ];
    }

    return tempUrl;
  }

  protected fetchSelectedData(actualFilters: FiltersInterface) {
    const costCenter = actualFilters.filters[this.getFilterParamIndex('costCenter')]?.value;
    const account = actualFilters.filters[this.getFilterParamIndex('account')]?.value;
    const counterAccount = actualFilters.filters[this.getFilterParamIndex('counterAccount')]?.value;
    const supplier = actualFilters.filters[this.getFilterParamIndex('supplier')]?.value;
    const accountGroup = actualFilters.filters[this.getFilterParamIndex('accountGroup')]?.value;
    const paidBy = actualFilters.filters[this.getFilterParamIndex('paidBy')]?.value;
    const processingStatus = actualFilters.dropdownOptional?.value;

    this.commonNetwork
      .getDataForSelected(costCenter, account, counterAccount, supplier, accountGroup, paidBy, processingStatus)
      .subscribe((result: BookingsDataSelectedInterface) => {
        if (result.costCenter) {
          this.actualFilters.filters[this.getFilterParamIndex('costCenter')].dropdownData.push({
            label: result.costCenter.description,
            value: result.costCenter.id,
            active: true,
          });
        }

        if (result.account) {
          this.actualFilters.filters[this.getFilterParamIndex('account')].dropdownData.push({
            label: result.account.name,
            value: result.account.id,
            active: true,
          });
        }

        if (result.counterAccount) {
          this.actualFilters.filters[this.getFilterParamIndex('counterAccount')].dropdownData.push({
            label: result.counterAccount.name,
            value: result.counterAccount.id,
            active: true,
          });
        }

        if (result.accountGroup) {
          this.actualFilters.filters[this.getFilterParamIndex('accountGroup')].dropdownData.push({
            label: result.accountGroup.description,
            value: result.accountGroup.id,
            active: true,
          });
        }

        if (result.supplier) {
          this.actualFilters.filters[this.getFilterParamIndex('supplier')].dropdownData.push({
            label: result.supplier.name,
            value: result.supplier.id,
            active: true,
          });
        }

        if (result.user) {
          this.actualFilters.filters[this.getFilterParamIndex('paidBy')].dropdownData.push({
            label: result.user.name,
            value: result.user.id.toString(),
            active: true,
          });
        }

        if (result.processingStatus) {
          this.actualFilters.dropdownOptional.preloadedOptions.push({
            label: result.processingStatus.name,
            value: result.processingStatus.id.toString(),
            active: true,
          });
        }
      });
  }

  generateTabs(): TabInterface[] {
    return this.tabsService.generateTabs([
      {
        id: TabIdsEnum.UPLOADED_FILES,
        active: false,
      },
      {
        id: TabIdsEnum.DIGITAL_INVOICE,
        active: this.activeTab === TabIdsEnum.DIGITAL_INVOICE,
      },
      {
        id: TabIdsEnum.RECORDED,
        active: this.activeTab === TabIdsEnum.RECORDED,
      },
      {
        id: TabIdsEnum.PROTOCOL,
        active: this.activeTab === TabIdsEnum.PROTOCOL,
      },
      {
        id: TabIdsEnum.IMPORT_EXPORT,
        active: this.activeTab === TabIdsEnum.IMPORT_EXPORT,
      },
    ]);
  }

  ngOnDestroy() {
    this.fetchSelectedSubscription.unsubscribe();
  }

  private async checkForClientRec(): Promise<void> {
    new Promise(() => {
      if (this.actualFilters?.dropdownFirst) {
        this.paymentMethodFilter.dropdownData = this.paymentMethods.buildPaymentMethods(
          this.actualFilters.dropdownFirst,
        );
      } else {
        this.checkForClientRec();
      }
    });
  }
}
