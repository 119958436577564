import { Component, Output, EventEmitter } from '@angular/core';
import { AddProjectResultInterface } from '../../../../interfaces/add-project-result.interface';
import { BookingSettingsService } from '../../../../services/booking-settings.service';

@Component({
  selector: 'troi-add-auto-project',
  templateUrl: './add-auto-project.component.html',
  styleUrls: ['./add-auto-project.component.scss'],
})
export class AddAutoProjectComponent {
  @Output() public dataSelected = new EventEmitter<AddProjectResultInterface>();

  constructor(public bookingSettingsService: BookingSettingsService) {}

  addProjectAssignment() {
    const projectAssigmentSettings = {
      ...this.bookingSettingsService.settings.fallbackObjects.projectAssignment,
    };

    this.dataSelected.emit({
      projectReactivation: false,
      customer: projectAssigmentSettings.customer.id,
      project: parseInt(projectAssigmentSettings.project.id),
      subproject: parseInt(projectAssigmentSettings.subProject.id),
      cp: parseInt(projectAssigmentSettings.cp.id),
    });
  }
}
