<div class="collapse-section">
  <troi-modal-row>
    <div row-label></div>
    <div row-value class="subproject__row sub-header">
      <div (click)="subproject.isOpen = !subproject.isOpen" class="flex">
        <troi-icon
          *ngIf="subproject.isOpen"
          class="collapse-section__icon"
          icon="icon-bold-arrow-down"
        ></troi-icon>
        <troi-icon
          *ngIf="!subproject.isOpen"
          class="collapse-section__icon"
          icon="icon-bold-arrow-right"
        ></troi-icon>
        <span>{{ 'Common.labels.subproject' | translate }} {{ index }}</span>
      </div>
      <troi-dropdown-select-lazy
        [filterObject]="parent"
        dataType="SUB_PROJECTS"
        [reloadInitData]="parent"
        [initValue]="subproject?.getSubprojectId()"
        (selected)="onSubprojectChange($event)"
        [returnSelectedObject]="true"
        [predefinedOptions]="projectAssignmentData.generateDropdownOption(subproject?.subproject)"
        [withSearch]="true"
        [translatableName]="true"
        [lang]="projectAssignmentData.languagesService.getLanguage()"
        [enabled]="parent !== null && enabled"
        [clearButton]="false"
        [colorizeBackgroundProperty]="'hasNotAnyCalculationPositionWithExternalLabour'"
      >
      </troi-dropdown-select-lazy>
      <troi-icon
        class="icon-trash-can"
        icon="icon-trash-can"
        (click)="delete()"
        [small]="true"
        *ngIf="enabled"
      ></troi-icon>
    </div>
  </troi-modal-row>
</div>
<div class="subproject" [ngClass]="{ hidden: !subproject.isOpen }">
  <troi-modal-row class="subproject__row-calculation">
    <div row-label></div>
    <div row-value class="subproject__calculation">
      <troi-pa-calculation
        *ngFor="let calculation of subproject.cps; let i = index"
        [cp]="calculation"
        [subproject]="subproject.getSubprojectId()"
        [client]="client"
        [showLabels]="i === 0"
        [showDeleteBtn]="true"
        (remove)="removeCp($event)"
        [project]="parent"
        [customer]="customer"
        (taxChanged)="taxChanged.emit($event)"
        [netTotalAmount]="netTotalAmount"
        [enabled]="enabled"
        [hasAutoCustomer]="hasAutoCustomer"
        [isNet]="isNet"
      ></troi-pa-calculation>
    </div>
  </troi-modal-row>
  <troi-modal-row
    class="add-cp"
    [ngClass]="{ 'add-cp__disabled': subproject.getSubprojectId() === null }"
  >
    <div row-value class="add-cp__button">
      <div (click)="addCP()" *ngIf="hasAutoCustomer || enabled">
        <troi-icon [small]="true" icon="icon-plus"></troi-icon>
        {{ 'Booking.labels.addCp' | translate }}
      </div>
      <div class="unassigned" *ngIf="subproject.cps.length > 0">
        <div *ngIf="unassignedAmount">
          <span [ngClass]="{ overbooked: unassignedAmount.isMinusValue() }">{{
            unassignedAmount.formattedValueWithCurrencyMark
          }}</span>
          <span [ngClass]="{ overbooked: unassignedAmount.isMinusValue() }" *ngIf="isNet">
            ({{ 'Booking.labels.bookingFormNetLabel' | translate }})</span
          >
          <span [ngClass]="{ overbooked: unassignedAmount.isMinusValue() }" *ngIf="!isNet">
            ({{ 'Booking.labels.bookingFormGrossLabel' | translate }})</span
          >
          <span *ngIf="!unassignedAmount.isMinusValue()">
            {{ 'Booking.labels.unassigned' | translate }}</span
          >
          <span class="overbooked" *ngIf="unassignedAmount.isMinusValue()">
            {{ 'Booking.labels.overbooked' | translate }}</span
          >
        </div>
      </div>
    </div>
  </troi-modal-row>
</div>
