import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ModalType } from '../../../../../../core/enums/modalType';
import { BaseModalDirective } from '../../../../../../shared/troi-base-modal/baseModal.component';
import { ModalService } from '../../../../../../shared/troi-modals/modal.service';
import { AddEditService } from '../../services/addEdit.service';
import { AccountingSettingsService } from '../../../../common/services/accounting-settings.service';
import { TroiDropdownListModel } from '../../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { ModelsFactory } from '../../../../common/factories/models.factory';
import { AccountsService } from '../../services/accounts.service';
import { StorageNotificationService } from '../../../../../../core/notifications/storageNotification.service';
import { FiltersService } from '../../services/filters.service';
import {
  AccountDropdownInterface,
  AccountGroupDropdownInterface,
} from '../../../../../common/interfaces/basic-settings.interface';
import { AccountCreatedEmitter } from '../../../../../common/services/account-created.emitter';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss'],
  providers: [AccountingSettingsService],
})
export class AddEditComponent extends BaseModalDirective implements OnInit {
  public modalObject: any;

  public ModalType = ModalType;

  public canBeDeleted = false;

  public notDeletableReasons: string[] = [];

  public submitted = false;

  @Input() isOpenedAsSecond = false;

  constructor(
    private settings: AccountingSettingsService,
    private modelsFactory: ModelsFactory,
    private accountService: AccountsService,
    private filterService: FiltersService,
    private notificationService: StorageNotificationService,
    private translate: TranslateService,
    private accountEmitter: AccountCreatedEmitter,
    public modalService: ModalService,
    public addEditService: AddEditService,
    public accountingSettingsService: AccountingSettingsService,
  ) {
    super(modalService);
    this.modalObject = this.modalService.object;
    this.addEditService.openingBalances = [];
  }

  ngOnInit() {
    const client = this.filterService.getSelectedClientId()
      ? this.filterService.getSelectedClientId()
      : this.modalObject.client;
    this.accountingSettingsService.prepareSettings(this.filterService.getSelectedClientId(), true).subscribe(() => {
      this.addEditService.initForm(client);
      if (this.modalObject.type === ModalType.EDIT && this.modalObject.id) {
        this.accountService.getAccount(this.modalObject.id).subscribe((result) => {
          this.canBeDeleted = result.data.canBeDeleted;
          this.notDeletableReasons = result.data.notDeletableReasons;
          this.addEditService.patchValues(result.data);
        });
      }
    });
  }

  save() {
    this.submitted = true;

    if (this.addEditService.form.invalid) {
      return;
    }
    if (this.addEditService.form.value.id) {
      this.accountService
        .edit(this.addEditService.form, this.addEditService.prepareOpeningBalancesPayload())
        .subscribe((result) => {
          this.accountService.updateInList(result.data);
          this.close();
        });
    } else {
      this.accountService
        .create(this.addEditService.form, this.addEditService.prepareOpeningBalancesPayload())
        .subscribe((result) => {
          if (this.isOpenedAsSecond) {
            this.closeSecond();
          } else {
            this.accountService.loadList(this.filterService.actualFilters);
            this.close();
          }
          this.accountEmitter.accountCreated.next(result.data);
          this.translate.get('Common.labels.accountSuccessfullySaved').subscribe((message) => {
            this.notificationService.showSuccess(message);
          });
        });
    }
  }

  prepareNotDeletableMessages(): string {
    let messages = this.translate.instant('Booking.labels.thisAccountCannotBeDeletedItsInUse');

    _.forEach(this.notDeletableReasons, (element: string) => {
      messages += ` ${this.translate.instant(element)},`;
    });

    return messages.replace(/.$/, '.');
  }

  delete() {
    if (!this.canBeDeleted) {
      return;
    }

    this.accountService.delete(this.modalObject.id).subscribe((result) => {
      if (result.data.success) {
        this.close();
        if (this.accountService.accounts.length === 1) {
          this.filterService.decrementPage();
        }
        this.accountService.loadList(this.filterService.actualFilters);
      } else {
        this.notificationService.showError(result.data.reason);
      }
    });
  }

  public buildAccountList(): Array<TroiDropdownListModel> {
    const list = [];

    _.forEach(this.modelsFactory.getAccountDropdownList(), (element: AccountDropdownInterface) => {
      list.push({
        label: element.group ? element.name : `${element.number} - ${element.name}`,
        group: element.group,
        value: element.id,
        active: true,
        disabled: element.group,
      });
    });
    return list;
  }

  public displaySummaryAccountDropdown() {
    const selectedAccountGroup = _.find(
      this.settings.settings.dropdowns.accountGroups,
      (group: AccountGroupDropdownInterface) => group.id === this.addEditService.form.controls.group.value,
    );
    return (
      selectedAccountGroup &&
      (selectedAccountGroup.type === this.settings.settings.constantVariables.creditorTypeId ||
        selectedAccountGroup.type === this.settings.settings.constantVariables.debitorTypeId)
    );
  }

  public updateOpeningBalance(value) {
    this.addEditService.updateOpeningBalanceValue(value);
  }
}
