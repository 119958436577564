import * as _ from 'lodash';
import { BookingUser } from '../interfaces/booking-receipt-full.interface';
import {
  BookingAccount,
  BookingCostCenter,
  BookingPostingRunModel,
  BookingProject,
  BookingReceiptInterface,
  BookingTax,
} from '../interfaces/booking-receipt.interface';

export class BookingReceiptModel implements BookingReceiptInterface {
  public isOpen = false;

  constructor(
    public id: string,
    public oldId: string,
    public accountingEntryId: number,
    public supplier: string,
    public number: string,
    public intNumberYear: string,
    public digitalInvoiceType: number,
    public postingText: string,
    public postingRun: BookingPostingRunModel,
    public paidBy: BookingUser,
    public project: BookingProject,
    public bookingDate: number,
    public provisionDate: number,
    public createdAt: number,
    public account: BookingAccount,
    public counterAccount: BookingAccount,
    public costCenter: BookingCostCenter,
    public filename: string,
    public totalPrice: string,
    public tax: BookingTax,
    public netTotalPrice: string,
    public notBillable: boolean,
    public billed: boolean,
    public paid: boolean,
    public ksk: boolean,
    public pr: boolean,
    public cancelled: boolean,
    public accountingFinalized: boolean,
    public isBlocked: boolean,
    public children: BookingReceiptModel[],
    public treeStatus: string,
    public parentId: string = null,
    public blockedForExport: boolean = false,
    public blockedForExportMessage = '',
    public approvalState: string,
    public projectNumber?: string,
  ) {}

  get value() {
    return this.children;
  }

  toggleExpand() {
    this.isOpen = !this.isOpen;
  }

  get isChild(): boolean {
    return !_.isNull(this.parentId);
  }

  get isGroup(): boolean {
    return this.children.length > 0;
  }
}
