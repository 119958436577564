<div class="payment-terms" [ngClass]="{ 'payment-terms--disabled': !enabled }">
  <span class="payment-terms__select" (click)="toggleOpen()">
    <span class="payment-terms__select__label">
      <span class="payment-terms__select__label__wrapper">
        <span *ngIf="showPlaceholder()">{{ 'Booking.labels.paymentTermDetails' | translate }}</span>
        <span *ngIf="!showPlaceholder()">{{ label }}</span>
      </span>
    </span>
    <span class="payment-terms__select__icon">
      <troi-icon *ngIf="!open" icon="icon-bold-arrow-down"></troi-icon>
      <troi-icon *ngIf="open" icon="icon-bold-arrow-up"></troi-icon>
    </span>
  </span>
  <payment-terms-list
    *ngIf="open"
    (openChange)="onOpen($event)"
    [terms]="terms"
    [numberOfCashDiscounts]="numberOfCashDiscounts"
    (termsChanged)="termsChanged($event)"
  ></payment-terms-list>
</div>
