import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { AmountModel } from './amount.model';
import { TroiDropdownListModel } from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { BookingStatesInterface } from '../interfaces/booking-states.interface';
import { ProjectModel } from './project.model';
import { SubprojectModel } from './subproject.model';
import { CpModel } from './cp.model';
import { PABasicModel } from './PA-basic.model';
import { CpService } from '../services/cp.service';
import { BackendResponseInterface } from '../../../../core/interfaces/backend.response.interface';
import { Money } from '../../../../shared/troi-money/money';
import { BookingSettingsService } from '../services/booking-settings.service';
import {
  BookingCP,
  BookingCPsData,
  BookingCustomer,
  BookingProject,
  BookingProjectData,
  BookingReceiptFullInterface,
  BookingSubProjectData,
  BookingUser,
} from '../interfaces/booking-receipt-full.interface';
import { BrFilesAssignmentInterface } from '../interfaces/br-files-assignment.interface';
import { UploadedFileInterface } from '../../uploaded-files/interfaces/uploaded-file.interface';

export class BookingReceiptFullModel extends PABasicModel {
  public validDate = true;

  public supplierHasNotCreditAccount = false;

  public suppliers: Array<TroiDropdownListModel> = [];

  private prestineData;

  public startSavingCps = new Subject();

  public filesFetched = new Subject();

  public cpService: CpService;

  public multipleCps = false;

  public showProjectAssignmentRowsForSingle = false;

  public id = null;

  public hasDifferentTaxInSplits = false;

  public unassignedAmount: Money;

  public accountingTotalForSplits: Money;

  public internalNumber = '';

  public creditorAccount;

  public counterAccount;

  public projectReactivation = null;

  public notBillable = null;

  public files: UploadedFileInterface[] = [];

  public isAccountingUpdate = false;

  constructor(
    public formValues: UntypedFormGroup,
    public amount: AmountModel,
    public states: BookingStatesInterface,
    public projects: ProjectModel[],
  ) {
    super();
    this.generatePrestineData();
  }

  isPaid(): boolean {
    return this.formValues.get('paid').value;
  }

  isNew(): boolean {
    return this.id === null;
  }

  toBackendRequest() {
    return {
      ...this.formValuesToBackendRequest(),
      ...{
        amount: this.amount.toBackendRequest(),
      },
      ...{
        projects: this.projectsToBackendRequest(),
      },
      ...{
        pr: this.projectReactivation,
      },
      isAccountingUpdate: this.isAccountingUpdate,
    };
  }

  filesToBackendRequest(): BrFilesAssignmentInterface {
    const data: BrFilesAssignmentInterface = { group: null, files: [] };
    _.forEach(this.files, (file: UploadedFileInterface) => {
      if (file.isGroup) {
        data.group = file.id;
      } else {
        data.files.push(file.id);
      }
    });

    return data;
  }

  formValuesToBackendRequest() {
    const basicData = {
      supplier: this.getFormValueIdForKey('supplier'),
      number: this.getFormValueIdForKey('number'),
      additionalNumber: this.getFormValueIdForKey('additionalNumber'),
      bookingDate: this.getFormValueIdForKey('bookingDate'),
      provisionDate: this.getFormValueIdForKey('provisionDate'),
      paymentTerms: this.getFormValueIdForKey('paymentTerms'),
      postingText: this.getFormValueIdForKey('postingText'),
      client: this.getFormValueIdForKey('client'),
      multipleCps: this.isMultipleCps() && this.projects.length > 0,
      customer: this.getFormValueIdForKey('customer'),
      project: this.getFormValueIdForKey('project'),
      subproject: this.getFormValueIdForKey('subproject'),
      cp: this.getFormValueIdForKey('cp'),
      account: this.getFormValueIdForKey('account'),
      counterAccount: this.getFormValueIdForKey('counterAccount'),
      costCenter: this.getFormValueIdForKey('costCenter'),
      paid: this.getFormValueIdForKey('paid'),
      ksk: this.getFormValueIdForKey('ksk'),
      notBillable: this.getFormValueIdForKey('notBillable'),
      paidByEmployee: this.getFormValueIdForKey('paidByEmployee'),
      paymentMethod: this.getFormValueIdForKey('paymentMethod'),
      paidBy: this.getFormValueIdForKey('paidByEmployee') ? this.getFormValueIdForKey('paidBy') : null,
    };

    return this.isNew() ? basicData : { ...basicData, ...{ id: this.id } };
  }

  paidBy(): BookingUser {
    return this.formValues.get('paidBy').value;
  }

  saveAndUpdateCPs() {
    if (this.isMultipleCps()) {
      const requests = [];
      _.forEach(this.projects, (project: ProjectModel) => {
        _.forEach(project.subprojects, (subproject: SubprojectModel) => {
          _.forEach(subproject.cps, (cp: CpModel) => {
            if (cp.isNew) {
              cp.unit = this.amount.unit;
              requests.push(this.cpService.createCP(cp));
            }
          });
        });
      });

      if (requests.length === 0) {
        this.startSavingCps.next(true);
      }

      forkJoin(requests).subscribe((result) => {
        _.forEach(this.projects, (project: ProjectModel) => {
          _.forEach(project.subprojects, (subproject: SubprojectModel) => {
            _.forEach(subproject.cps, (cp: CpModel) => {
              if (cp.isNew) {
                const singleData = _.find(
                  result,
                  (singleResult: BackendResponseInterface<BookingCP>) => singleResult.tempId === cp.internalId,
                ) as BackendResponseInterface<BookingCP>;
                if (singleData) {
                  cp.isNew = false;
                  cp.change(singleData.data);
                }
              }
            });
          });
        });
        this.startSavingCps.next(true);
      });
    } else if (this.cpService && this.formValues.get('cp').value instanceof CpModel) {
      const cpObject = this.formValues.get('cp').value;
      cpObject.unit = this.amount.unit;
      this.cpService.createCP(cpObject).subscribe((result) => {
        this.formValues.patchValue({ cp: result.data.id });
        this.startSavingCps.next(true);
      });
    } else {
      this.startSavingCps.next(true);
    }
  }

  isMultipleCps(): boolean {
    return this.multipleCps;
  }

  getFormValueIdForKey(key: string) {
    return this.formValues.get(key).value && this.formValues.get(key).value.id
      ? this.formValues.get(key).value.id
      : this.formValues.get(key).value;
  }

  stringifyData(): string {
    return (
      JSON.stringify(this.amount.toBackendRequest()) +
      JSON.stringify(this.formValues.value) +
      JSON.stringify(this.projects)
    );
  }

  formValid(): boolean {
    return !this.formValues.invalid && this.amount.isValid() && this.validDate;
  }

  isOverbooked(): boolean {
    return this.unassignedAmount.isMinusValue();
  }

  isTaxRequired(): boolean {
    return !this.isMultipleCps() || (this.isMultipleCps() && this.projects.length === 0);
  }

  modelHasChanged(): boolean {
    return this.prestineData !== this.stringifyData();
  }

  generatePrestineData() {
    this.prestineData = _.cloneDeep(this.stringifyData());
  }

  updateModel(data: BookingReceiptFullInterface, amount: AmountModel, bookingsSettings: BookingSettingsService) {
    this.formValues.patchValue({
      supplier: data.supplier,
      number: data.number,
      additionalNumber: data.additionalNumber,
      bookingDate: data.bookingDate,
      provisionDate: data.provisionDate,
      paymentTerms: data.paymentTerms,
      postingText: data.postingText,
      customer: data.customer,
      project: data.project,
      subproject: data.subproject,
      cp: data.cp,
      account: data.account,
      counterAccount: data.counterAccount,
      costCenter: data.costCenter,
      paid: data.paid,
      ksk: data.ksk,
      notBillable: data.notBillable,
      paidBy: data.paidBy,
      paidByEmployee: data.paidByEmployee,
      paymentMethod: data.paymentMethod,
    });
    this.id = data.id;
    this.amount = amount;
    this.states.projectAssignmentFinalized = data.projectAssignmentFinalized;
    this.states.accountingFinalized = data.accountingFinalized;
    this.states.baseDataFinalized = true;
    this.multipleCps = data.multipleCps;
    this.projects = this.buildProjectsFromResponse(data.projects, bookingsSettings);
    this.showProjectAssignmentRowsForSingle = data.customer !== null;
    this.hasDifferentTaxInSplits = data.isMultipleTax;
    this.internalNumber = data.intNumberYear;
    this.notBillable = data.notBillable;
    this.generatePrestineData();
  }

  updateFilesFromResponse(files: UploadedFileInterface[]) {
    this.files = files || [];
    this.filesFetched.next(true);
  }

  buildProjectsFromResponse(data: BookingProjectData[], bookingsSettings: BookingSettingsService): ProjectModel[] {
    const projects: ProjectModel[] = [];
    let projectData;
    let cpsData = [];
    _.forEach(data, (project: BookingProjectData) => {
      projectData = new ProjectModel(
        project.project,
        project.customer,
        this.getClient(),
        [],
        this.amount.getValidTax(),
        this.creditorAccount,
      );
      _.forEach(project.subProjects, (subproject: BookingSubProjectData) => {
        cpsData = [];
        _.forEach(subproject.CPs, (cp: BookingCPsData) => {
          const cpData = new CpModel(
            cp.cp,
            cp.tax,
            this.initMoneyValue(cp.amount, bookingsSettings),
            this.initMoneyValue(cp.amountNet, bookingsSettings),
            this.getClient(),
            this.creditorAccount,
          );
          cpData.account = cp.account;
          cpData.counterAccount = cp.counterAccount;
          cpData.costCenter = cp.costCenter;
          cpData.postingText = cp.postingText;
          cpData.ksk = cp.ksk;
          cpData.splitId = cp.splitId;
          cpData.notBillable = cp.notBillable;
          cpsData.push(cpData);
        });
        projectData.subprojects.push(
          new SubprojectModel(
            subproject.subProject,
            cpsData,
            this.amount.getValidTax(),
            this.getClient(),
            this.creditorAccount,
          ),
        );
      });
      projects.push(projectData);
    });
    return projects;
  }

  getClient(): number {
    return this.getFormValueIdForKey('client');
  }

  addNextProjectSection(
    customer: BookingCustomer,
    project: BookingProject,
    subproject: BookingProject,
    cp?: BookingCP,
  ) {
    this.collapseProjectSections();
    this.projects.push(
      new ProjectModel(
        project,
        customer,
        this.getClient(),
        [
          new SubprojectModel(
            subproject,
            cp ? [new CpModel(cp, this.amount.getValidTax(), null, null, this.getClient(), this.creditorAccount)] : [],
            this.amount.getValidTax(),
            this.getClient(),
            this.creditorAccount,
          ),
        ],
        this.amount.getValidTax(),
        this.creditorAccount,
      ),
    );
  }

  collapseProjectSections() {
    _.forEach(this.projects, (project: ProjectModel) => {
      project.isOpen = false;
    });
  }

  removeProjectSection(id: string) {
    this.projects = this.removeObject(this.projects, id);
  }

  projectsToBackendRequest() {
    const data = [];
    let projectData;
    let subprojectData;
    const postingText = this.formValues.get('postingText').value;
    _.forEach(this.projects, (project: ProjectModel) => {
      projectData = {
        customer: project.getCustomerId(),
        project: project.getProjectId(),
        subprojects: [],
      };
      _.forEach(project.subprojects, (subproject: SubprojectModel) => {
        if (subproject.getSubprojectId() !== null) {
          subprojectData = {
            subProject: subproject.getSubprojectId(),
            CPs: [],
          };
          _.forEach(subproject.cps, (cp: CpModel) => {
            if (cp.getCpId() !== null || cp.getTaxId() !== null || !cp.amountNet.isZero()) {
              subprojectData.CPs.push({
                cp: cp.getCpId(),
                tax: cp.getTaxId(),
                amount: cp.amountNet.forBackend,
                postingText,
                account: cp.getDropdownId(cp.account),
                counterAccount: cp.getDropdownId(cp.counterAccount),
                costCenter: cp.getDropdownId(cp.costCenter),
                ksk: cp.ksk,
                notBillable: cp.getNbiDefaultValue(),
                splitId: cp.splitId,
              });
            }
          });
          projectData.subprojects.push(subprojectData);
        }
      });
      data.push(projectData);
    });

    return data;
  }

  clearSinglePAData() {
    this.formValues.patchValue({
      customer: null,
      project: null,
      subproject: null,
      cp: null,
    });
  }

  isSinglePATouched() {
    return this.getFormValueIdForKey('customer') !== null;
  }

  onMultipleCpCheckboxChange() {
    this.projects = [];
    this.clearSinglePAData();
    this.showProjectAssignmentRowsForSingle = false;
  }

  initMoneyValue(value: string, bookingsSettings: BookingSettingsService): Money {
    value = value === null ? '0' : value;
    const formattedValue = value.replace(',', '.');
    return new Money(
      formattedValue !== '' ? formattedValue : '0',
      bookingsSettings.systemCurrency,
      bookingsSettings.settings.settings.decimalPlacesNumber,
    );
  }

  changeBaseDataTax(tax) {
    this.amount.tax = tax;
    if (tax && tax.id !== -1 && tax.id !== null) {
      this.updateDefaultTaxForSplits(tax);
    }
  }

  updateDefaultTaxForSplits(defaultTax) {
    _.forEach(this.projects, (project: ProjectModel) => {
      project.defaultTax = defaultTax;
      _.forEach(project.subprojects, (subproject: SubprojectModel) => {
        subproject.defaultTax = defaultTax;
        _.forEach(subproject.cps, (cp: CpModel) => {
          cp.tax = defaultTax;
        });
      });
    });
  }

  checkIfSplitsHasDiffTax() {
    let hasDifferentTaxInSplits = false;
    let tax = this.projects[0].subprojects.length
      ? this.projects[0].subprojects[0].cps.length > 0
        ? this.projects[0].subprojects[0].cps[0].tax
        : null
      : null;
    _.forEach(this.projects, (project: ProjectModel) => {
      _.forEach(project.subprojects, (subproject: SubprojectModel) => {
        _.forEach(subproject.cps, (cp: CpModel) => {
          if ((tax === null && cp.getTaxId() !== null) || (tax !== null && tax.id !== cp.getTaxId())) {
            hasDifferentTaxInSplits = true;
          }
          tax = cp.tax;
        });
      });
    });
    this.hasDifferentTaxInSplits =
      hasDifferentTaxInSplits || (tax !== null && this.amount.tax !== null && tax.id !== this.amount.tax.id);
    this.amount.tax = this.hasDifferentTaxInSplits ? { id: -1 } : tax;
  }

  getAllSplitAmounts(): string[] {
    const data = [];

    _.forEach(this.projects, (project: ProjectModel) => {
      _.forEach(project.subprojects, (subproject: SubprojectModel) => {
        _.forEach(subproject.cps, (cp: CpModel) => {
          if (cp.amountNet) {
            data.push({
              internalId: cp.internalId,
              amountNet: cp.amountNet.forBackend,
              tax: cp.tax ? cp.tax.id : null,
            });
          }
        });
      });
    });

    return data;
  }

  hasSelectedAtLeastOneNotAutomaticCp(settings: BookingSettingsService): boolean {
    const cpMain = this.getFormValueIdForKey('cp');
    if (cpMain !== null) {
      if (settings.getFallbackObjects().isManualProjectAssignment) {
        return true;
      }

      return (
        settings.getFallbackObjects().areOwnFallbacks ||
        (!settings.getFallbackObjects().areOwnFallbacks && cpMain !== settings.getFallbackObjects().cp.id)
      );
    }

    let hasCp = false;

    _.forEach(this.projects, (project: ProjectModel) => {
      _.forEach(project.subprojects, (subproject: SubprojectModel) => {
        _.forEach(subproject.cps, (cp: CpModel) => {
          if (cp.getCpId() !== null) {
            hasCp = true;
          }
        });
      });
    });

    return hasCp;
  }

  hasAtLeastOneDifferentCounterAccountInSplits(counterAccountId): boolean {
    let diff = false;

    _.forEach(this.projects, (project: ProjectModel) => {
      _.forEach(project.subprojects, (subproject: SubprojectModel) => {
        _.forEach(subproject.cps, (cp: CpModel) => {
          if (cp.getCounterAccountId() !== null && cp.getCounterAccountId() !== counterAccountId) {
            diff = true;
          }
        });
      });
    });

    return diff;
  }

  updateSplitAccountAndCounterAccounts(account, counterAccount) {
    _.forEach(this.projects, (project: ProjectModel) => {
      _.forEach(project.subprojects, (subproject: SubprojectModel) => {
        _.forEach(subproject.cps, (cp: CpModel) => {
          cp.account = account;
          cp.counterAccount = counterAccount;
        });
      });
    });
  }

  get accountingTotal(): Money {
    return this.isMultipleCps() ? this.accountingTotalForSplits : this.amount.totalNetAmount;
  }

  getSplitAmounts(): string[] {
    const amounts = [];

    _.forEach(this.projects, (project: ProjectModel) => {
      _.forEach(project.subprojects, (subproject: SubprojectModel) => {
        _.forEach(subproject.cps, (cp: CpModel) => {
          amounts.push(cp.amount.forBackend);
        });
      });
    });

    return amounts;
  }
}
