import { Injectable } from '@angular/core';
import { CPOptionInterface } from '../interfaces/project-list.interface';
import { BookingFormNetwork } from '../networks/booking-form.network';
import { LanguagesService } from '../../../../core/services/languages.service';
import { LanguageTranslationsBackend } from '../../../../core/interfaces/languageTranslations.backend';
import { BackendResponseDropdownDataInterface } from '../../../../core/interfaces/backend.response.interface';

@Injectable({
  providedIn: 'root',
})
export class AddProjectByOrderNumberService {
  isLoading = false;

  page = 1;

  pageSize = 10;

  search = '';

  cPs: CPOptionInterface[] = [];

  selectedCP: CPOptionInterface;

  client: number;

  canLoadMore = true;

  projectReactivation = false;

  errorMessage = '';

  constructor(public network: BookingFormNetwork, public languagesService: LanguagesService) {}

  initialLoadCPs() {
    this.page = 1;
    this.loadCPs();
  }

  loadCPs(concat = false) {
    if (!concat) {
      this.cPs = [];
    }

    this.isLoading = true;
    this.errorMessage = '';

    this.network
      .fetchCPListByOrderNumber(this.search, this.client, this.page, this.pageSize)
      .subscribe(({ errorMessage, items }: BackendResponseDropdownDataInterface<CPOptionInterface[]>) => {
        this.isLoading = false;
        this.errorMessage = errorMessage;
        this.cPs = concat ? [...this.cPs, ...items] : items;
        this.canLoadMore = this.page === 1 || items.length === this.pageSize;
      });
  }

  clearValue() {
    this.selectedCP = undefined;
    this.search = '';
    this.cPs = [];
    this.canLoadMore = true;
  }

  incrementPageForCPs() {
    if (!this.canLoadMore || this.isLoading) {
      return;
    }
    this.page++;
    this.loadCPs(true);
  }

  getTransValue(value: LanguageTranslationsBackend) {
    return this.languagesService.getLanguageValue(value);
  }
}
