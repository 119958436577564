<div class="troi__tabs">
  <troi-data-tabs
    [tabs]="tabs"
    (tabChanged)="changeTab($event)"
    [ngClass]="{ 'troi__tabs--customOptions': useTabOptions }"
  ></troi-data-tabs>
  <div class="troi__tabs__options" *ngIf="useTabOptions">
    <ng-content></ng-content>
  </div>
  <div class="troi__tabs__actions" *ngIf="!useTabOptions">
    <troi-dropdown-list
      [width]="width"
      (selectedEmitter)="moreActionOptionClick($event)"
      (openChange)="onOpeningState($event)"
      [disable]="moreActionButtonDisabled || !moreActionsOptions"
      [options]="moreActionsOptions"
    >
      <div class="actions__item">
        <troi-icon icon="icon-setting" size="11px"></troi-icon>
        <span class="actions__item__label">{{ 'PriceList.labels.moreActions' | translate }}</span>
      </div>
    </troi-dropdown-list>

    <div
      class="actions__item"
      *ngIf="!newButtonHidden"
      [ngClass]="{ 'actions--inactive': newButtonDisabled }"
      (click)="emitOnNew()"
    >
      <troi-icon icon="icon-plus" size="11px"></troi-icon>
      <span class="actions__item__label">{{ 'PriceList.labels.new' | translate }}</span>
    </div>
  </div>
</div>
