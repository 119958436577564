import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Money } from '../../../../../../../../shared/troi-money/money';

@Component({
  selector: 'troi-accounting-list-total',
  templateUrl: './total.component.html',
  styleUrls: ['./total.component.scss'],
})
export class TotalComponent {
  @Input() total: Money;

  @Input() showPaid = false;

  @Input() paid = false;

  @Input() enabled = true;

  @Output() paidChanged = new EventEmitter();
}
