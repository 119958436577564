import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { BackendResponseInterface } from '../../../../core/interfaces/backend.response.interface';
import { Money } from '../../../../shared/troi-money/money';
import {
  BookingReceiptFullInterface,
  PaymentTermsResponseInterface,
} from '../interfaces/booking-receipt-full.interface';
import { BookingsDataSelectedInterface } from '../interfaces/bookings-data-selected.interface';
import { NextIntNumberInterface } from '../interfaces/next-int-number.interface';
import { CashDiscountInterface, PaymentTermsInterface } from '../modals/booking-form/terms/payment-terms.interface';
import { BookingFormNetwork } from '../networks/booking-form.network';
import { AssignFilesToBrService } from './assign-files-to-br.service';
import { BookingSettingsService } from './booking-settings.service';

@Injectable()
export class BookingsFormService {
  submitted = false;

  constructor(private network: BookingFormNetwork, public assignFilesService: AssignFilesToBrService) {}

  initForm(clientId: number, paidBy = null): UntypedFormGroup {
    return new UntypedFormGroup({
      supplier: new UntypedFormControl(null),
      number: new UntypedFormControl(''),
      additionalNumber: new UntypedFormControl(null),
      bookingDate: new UntypedFormControl(null, Validators.required),
      provisionDate: new UntypedFormControl(null),
      paymentTerms: new UntypedFormControl(null),
      postingText: new UntypedFormControl(''),
      paidByEmployee: new UntypedFormControl(false),
      paymentMethod: new UntypedFormControl(null),
      paidBy: new UntypedFormControl(paidBy),
      client: new UntypedFormControl(clientId),
      customer: new UntypedFormControl(null),
      project: new UntypedFormControl(null),
      subproject: new UntypedFormControl(null),
      cp: new UntypedFormControl(null),
      account: new UntypedFormControl(null),
      counterAccount: new UntypedFormControl(null),
      costCenter: new UntypedFormControl(null),
      paid: new UntypedFormControl(false),
      ksk: new UntypedFormControl(false),
      notBillable: new UntypedFormControl(null),
    });
  }

  buildPaymentTerms(paymentTerms: PaymentTermsResponseInterface, numberOfCashDiscounts: number): PaymentTermsInterface {
    if (!paymentTerms) {
      return {
        net: null,
        discounts: this.buildCashDiscounts(numberOfCashDiscounts),
      };
    }

    return {
      net: Number(paymentTerms.overduePaymentLimit),
      discounts: this.buildCashDiscounts(numberOfCashDiscounts, paymentTerms),
    };
  }

  private buildCashDiscounts(
    numberOfCashDiscounts: number,
    data?: PaymentTermsResponseInterface,
  ): CashDiscountInterface[] {
    const discounts = [];
    for (let i = 1; i <= numberOfCashDiscounts; i++) {
      discounts.push({
        days: _.isUndefined(data) || !(`discount${i}Days` in data) ? null : data[`discount${i}Days`],
        rate: _.isUndefined(data) || !(`discount${i}Rate` in data) ? null : data[`discount${i}Rate`],
      });
    }
    return discounts;
  }

  save(data, isCreate: boolean): Observable<BackendResponseInterface<BookingReceiptFullInterface>> {
    return isCreate ? this.create(data) : this.update(data);
  }

  get(id: string): Observable<BackendResponseInterface<BookingReceiptFullInterface>> {
    return this.network.get(id);
  }

  private create(data): Observable<BackendResponseInterface<BookingReceiptFullInterface>> {
    return this.network.create(data);
  }

  private update(data): Observable<BackendResponseInterface<BookingReceiptFullInterface>> {
    return this.network.update(data);
  }

  initMoneyValue(value: string, settings: BookingSettingsService): Money {
    const formattedValue = value.toString().replace(',', '.');
    return new Money(
      formattedValue !== '' ? formattedValue : '0',
      settings.systemCurrency,
      settings.settings.settings.decimalPlacesNumber,
    );
  }

  fetchSelected(data): Observable<BookingsDataSelectedInterface> {
    return this.network.getForSelected(data);
  }

  fetchNextBookingIntNumber(
    client: number,
    year: number,
  ): Observable<BackendResponseInterface<NextIntNumberInterface>> {
    return this.network.fetchNextBookingIntNumber(client, year);
  }

  patchSupplierTax(supplierId: string, bookingId: string): Observable<BackendResponseInterface<any>> {
    return this.network.patchSupplierTax(supplierId, bookingId);
  }
}
