<div class="booking-accounting-table" [ngClass]="{ disabled: !enabled }">
  <div class="booking-accounting-table__cell cp">
    <div class="booking-accounting-table__label">{{ getCpName(calculation.cp) }}</div>
  </div>
  <div class="booking-accounting-table__cell booking-accounting-table__cell__postingTun">
    <div class="booking-accounting-table__label inline-edit">
      <troi-inline-edit
        inline-tab
        [enabled]="enabled"
        (enterEmitter)="$event.target.blur()"
        [value]="_cpModel.postingText"
      >
        <input
          type="text"
          [name]="_cpModel.postingText"
          (ngModelChange)="onDataChanged()"
          [(ngModel)]="_cpModel.postingText"
        />
      </troi-inline-edit>
    </div>
  </div>
  <div class="booking-accounting-table__cell no-margin-right">
    <div class="booking-accounting-table__label show-overflow">
      <troi-dropdown-select-lazy
        [filterObject]="_cpModel.client"
        [predefinedOptions]="getPreloadedOptionsForAccounts()"
        [refreshPreloadedOptionsOnChange]="true"
        [secondFilterObject]="supplier?.id"
        [thirdFilterObject]="'account'"
        dataType="ACCOUNTS_CREATE_BOOKING"
        [reloadInitData]="supplier?.id"
        [initValue]="_cpModel.getDropdownId(_cpModel.account)"
        (selected)="accountSelected($event?.fullObject)"
        [disableIds]="[_cpModel.getDropdownId(_cpModel.counterAccount)]"
        [clearButton]="true"
        [noBorder]="true"
        [returnSelectedObject]="true"
        [enabled]="enabled"
        [withSearch]="true"
      >
      </troi-dropdown-select-lazy>
    </div>
  </div>
  <div class="account-create" (click)="createAccount()">
    <troi-icon
      icon="icon-plus"
      *ngIf="canCreateAccount() && enabled"
      title="{{ 'Booking.labels.createNewAccount' | translate }}"
    ></troi-icon>
    <troi-icon
      icon="icon-plus"
      *ngIf="!canCreateAccount()"
      [disabled]="true"
      title="{{ 'Booking.labels.noRightsToCreateAccount' | translate }}"
    ></troi-icon>
  </div>

  <div class="booking-accounting-table__cell">
    <div class="booking-accounting-table__label show-overflow">
      <troi-dropdown-select-lazy
        [filterObject]="_cpModel.client"
        [secondFilterObject]="supplier?.id"
        [thirdFilterObject]="'counterAccount'"
        dataType="ACCOUNTS_CREATE_BOOKING"
        [reloadInitData]="supplier?.id"
        [predefinedOptions]="getPreloadedOptionsForCounterAccount()"
        [initValue]="_cpModel.getDropdownId(_cpModel.counterAccount)"
        (selected)="_cpModel.counterAccount = $event; onDataChanged()"
        [refreshPreloadedOptionsOnChange]="true"
        [noBorder]="true"
        [enabled]="enabled"
        [disableIds]="[_cpModel.getDropdownId(_cpModel.account)]"
        [withSearch]="true"
      >
      </troi-dropdown-select-lazy>
    </div>
  </div>
  <div class="booking-accounting-table__cell">
    <div class="booking-accounting-table__label show-overflow">
      <troi-dropdown-select-lazy
        [filterObject]="_cpModel.client"
        dataType="COST_CENTERS"
        [initValue]="_cpModel.getDropdownId(_cpModel.costCenter)"
        (selected)="_cpModel.costCenter = $event; onDataChanged()"
        [year]="year"
        [predefinedOptions]="getPreloadedOptionsForCostCenters()"
        [refreshPreloadedOptionsOnChange]="true"
        [clearButton]="true"
        [enabled]="enabled"
        [noBorder]="true"
        [withSearch]="true"
      >
      </troi-dropdown-select-lazy>
    </div>
  </div>
  <div class="booking-accounting-table__cell tax">
    <div class="booking-accounting-table__label">
      {{ getTaxLabel(_cpModel.tax) }}
    </div>
  </div>
  <div class="booking-accounting-table__cell amount">
    <div class="booking-accounting-table__label value-amount">
      {{ _cpModel.amount?.formattedValueWithCurrencyMark }}
    </div>
  </div>
  <div class="booking-accounting-table__cell options">
    <div class="booking-accounting-table__label" *ngIf="bookingSettingsService.settings.settings.showKSK">
      <troi-checkbox
        [disabled]="!enabled"
        label="KSK"
        [value]="_cpModel.ksk"
        (checkboxChange)="_cpModel.ksk = $event; onDataChanged()"
        data-test="KSK"
      >
      </troi-checkbox>
    </div>
    <div class="booking-accounting-table__label">
      <troi-checkbox
        [disabled]="!enabled"
        label="{{ 'Booking.labels.checkbox.nbi' | translate }}"
        [value]="_cpModel.getNbiDefaultValue()"
        (checkboxChange)="_cpModel.notBillable = $event; onDataChanged()"
        data-test="NBI"
      ></troi-checkbox>
    </div>
  </div>
</div>
