<troi-modal-header>
  <div style="display: flex">
    <p style="margin: 0px">
      {{ 'Booking.digitalInvoice.reports.label' | translate }}
    </p>
  </div>
</troi-modal-header>
<troi-modal-content style="overflow-y: inherit">
  <div class="di-upload--items mb-4">
    <div class="di-upload--item" *ngFor="let report of reports">
      <div class="di-upload--item__name">
        {{ (report?.bookingReceipt || report?.childBookingReceipt).number }}
        <ng-container *ngIf="report.childIndex >= 0">- Split {{ report.childIndex + 1 }}</ng-container>
      </div>
      <div class="di-upload--item__name">
        {{ report.message | translate }}
      </div>
      <div class="di-upload--item__type">
        <troi-btn (click)="editRecorded(report?.bookingReceipt || report?.childBookingReceipt, report)">
          <span>{{ 'Booking.labels.edit' | translate }}</span>
        </troi-btn>
      </div>
    </div>
  </div>
</troi-modal-content>
