<div class="modal-confirmation">
  <troi-modal-header (modalWantClose)="close()" [askBeforeClose]="true">
    <span *ngIf="firstPage">
      <troi-icon icon="icon-warning"></troi-icon>
      {{ 'Booking.labels.blocked' | translate }}
    </span>
    <span *ngIf="!firstPage">{{ 'Booking.labels.whatDo' | translate }}</span>
  </troi-modal-header>
  <troi-modal-content style="overflow-y: inherit">
    <p class="modal-confirmation__message" *ngIf="firstPage">
      {{ 'Booking.labels.blockedDescription' | translate }}
    </p>
    <div *ngIf="!firstPage" class="modal-confirmation__status-list">
      <div
        class="modal-confirmation__status-list__item"
        (click)="selectStatus(null)"
        [ngClass]="{ active: isSelected(null) }"
      >
        <span>{{ 'Booking.labels.bookWithoutStatusChange' | translate }}</span>
        <troi-icon [small]="true" icon="icon-tick" *ngIf="isSelected(null)"></troi-icon>
      </div>
      <div
        class="modal-confirmation__status-list__item"
        *ngFor="let status of statuses"
        (click)="selectStatus(status.id)"
        [ngClass]="{ active: isSelected(status.id) }"
      >
        <span
          >{{ 'Booking.labels.bookAndChange' | translate }}
          <strong>{{ languagesService.getLanguageValue(status.name) }}</strong></span
        >
        <troi-icon [small]="true" icon="icon-tick" *ngIf="isSelected(status.id)"></troi-icon>
      </div>
    </div>
  </troi-modal-content>
  <troi-modal-footer>
    <div *ngIf="firstPage" style="display: flex">
      <troi-btn class="modal-confirmation__button" [outline]="true" (click)="firstPage = false">
        {{ 'Booking.labels.yes' | translate }}
      </troi-btn>
      <troi-btn class="modal-confirmation__button" (click)="close()">
        {{ 'Booking.labels.no' | translate }}
      </troi-btn>
    </div>
    <div *ngIf="!firstPage" style="margin-top: 10px">
      <troi-btn class="modal-confirmation__button" [outline]="true" (click)="executeSave()">
        {{ 'Booking.labels.save' | translate }}
      </troi-btn>
    </div>
  </troi-modal-footer>
</div>
