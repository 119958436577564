<div class="cp-list" id="optionWrapper" #optionWrapper (scroll)="calculateScrollPosition()">
  <div #optionList id="optionList">
    <div class="cp-list__elem" *ngIf="addProjectByService.errorMessage">
      <span class="elem__error-title">
        <troi-icon class="error_title__icon" icon="icon-lock" medium="true"></troi-icon>
        {{ addProjectByService.errorMessage | translate }}</span
      >
    </div>
    <div
      class="cp-list__elem"
      (click)="onOptionSelected(cp)"
      *ngFor="let cp of addProjectByService.cPs"
    >
      <span class="cp-list__elem__title">{{ addProjectByService.getTransValue(cp.name) }}</span>
    </div>
    <troi-loading-spinner *ngIf="addProjectByService.isLoading"></troi-loading-spinner>
  </div>
</div>
