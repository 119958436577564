import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BookingReceiptFullModel } from '../../../models/booking-receipt-full.model';
import { AddProjectResultInterface } from '../../../interfaces/add-project-result.interface';
import { BookingsFormService } from '../../../services/bookings-form.service';
import { ProjectAssignmentDataService } from '../../../services/project-assignment-data.service';

@Component({
  selector: 'troi-multiple-add-project',
  templateUrl: './multiple-add-project.component.html',
  styleUrls: ['./multiple-add-project.component.scss'],
})
export class MultipleAddProjectComponent {
  loading = false;

  @Input() bookingModel: BookingReceiptFullModel;

  @Input() enabled = true;

  @Input() client: number;

  @Output() taxChanged = new EventEmitter();

  constructor(private formService: BookingsFormService, private projectAssignmentData: ProjectAssignmentDataService) {}

  fillDropdownData(data: AddProjectResultInterface) {
    this.loading = true;
    this.formService
      .fetchSelected([
        {
          key: 'customer',
          value: data.customer,
        },
        {
          key: 'project',
          value: data.project,
        },
        {
          key: 'subproject',
          value: data.subproject,
        },
        {
          key: 'cp',
          value: data.cp ? data.cp : null,
        },
      ])
      .subscribe((resultData) => {
        this.loading = false;
        this.bookingModel.addNextProjectSection(
          resultData.customer,
          resultData.project,
          resultData.subproject,
          resultData.cp,
        );
      });
  }

  removeProject(id: string) {
    if (!this.enabled) {
      return;
    }
    this.bookingModel.removeProjectSection(id);
    this.projectAssignmentData.splitAmountChanged.next(true);
  }
}
