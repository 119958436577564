import { BookingProject } from '../interfaces/booking-receipt-full.interface';
import { CpModel } from './cp.model';
import { PABasicModel } from './PA-basic.model';

export class SubprojectModel extends PABasicModel {
  public isOpen = true;

  public accountingIsOpen = true;

  constructor(
    public subproject: BookingProject,
    public cps: CpModel[],
    public defaultTax = null,
    public client = null,
    public defaultCounterAcount = null,
  ) {
    super();
  }

  change(data: BookingProject) {
    this.subproject = data;
    this.cps = [];
  }

  getSubprojectId() {
    return this.getDropdownId(this.subproject);
  }

  private getDropdownId(data) {
    return data ? (data.id ? data.id : data) : null;
  }

  addNextCP(id = null) {
    this.cps.push(new CpModel(id, this.defaultTax, null, null, this.client, this.defaultCounterAcount));
  }

  removeCP(id: string) {
    this.cps = this.removeObject(this.cps, id);
  }
}
