import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { environment } from '../../../../../../../environments/environment';
import { UploadRoutes } from '../../../../uploaded-files/enums/routes';
import { UploadedFileInterface } from '../../../../uploaded-files/interfaces/uploaded-file.interface';
import { BookingReceiptFullModel } from '../../../models/booking-receipt-full.model';
import { BookingSettingsService } from '../../../services/booking-settings.service';

@Component({
  selector: 'booking-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit {
  zoom = 0.6;

  page = 1;

  selectedFiles = [];

  initUnusedFiles = [];

  preloadedUnusedFiles = [];

  actualFile: UploadedFileInterface = null;

  maxPdfPages = 1;

  reloadPreloadedOptions;

  bookingYears = [];

  @Input() assignedFile: UploadedFileInterface;

  @Input() bookingModel: BookingReceiptFullModel;

  @Input() client;

  @Input() year;

  @Input() enabled = true;

  @Output() filesAssignmentChanged = new EventEmitter<UploadedFileInterface[]>();

  constructor(private settingsService: BookingSettingsService) {}

  ngOnInit() {
    if (this.assignedFile) {
      this.bookingModel.files.push(this.assignedFile);
      this.addFilesSelection(this.assignedFile, false);
    }
    this.bookingModel.filesFetched.subscribe(() => {
      this.preloadedUnusedFiles = [];
      this.initUnusedFiles = [];
      this.addFilesSelection(this.bookingModel.files[0]);
    });
    this.bookingYears = this.settingsService.buildBookingYears();
  }

  zoomIn() {
    this.zoom += 0.2;
  }

  zoomOut() {
    this.zoom = Math.max(this.zoom - 0.2, 0.6);
  }

  nextPage() {
    if (this.isPdf() && this.maxPdfPages > this.page) {
      this.page++;
    }
  }

  previousPage() {
    this.page = Math.max(this.page - 1, 1);
  }

  clearData() {
    this.page = 1;
    this.zoom = 0.6;
  }

  onPageChange(page) {
    this.page = this.isPdf() && this.maxPdfPages >= page ? Math.max(page, 1) : 1;
  }

  onEnterPressed(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  filesSelected(files) {
    const data = [];
    const assignment = [];
    let actualStillExist = false;
    _.forEach(files, (file) => {
      data.push(_.cloneDeep(file));
      assignment.push(file.fullObject.id ? file.fullObject : file.fullObject.fullObject);
      if (this.actualFile && file.value === this.actualFile.id) {
        actualStillExist = true;
      }
      if (file.group) {
        data[0].disabled = true;
        const children = file.fullObject.children ? file.fullObject.children : file.fullObject.fullObject.children;
        _.forEach(children, (child) => {
          data.push({
            label: this.nameWithExtension(child),
            value: child.id,
            active: true,
            fullObject: child,
          });
          if (this.actualFile && child.id === this.actualFile.id) {
            actualStillExist = true;
          }
        });
      }
    });

    this.selectedFiles = data;
    if (!this.selectedFiles.length || !actualStillExist) {
      this.actualFile = null;
    }
    this.filesAssignmentChanged.emit(assignment);
  }

  addFilesSelection(file: UploadedFileInterface, withReloading = true) {
    if (!file) {
      return;
    }
    this.selectedFiles = [];
    this.preloadedUnusedFiles = [];
    this.initUnusedFiles = [];
    this.selectedFiles.push({
      label: this.nameWithExtension(file),
      value: file.id,
      active: true,
      disabled: file.isGroup,
      group: file.isGroup,
      fullObject: file,
    });
    const preloadedObject = {
      label: this.nameWithExtension(file),
      value: file.id,
      active: true,
      group: file.isGroup,
      fullObject: file,
      disabled: false,
    };
    this.preloadedUnusedFiles.push(preloadedObject);
    this.initUnusedFiles.push(preloadedObject);
    if (file.isGroup) {
      _.forEach(file.children, (child: UploadedFileInterface) => {
        this.selectedFiles.push({
          label: this.nameWithExtension(child),
          value: child.id,
          active: true,
          fullObject: child,
          disabled: false,
        });
        this.preloadedUnusedFiles.push({
          label: this.nameWithExtension(child),
          value: child.id,
          active: true,
          fullObject: child,
          disabled: true,
        });
      });
    }

    if (!this.actualFile) {
      this.actualFile = file.isGroup ? file.children[0] : file;
    }
    if (withReloading) {
      this.reloadPreloadedOptions = Math.random();
    }
  }

  nameWithExtension(file: UploadedFileInterface): string {
    if (file.isGroup) {
      return file.name;
    }
    return `${file.name}.${file.extension}`;
  }

  getActualSelectedFiles() {
    return this.selectedFiles.concat([]);
  }

  filePreviewSelected(file) {
    this.clearData();
    this.actualFile = file;
  }

  getFileUrl(): string {
    return `${environment.url + UploadRoutes.UPLOADED_FILES_LIST}/${this.actualFile.id}`;
  }

  isPdf(): boolean {
    return this.actualFile && this.actualFile.extension === 'pdf';
  }

  widthForImage(): string {
    return `${this.zoom * 100}%`;
  }

  pdfPagesLoaded(pdf) {
    this.maxPdfPages = pdf.pagesCount;
  }
}
