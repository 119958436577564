import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { RefreshFormEmitter } from '../../../services/refresh-form.emitter';
import { CpModel } from '../../../models/cp.model';

@Component({
  selector: 'troi-cp-data',
  templateUrl: './cp-data.component.html',
  styleUrls: ['./cp-data.component.scss'],
})
export class CpDataComponent implements OnInit, OnDestroy {
  newCpActive = false;

  newCpName = '';

  refreshForm: Subscription;

  @Input() filterObject;

  @Input() secondFilterObject;

  @Input() initValue;

  @Input() preloadedOptions;

  @Input() enabled = true;

  @Input() reloadInitData;

  @Input() lang = 'de';

  @Output() cpChanged = new EventEmitter();

  constructor(private refreshFormEmitter: RefreshFormEmitter) {}

  ngOnInit() {
    this.refreshForm = this.refreshFormEmitter.emitter.subscribe(() => {
      this.newCpActive = false;
    });
  }

  selectCp(value?: CpModel) {
    this.cpChanged.emit({
      isNew: false,
      cp: value,
      name: null,
    });
  }

  ngOnDestroy(): void {
    if (this.refreshForm) {
      this.refreshForm.unsubscribe();
    }
  }

  showNewCpInput(): void {
    if (!this.enabled) {
      return;
    }

    this.newCpActive = true;
  }

  hideNewCpInput(): void {
    if (!this.enabled) {
      return;
    }

    this.newCpActive = false;
    this.newCpName = '';
  }

  applyNewCp() {
    this.cpChanged.emit({
      isNew: true,
      cp: null,
      name: this.newCpName,
    });
  }
}
