import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { TroiDropDownCloseService } from '../../../../../../../../../shared/troi-dropdown-list/services/troi-dropdown-close.service';
import { SubprojectOptionInterface } from '../../../../../../interfaces/project-list.interface';
import { BookingSettingsService } from '../../../../../../services/booking-settings.service';
import { ModalService } from '../../../../../../../../../shared/troi-modals/modal.service';
import { BookOnBlockedComponent } from '../../../book-on-blocked/book-on-blocked.component';
import { DomService } from '../../../../../../../../../shared/troi-modals/dom.service';
import { AddProjectSubscriber } from '../../../add-project.subscriber';
import { LanguagesService } from '../../../../../../../../../core/services/languages.service';
import { SearchProjectService } from '../../../../../../services/search-project.service';

@Component({
  selector: 'troi-add-project-sublist',
  templateUrl: './project-sublist-result-list.component.html',
  styleUrls: ['./project-sublist-result-list.component.scss'],
})
export class ProjectSublistResultListComponent {
  private element: ElementRef;

  public openState = false;

  public projectReactivationLSKey = 'projectReactivationAction';

  @ViewChild('optionList') optionList;

  @ViewChild('optionWrapper') optionWrapper;

  @Output() openChange = new EventEmitter<boolean>();

  @Output() loadMore = new EventEmitter();

  @Input() searchService: SearchProjectService;

  constructor(
    element: ElementRef,
    private troiDropDownCloseService: TroiDropDownCloseService,
    private bookingSettingsService: BookingSettingsService,
    private domService: DomService,
    private addProjectSubscriber: AddProjectSubscriber,
    public languagesService: LanguagesService,
  ) {
    this.element = element;
    this.troiDropDownCloseService.getEmitter().subscribe((event) => {
      const clickedElement = _.find(
        event.path || event.composedPath(),
        (path) => path === this.element.nativeElement || path === this.element.nativeElement.parentElement,
      );
      if (!clickedElement) {
        this.hideModal();
      }
      event.stopPropagation();
    });
  }

  hideModal() {
    this.openState = false;
    this.openChange.emit(this.openState);
  }

  public calculateScrollPosition() {
    if (!this.searchService.isLoading && this.searchService.canLoadMoreSubprojects) {
      this.searchService.incrementPageForSubProjects();
    }
  }

  getSubprojects(): SubprojectOptionInterface[] {
    return this.searchService.selectedProject
      ? [...this.searchService.selectedProject.subProjects, ...this.searchService.extraLoadedSubprojects]
      : [];
  }

  onLeafSelected(subProject: SubprojectOptionInterface, canClick) {
    if (!canClick) {
      return;
    }

    if (subProject.isBlocked && this.bookingSettingsService.settings.settings.bookOnBlockedProjectsAfterConfirmation) {
      const secondModalService = new ModalService(this.domService);
      secondModalService.init(
        BookOnBlockedComponent,
        {
          statuses: this.bookingSettingsService.settings.dropdowns.projectStatuses,
          selectedSubProject: subProject,
        },
        {},
        '600px',
        '500px',
        true,
      );
    } else {
      this.addProjectSubscriber.selectSubProject.next(subProject);
      this.hideModal();
    }
  }
}
