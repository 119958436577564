<div class="cp-list" *ngIf="bookingModel.isMultipleCps()">
  <div *ngFor="let project of bookingModel.projects">
    <div class="cp-list__row" *ngFor="let subproject of project.subprojects">
      <span *ngIf="subproject.subproject !== null">
        <div
          class="cp-list__label"
          [ngClass]="{ 'cp-list__label--open': subproject.accountingIsOpen }"
          (click)="subproject.accountingIsOpen = !subproject.accountingIsOpen"
        >
          <div class="cp-list__arrow-container">
            <troi-icon
              *ngIf="subproject.accountingIsOpen"
              class="cp-list__icon"
              icon="icon-bold-arrow-down"
            ></troi-icon>
            <troi-icon
              *ngIf="!subproject.accountingIsOpen"
              class="cp-list__icon"
              icon="icon-bold-arrow-right"
            ></troi-icon>
          </div>
          <span class="cp-list__customer">{{ project.customer.name }} >> </span>
          <span class="cp-list__project">{{ getLangValue(project.project) }} >> </span>
          <span class="cp-list__subProject">{{ getLangValue(subproject.subproject) }}</span>
        </div>
        <div class="cp-list__cps" *ngIf="subproject.accountingIsOpen">
          <calculation-positions-row
            *ngFor="let calculation of subproject.cps"
            [calculation]="calculation"
            [enabled]="enabled"
            (dataChanged)="onMultipleDataChanged(calculation, $event)"
            [year]="year"
            [supplier]="supplier"
          ></calculation-positions-row>
        </div>
      </span>
    </div>
  </div>
</div>
<div class="cp-list" *ngIf="!bookingModel.isMultipleCps() && bookingModel.getFormValueIdForKey('customer')">
  <div class="cp-list__row">
    <div class="cp-list__label" [ngClass]="{ 'cp-list__label--open': open }" (click)="open = !open">
      <div class="cp-list__arrow-container">
        <troi-icon *ngIf="open" class="cp-list__icon" icon="icon-bold-arrow-down"></troi-icon>
        <troi-icon *ngIf="!open" class="cp-list__icon" icon="icon-bold-arrow-right"></troi-icon>
      </div>
      <span class="cp-list__customer">{{ bookingModel.formValues.get('customer').value.name }} >> </span>
      <span class="cp-list__project">{{ getLangValue(bookingModel.formValues.get('project').value) }} >> </span>
      <span class="cp-list__subProject">{{ getLangValue(bookingModel.formValues.get('subproject').value) }}</span>
    </div>
    <div class="cp-list__cps" *ngIf="open">
      <calculation-positions-row
        [calculation]="singlePACp"
        (dataChanged)="onSingleDataChanged($event)"
        [enabled]="enabled"
        [year]="year"
        [supplier]="supplier"
        [counterAccount]="bookingModel.formValues.get('counterAccount').value"
      ></calculation-positions-row>
    </div>
  </div>
</div>
