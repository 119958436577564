import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { TroiDropdownListModel } from '../../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { AddProjectOptionSettingService } from '../../../services/add-project-option-setting.service';
import { AddProjectTypeEnum } from '../../../interfaces/add-project-type.enum';
import { AddProjectResultInterface } from '../../../interfaces/add-project-result.interface';
import { SearchProjectByCustomerService } from '../../../services/search-project-by-customer.service';
import { SearchProjectByProjectNameService } from '../../../services/search-project-by-project-name.service';
import { BookingSettingsService } from '../../../services/booking-settings.service';

@Component({
  selector: 'troi-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
})
export class AddProjectComponent implements OnInit {
  public AddProjectTypeEnum = AddProjectTypeEnum;

  selectedSearchType: AddProjectTypeEnum;

  @Input() client: number;

  @Input() enabled: boolean;

  @Input() multipleCps = false;

  @Output() public dataSelected = new EventEmitter<AddProjectResultInterface>();

  constructor(
    public bookingSettingsService: BookingSettingsService,
    private translate: TranslateService,
    private addProjectOptionSetting: AddProjectOptionSettingService,
    public searchProjectByCustomer: SearchProjectByCustomerService,
    public searchProjectByProjectName: SearchProjectByProjectNameService,
  ) {}

  ngOnInit() {
    this.selectedSearchType = this.addProjectOptionSetting.readFromLS(this.client);
    if (this.selectedSearchType === AddProjectTypeEnum.ADD_AUTO_PROJECT && !this.multipleCps) {
      this.selectedSearchType = this.addProjectOptionSetting.getDefaultSettings();
      this.addProjectOptionSetting.setOption(this.client, this.selectedSearchType);
    }
  }

  saveOptionToLS(option) {
    this.selectedSearchType = option;
    this.addProjectOptionSetting.setOption(this.client, option);
  }

  generateTypeOptions(): Array<TroiDropdownListModel> {
    const options = [
      {
        id: AddProjectTypeEnum.CUSTOMER,
        name: this.translate.instant('Common.labels.customer'),
      },
      {
        id: AddProjectTypeEnum.PROJECT,
        name: this.translate.instant('Common.labels.project'),
      },
      {
        id: AddProjectTypeEnum.K_NUMBER,
        name: this.translate.instant('Common.labels.knumber'),
      },
      {
        id: AddProjectTypeEnum.ORDER_NUMBER,
        name: this.translate.instant('Common.labels.ordernumber'),
      },
    ];

    const projectAssigmentSettings = {
      ...this.bookingSettingsService.settings.fallbackObjects.projectAssignment,
    };

    if (
      this.multipleCps &&
      !projectAssigmentSettings.areOwnFallbacks &&
      !projectAssigmentSettings.isManualProjectAssignment
    ) {
      options.push({ id: AddProjectTypeEnum.ADD_AUTO_PROJECT, name: 'Auto Project' });
    }

    const list = [];
    _.forEach(options, (element) => {
      list.push({
        label: element.name,
        value: element.id,
        active: true,
      });
    });

    return list;
  }
}
