import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchProjectByCustomerService } from '../../../../services/search-project-by-customer.service';
import { AddProjectResultInterface } from '../../../../interfaces/add-project-result.interface';
import { SubprojectOptionInterface } from '../../../../interfaces/project-list.interface';
import { AddProjectSubscriber } from '../add-project.subscriber';
import { LanguagesService } from '../../../../../../../core/services/languages.service';
import { SearchProjectService } from '../../../../services/search-project.service';

@Component({
  selector: 'troi-project-search',
  templateUrl: './project-search.component.html',
  styleUrls: ['./project-search.component.scss'],
})
export class ProjectSearchComponent implements OnInit, OnDestroy {
  searchInputSubject = new Subject<string>();

  open = false;

  private addProjectSubscription: Subscription;

  private searchSubscription: Subscription;

  @Input() client: number;

  @Input() enabled = true;

  @Output() public dataSelected = new EventEmitter<AddProjectResultInterface>();

  @Input() searchService: SearchProjectService;

  constructor(
    public addProjectByCustomer: SearchProjectByCustomerService,
    private addProjectSubscriber: AddProjectSubscriber,
    private languagesService: LanguagesService,
  ) {}

  openChange(state: boolean) {
    if (!this.enabled) {
      return;
    }
    this.open = state;
  }

  getValueForSelectedOption(): string {
    return `${this.languagesService.getLanguageValue(
      this.searchService.selectedProject.name,
    )} ${this.languagesService.getLanguageValue(this.searchService.selectedSubproject.name)}`;
  }

  clearValue() {
    this.searchService.clearValue();
  }

  addProjectAssignment() {
    if (!this.searchService.selectedSubproject) {
      return;
    }
    this.dataSelected.emit({
      projectReactivation: this.searchService.projectReactivation,
      customer: this.searchService.selectedProject.customer,
      project: this.searchService.selectedProject.id,
      subproject: this.searchService.selectedSubproject.id,
    });
    this.clearValue();
  }

  ngOnInit(): void {
    this.searchSubscription = this.searchInputSubject.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      if (this.searchService.search.length > 1) {
        this.searchService.initialLoadProjects();
        this.openChange(true);
      }
    });

    this.clearValue();
    this.searchService.client = this.client;

    this.addProjectSubscription = this.addProjectSubscriber.selectSubProject.subscribe(
      (selectedSubProject: SubprojectOptionInterface) => {
        this.searchService.selectedSubproject = selectedSubProject;
      },
    );
  }

  ngOnDestroy() {
    this.addProjectSubscription.unsubscribe();
    this.searchSubscription.unsubscribe();
  }
}
