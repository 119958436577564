import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { CashDiscountInterface, PaymentTermsInterface } from '../payment-terms.interface';
import { TroiDropDownCloseService } from '../../../../../../../shared/troi-dropdown-list/services/troi-dropdown-close.service';

@Component({
  selector: 'payment-terms-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  _numberOfCashDiscounts = 1;

  private element: ElementRef;

  @Input() terms: PaymentTermsInterface;

  @Input() numberOfCashDiscounts = 1;

  @Output() termsChanged = new EventEmitter<PaymentTermsInterface>();

  @Output() openChange = new EventEmitter<boolean>();

  constructor(element: ElementRef, private troiDropDownCloseService: TroiDropDownCloseService) {
    this.element = element;
    this.troiDropDownCloseService.getEmitter().subscribe((event) => {
      const clickedElement = _.find(
        event.path || event.composedPath(),
        (path) => path === this.element.nativeElement || path === this.element.nativeElement.parentElement,
      );
      if (!clickedElement) {
        this.hideModal();
      }
      event.stopPropagation();
    });
  }

  ngOnInit(): void {
    this._numberOfCashDiscounts = this.numberOfCashDiscounts;
  }

  hideModal() {
    this.openChange.emit(false);
  }

  applyPaymentTerms() {
    if (!this.isValid()) {
      return;
    }
    _.forEach(this.terms.discounts, (discount: CashDiscountInterface) => {
      if (discount.days !== null && !discount.rate) {
        discount.rate = '0';
      }
    });
    this.termsChanged.emit(this.terms);
  }

  refreshTerms() {
    this.terms = {
      net: 0,
      discounts: [],
    };
    for (let i = 0; i < this._numberOfCashDiscounts; i++) {
      this.terms.discounts.push({
        days: 0,
        rate: '0',
      });
    }
  }

  clearTerms() {
    this.refreshTerms();
    this.termsChanged.emit(this.terms);
  }

  canEditCashDiscounts(): boolean {
    return this.terms.net !== null && this.terms.net !== 0;
  }

  areCashDiscountsValid(): boolean {
    let valid = true;
    _.forEach(this.terms.discounts, (discount: CashDiscountInterface) => {
      if (discount.days === 0 || discount.days === null || discount.days.toString() === '') {
        valid = false;
        return false;
      }
    });

    return valid;
  }

  isValid(): boolean {
    return this.canEditCashDiscounts();
  }

  isPercentValid(value): boolean {
    return value === null || value === '' || parseFloat(value) <= 100;
  }

  parseFloat(value: any) {
    return parseFloat(value);
  }

  isDaysValid(discounts: CashDiscountInterface) {
    return discounts.days !== null && discounts.days !== 0 && discounts.days && discounts.days.toString() !== '';
  }
}
