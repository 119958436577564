import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ParentWindowRef } from '../../core/services/parent-window-ref.service';
import { TabInterface } from './tab.interface';

@Component({
  selector: 'troi-data-listing-tabs',
  templateUrl: './troi-data-listing-tabs.component.html',
  styleUrls: ['./troi-data-listing-tabs.component.scss'],
})
export class TroiDataListingTabsComponent {
  public isEditMenuOpen = false;

  @Input() tabs: TabInterface[] = [];
  @Input() newButtonDisabled = false;
  @Input() newButtonHidden = false;
  @Input() moreActionButtonDisabled = false;
  @Input() moreActionsOptions = [];
  @Input() width = '185px';

  @Input()
  public set useTabOptions(value: string | boolean) {
    this._useTabOptions = coerceBooleanProperty(value);
  }
  public get useTabOptions(): string | boolean {
    return this._useTabOptions;
  }
  private _useTabOptions = false;

  @Output() newClicked = new EventEmitter();
  @Output() moreActionsClicked = new EventEmitter();
  @Output() tabChanged = new EventEmitter();

  constructor(private parentWindowRef: ParentWindowRef) {}

  private changeParentWindowUrl(url: string) {
    if (url) {
      const tempUrl = `index.php?page=${url}`;
      this.parentWindowRef.nativeHistory.pushState('', '', tempUrl);
    }
  }

  changeTab(tab: TabInterface) {
    this.changeParentWindowUrl(tab.parentWindowUrl);
    this.tabChanged.emit(tab);
  }

  public moreActionOptionClick(event) {
    this.moreActionsClicked.emit(event);
  }

  public onOpeningState(event) {
    this.isEditMenuOpen = event;
  }

  public emitOnNew() {
    this.newClicked.emit(true);
  }
}
