<div class="booking-accounting-table">
  <div class="booking-accounting-table__cell cp">
    <div class="booking-accounting-table__label">{{ 'Booking.labels.total' | translate }}</div>
  </div>
  <div class="booking-accounting-table__cell">
    <div class="booking-accounting-table__label"></div>
  </div>
  <div class="booking-accounting-table__cell">
    <div class="booking-accounting-table__label"></div>
  </div>
  <div class="booking-accounting-table__cell">
    <div class="booking-accounting-table__label"></div>
  </div>
  <div class="booking-accounting-table__cell">
    <div class="booking-accounting-table__label"></div>
  </div>
  <div class="booking-accounting-table__cell tax">
    <div class="booking-accounting-table__label"></div>
  </div>
  <div class="booking-accounting-table__cell amount">
    <div class="booking-accounting-table__label">{{ total.formattedValueWithCurrencyMark }}</div>
  </div>
  <div class="booking-accounting-table__cell options">
    <div class="booking-accounting-table__label">
      <troi-checkbox
        [ngClass]="{ disabled: !enabled }"
        [disabled]="!enabled"
        label="{{ 'Booking.recorded.paid' | translate }}"
        [value]="paid"
        [removeLeftBorder]="false"
        (checkboxChange)="paidChanged.emit($event)"
        *ngIf="showPaid"
      ></troi-checkbox>
    </div>
  </div>
</div>
