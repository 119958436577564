<div class="add-project">
  <div class="add-project__type">
    <troi-dropdown-select
      [size]="'11px'"
      [initValue]="selectedSearchType"
      class="troi-dropdown-select--full"
      (selected)="saveOptionToLS($event)"
      [enabled]="enabled"
      [options]="generateTypeOptions()"
    ></troi-dropdown-select>
  </div>
  <div class="add-project__project">
    <troi-project-search
      [client]="client"
      [enabled]="enabled"
      *ngIf="AddProjectTypeEnum.CUSTOMER === selectedSearchType"
      [searchService]="searchProjectByCustomer"
      (dataSelected)="dataSelected.emit($event)"
    ></troi-project-search>
    <troi-project-search
      [client]="client"
      [enabled]="enabled"
      *ngIf="AddProjectTypeEnum.PROJECT === selectedSearchType"
      [searchService]="searchProjectByProjectName"
      (dataSelected)="dataSelected.emit($event)"
    ></troi-project-search>
    <troi-add-project-by-knumber
      [client]="client"
      [enabled]="enabled"
      *ngIf="AddProjectTypeEnum.K_NUMBER === selectedSearchType"
      (dataSelected)="dataSelected.emit($event)"
    ></troi-add-project-by-knumber>
    <troi-add-auto-project
      *ngIf="AddProjectTypeEnum.ADD_AUTO_PROJECT === selectedSearchType && multipleCps"
      (dataSelected)="dataSelected.emit($event)"
    >
    </troi-add-auto-project>
    <troi-add-project-by-order-number
      [client]="client"
      [enabled]="enabled"
      *ngIf="AddProjectTypeEnum.ORDER_NUMBER === selectedSearchType"
      (dataSelected)="dataSelected.emit($event)"
    ></troi-add-project-by-order-number>
  </div>
</div>
