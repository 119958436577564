import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CpModel } from '../../../../../models/cp.model';
import { LanguagesService } from '../../../../../../../../core/services/languages.service';
import { AccessRightService } from '../../../../../../../../core/services/access-right.service';
import { ModalService } from '../../../../../../../../shared/troi-modals/modal.service';
import { DomService } from '../../../../../../../../shared/troi-modals/dom.service';
import { AddEditComponent } from '../../../../../../../accounts/accounting-administration/account-list/modals/add-edit/add-edit.component';
import { AccountCreatedEmitter } from '../../../../../../../common/services/account-created.emitter';
import { BookingSettingsService } from '../../../../../services/booking-settings.service';
import { AccountBookingContextInterface } from '../../../../../interfaces/account-booking-context.interface';
import { ProjectAssignmentDataService } from '../../../../../services/project-assignment-data.service';
import { UserConfirmationEventEnum } from '../../../../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { TroiDropdownListModel } from '../../../../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';

@Component({
  selector: 'calculation-positions-row',
  templateUrl: './calculation-positions-row.component.html',
  styleUrls: ['./calculation-positions-row.component.scss'],
})
export class CalculationPositionsRowComponent implements OnInit, OnChanges {
  _cpModel: CpModel;

  newlyCreatedAccounts = [];

  @Input() calculation: CpModel;

  @Input() postingText: string;

  @Input() year;

  @Input() supplier;

  @Input() counterAccount;

  @Input() enabled = true;

  @Output() dataChanged = new EventEmitter();

  public constructor(
    private languagesService: LanguagesService,
    private accessRightService: AccessRightService,
    private domService: DomService,
    private accountEmitter: AccountCreatedEmitter,
    private projectAssignmentData: ProjectAssignmentDataService,
    private translateService: TranslateService,
    public bookingSettingsService: BookingSettingsService,
  ) {}

  ngOnInit() {
    this._cpModel = this.calculation;
  }

  getCpName(object) {
    return object && object.isNew ? object.nameToCreate : this.getLangValue(object);
  }

  getLangValue(object): string {
    return object ? this.languagesService.getLanguageValue(object.name) : '';
  }

  getTaxLabel(object): string {
    return object && object.name ? this.getLangValue(object) : this.translateService.instant('Booking.labels.0none');
  }

  onDataChanged() {
    this.dataChanged.emit(this._cpModel);
  }

  ngOnChanges(changes): void {
    if (changes.calculation) {
      this._cpModel = changes.calculation.currentValue;
    }

    if (changes.counterAccount) {
      this._cpModel.counterAccount = changes.counterAccount.currentValue;
    }
  }

  createAccount() {
    if (!this.canCreateAccount()) {
      return;
    }
    this.accountEmitter.accountCreated.pipe(first()).subscribe((result) => {
      this.newlyCreatedAccounts.push({
        label: result.name,
        value: result.id,
        active: true,
        fullObject: result,
      });
    });
    const secondModalService = new ModalService(this.domService);
    secondModalService.init(AddEditComponent, { isOpenedAsSecond: true }, {}, '500px', '800px', true);
  }

  getPreloadedOptionsForAccounts(): Array<TroiDropdownListModel> {
    return this.newlyCreatedAccounts.concat(this.generateDropdownOption(this._cpModel.account));
  }

  getPreloadedOptionsForCostCenters(): Array<TroiDropdownListModel> {
    return this.generateDropdownOption(this._cpModel.costCenter).concat([]);
  }

  canCreateAccount() {
    return this.accessRightService.isWriteAccess(this.bookingSettingsService.settings.permissions.account);
  }

  accountSelected(account: AccountBookingContextInterface) {
    this._cpModel.account = account;
    if (account && this.automaticReplaceData()) {
      this.replaceTaxAndCounterAccount(account);
    } else {
      this.onDataChanged();
    }
  }

  automaticReplaceData(): boolean {
    return !this.bookingSettingsService.settings.settings.bookkeepingIgnoreAutomaticDataEntry;
  }

  replaceTaxAndCounterAccount(account: AccountBookingContextInterface) {
    const showReplaceDataConfirmation =
      (account.tax && this._cpModel.getTaxId() !== null && this._cpModel.getTaxId() !== account.tax.id) ||
      (account.counterAccount &&
        this._cpModel.getCounterAccountId() !== null &&
        this._cpModel.getCounterAccountId() !== account.counterAccount.id);

    if (showReplaceDataConfirmation) {
      this.projectAssignmentData
        .openConfirmationPopup(
          'Booking.labels.resetTaxAndCounterAccount',
          'Booking.labels.account_reset',
          'Common.labels.cancel',
          'Booking.labels.doYouReallyWantToRemoveResetTaxAndCounterAccount',
        )
        .pipe(first())
        .subscribe((result) => {
          if (result === UserConfirmationEventEnum.EXECUTE) {
            this._cpModel.counterAccount = account.counterAccount
              ? account.counterAccount
              : this._cpModel.counterAccount;
            this._cpModel.tax = account.tax ? account.tax : this._cpModel.tax;
          }
          this.onDataChanged();
        });
    } else {
      this._cpModel.counterAccount = account.counterAccount ? account.counterAccount : this._cpModel.counterAccount;
      this._cpModel.tax = account.tax ? account.tax : this._cpModel.tax;
      this.onDataChanged();
    }
  }

  getPreloadedOptionsForCounterAccount(): Array<TroiDropdownListModel> {
    return this.newlyCreatedAccounts.concat(this.generateDropdownOption(this._cpModel.counterAccount));
  }

  generateDropdownOption(data): Array<TroiDropdownListModel> {
    return !data || !data.id
      ? []
      : [
          {
            label: data.name ? data.name : data.description,
            value: data.id,
            active: true,
            fullObject: data,
          },
        ];
  }
}
