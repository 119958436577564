<div class="add-by-knumber">
  <span
    class="add-by-knumber__placeholder"
    [ngClass]="{ 'add-by-knumber__placeholder--disabled': !enabled }"
    *ngIf="!addProjectByKnumber.selectedCP"
    >K-</span
  >
  <troi-input-with-mask
    [enabled]="enabled"
    [value]="addProjectByKnumber.search"
    mask="00000000000"
    (valueChanged)="addProjectByKnumber.search = $event"
    *ngIf="!addProjectByKnumber.selectedCP"
    (keyup)="searchInputSubject.next($event)"
  ></troi-input-with-mask>
  <div class="add-by-knumber__selected-value" *ngIf="addProjectByKnumber.selectedCP">
    <input [value]="getValueForSelectedOption()" disabled />
    <troi-icon [small]="true" icon="icon-close" (click)="clearValue()"></troi-icon>
  </div>
  <div
    class="add-by-knumber__btn"
    [ngClass]="{ 'add-by-knumber__btn--disabled': !enabled }"
    (click)="addProjectAssignment()"
  >
    {{ 'Booking.labels.add' | translate }}
  </div>
  <troi-add-project-by-knumber-list
    *ngIf="open"
    (openChange)="openChange($event)"
  ></troi-add-project-by-knumber-list>
</div>
