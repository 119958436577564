<troi-dropdown-select-lazy
  class="single-cp__dropdown"
  [filterObject]="filterObject"
  [secondFilterObject]="secondFilterObject"
  dataType="CPS"
  [initValue]="initValue"
  [predefinedOptions]="preloadedOptions"
  [withSearch]="true"
  [clearButton]="false"
  [translatableName]="true"
  (selected)="selectCp($event.fullObject)"
  *ngIf="!newCpActive"
  [enabled]="enabled"
  [reloadInitData]="reloadInitData"
  [lang]="lang"
  [returnSelectedObject]="true"
  [width]="'250%'"
></troi-dropdown-select-lazy>
<div class="new-cp" *ngIf="newCpActive">
  <div class="input-section">
    <input
      type="text"
      placeholder="{{ 'Booking.labels.enterCalculationName' | translate }}"
      [(ngModel)]="newCpName"
      [disabled]="!enabled"
      (blur)="applyNewCp()"
    />
  </div>
  <troi-icon
    [small]="true"
    icon="icon-close"
    [ngClass]="{ disabled: !enabled }"
    (click)="hideNewCpInput()"
  ></troi-icon>
</div>
<troi-icon
  *ngIf="!newCpActive"
  icon="icon-plus"
  class="single-cp__add"
  (click)="showNewCpInput()"
  [ngClass]="{ disabled: !enabled }"
  title="{{ 'Booking.labels.createNewCp' | translate }}"
></troi-icon>
