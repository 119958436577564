import { Component } from '@angular/core';
import { BookingFormComponent } from '../../../modules/bookings/common/modals/booking-form/booking-form.component';
import { BookingReceiptModel } from '../../../modules/bookings/common/models/booking-receipt.model';
import { BookingSettingsService } from '../../../modules/bookings/common/services/booking-settings.service';
import { FiltersService } from '../../../modules/bookings/digital-invoice/services/filters.service';
import { BaseModalDirective } from '../../troi-base-modal/baseModal.component';
import { ModalService } from '../../troi-modals/modal.service';
import { DigitalInvoiceReport, TroiDiReportsModalObject } from '../interfaces/troi-di-reports.interface';

@Component({
  selector: 'troi-digital-invoice-reports-modal',
  templateUrl: './troi-di-reports-modal.component.html',
  styleUrls: ['./troi-di-reports-modal.component.scss'],
})
export class TroiDiReportsModalComponent extends BaseModalDirective {
  public modalObject: TroiDiReportsModalObject;

  public get reports(): DigitalInvoiceReport[] {
    return this.modalObject.digitalInvoiceReports;
  }

  public invoiceServiceMail?: string = null;

  constructor(
    public modalService: ModalService,
    private _settingsService: BookingSettingsService,
    private _filterService: FiltersService,
  ) {
    super(modalService);
    this.modalObject = this.modalService.object;
  }

  public editRecorded(booking: BookingReceiptModel, report: DigitalInvoiceReport): void {
    this.modalService.destroy();

    const createPermission = this._settingsService.settings.permissions.createBooking;
    const transferPermission = this._settingsService.settings.permissions.transferBooking;

    this.modalService.object = {
      id: booking.id,
      year: this._filterService.getSelectedYear(),
      client: this._filterService.getSelectedClientId(),
      permissions: {
        baseData: createPermission,
        projectAssignment: createPermission,
        accounting: transferPermission,
      },
      reportMessage: report.message,
      isDigitalInvoice: true,
    };

    this.modalService.init(
      BookingFormComponent,
      {},
      { reloadList: this.modalObject.reloadListEmitter },
      '700px',
      '80%',
    );
  }
}
