import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectAssignmentDataService } from '../../../../../../services/project-assignment-data.service';
import { CpModel } from '../../../../../../models/cp.model';
import { Money } from '../../../../../../../../../shared/troi-money/money';

@Component({
  selector: 'troi-pa-calculation',
  templateUrl: './pa-calculation.component.html',
  styleUrls: ['./pa-calculation.component.scss'],
})
export class PaCalculationComponent implements OnInit {
  @Input() client: number;

  @Input() subproject: number;

  @Input() project: number;

  @Input() customer: string;

  @Input() cp: CpModel;

  @Input() showLabels = false;

  @Input() showDeleteBtn = false;

  @Input() netTotalAmount: Money;

  @Input() enabled = true;

  @Input() hasAutoCustomer = true;

  @Input() isNet: boolean;

  @Output() remove = new EventEmitter<string>();

  @Output() taxChanged = new EventEmitter();

  constructor(public projectAssignmentData: ProjectAssignmentDataService) {}

  ngOnInit() {
    if (!this.cp.amount) {
      this.cp.amount = this.projectAssignmentData.initMoneyValue('0');
    }
    if (!this.cp.amountNet) {
      this.cp.amountNet = this.projectAssignmentData.initMoneyValue('0');
    }
  }

  updateAmount(value) {
    this.cp.amountNet.update(value, true);
    this.projectAssignmentData.splitAmountChanged.next(true);
  }

  delete() {
    this.remove.emit(this.cp.internalId);
  }

  cpChanged(data) {
    this.cp.isNew = data.isNew;
    this.cp.client = this.client;
    this.cp.customer = this.customer;
    this.cp.project = this.project;
    this.cp.subproject = this.subproject;
    this.cp.nameToCreate = data.name;
    this.cp.cp = data.cp;
  }

  onTaxChanged(tax) {
    this.cp.tax = tax;
    this.taxChanged.emit(tax);
  }
}
