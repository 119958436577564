<div class="terms">
  <div class="terms__net">
    <span class="terms__title">{{ 'Booking.labels.netDays' | translate }}</span>
    <troi-input-with-mask
      [value]="terms.net"
      mask="000"
      (valueChanged)="terms.net = $event"
    ></troi-input-with-mask>
  </div>
  <div class="terms__discounts">
    <span class="terms__title">{{ 'Booking.labels.cashDiscountDaysPercentage' | translate }}</span>
    <div
      class="terms__discounts__single"
      *ngFor="let discounts of terms?.discounts; let number = index"
    >
      <span class="terms__discounts__index">{{ number + 1 }}.</span>
      <troi-input-with-mask
        [value]="discounts.days"
        mask="000"
        (valueChanged)="discounts.days = $event"
        [enabled]="canEditCashDiscounts()"
        placeholder="{{ 'Booking.labels.days' | translate }}"
      ></troi-input-with-mask>
      <troi-input-with-mask
        error="{{ 'Booking.labels.incorrectPercent' | translate }}"
        [value]="parseFloat(discounts.rate)"
        mask="separator.2"
        decimalMarker=","
        separatorLimit="100"
        (valueChanged)="discounts.rate = $event"
        [enabled]="isDaysValid(discounts)"
        [validationEnabled]="true"
        [fieldInvalid]="!isPercentValid(discounts.rate)"
        placeholder="%"
        class="terms__discounts__percent"
      ></troi-input-with-mask>
    </div>
  </div>
  <div class="terms__footer">
    <troi-btn (click)="clearTerms()" [outline]="true">{{
      'Booking.labels.clear' | translate
    }}</troi-btn>
    <troi-btn [disabled]="!isValid()" (click)="applyPaymentTerms()">{{
      'Booking.labels.add' | translate
    }}</troi-btn>
  </div>
</div>
