import * as _ from 'lodash';

export class PABasicModel {
  public internalId = this.generateUniqueId();

  generateUniqueId(): string {
    return `_${Math.random().toString(36).substr(2, 9)}`;
  }

  removeObject(data: any[], id: string) {
    return _.filter(data, (object) => object.internalId !== id);
  }
}
