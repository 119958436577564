import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'troi-linear-progress',
  templateUrl: './troi-linear-progress.component.html',
  styleUrls: ['./troi-linear-progress.component.scss'],
})
export class TroiLinearProgressComponent {
  @Input() public progress = 0;

  @HostBinding('class.troi-linear-progress--determinate') public get determinateClass(): boolean {
    return this.isDeterminate;
  }

  @HostBinding('class.troi-linear-progress--indeterminate') public get indeterminateClass(): boolean {
    return this.isIndeterminate;
  }

  @Input() public mode: 'determinate' | 'indeterminate' = 'determinate';

  public get isDeterminate(): boolean {
    return this.mode === 'determinate';
  }

  public get isIndeterminate(): boolean {
    return this.mode === 'indeterminate';
  }

  public get isIndeterminateWithProgress(): boolean {
    return this.isIndeterminate && this.progress > 0;
  }

  public get isIndeterminateWithoutProgress(): boolean {
    return this.isIndeterminate && this.progress === 0;
  }
}
