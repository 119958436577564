import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalDirective } from '../../../../../../../shared/troi-base-modal/baseModal.component';
import { UserConfirmationPopupInterface } from '../../../../../../../shared/troi-user-confirmation/user-confirmation-popup.interface';
import { ModalService } from '../../../../../../../shared/troi-modals/modal.service';
import { UserConfirmationSubscriber } from '../../../../../../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { BaseSettingsDropdownInterface } from '../../../../../../../core/interfaces/settings/dropdowns/base-settings-dropdown.interface';
import { LanguagesService } from '../../../../../../../core/services/languages.service';
import { AddProjectByKnumberService } from '../../../../services/add-project-by-knumber.service';
import { StorageNotificationService } from '../../../../../../../core/notifications/storageNotification.service';
import { AddProjectSubscriber } from '../add-project.subscriber';
import { CPOptionInterface, SubprojectOptionInterface } from '../../../../interfaces/project-list.interface';

@Component({
  selector: 'app-book-on-blocked',
  templateUrl: './book-on-blocked.component.html',
  styleUrls: ['./book-on-blocked.component.scss'],
})
export class BookOnBlockedComponent extends BaseModalDirective {
  public firstPage = true;

  public modalObject: UserConfirmationPopupInterface;

  public selectedStatus = null;

  @Input() statuses: BaseSettingsDropdownInterface[] = [];

  @Input() selectedSubProject?: SubprojectOptionInterface;

  @Input() selectedCalculationPosition?: CPOptionInterface;

  public constructor(
    public modalService: ModalService,
    public languagesService: LanguagesService,
    private subscriber: UserConfirmationSubscriber,
    public addProjectByKnumber: AddProjectByKnumberService,
    private notificationService: StorageNotificationService,
    private translateService: TranslateService,
    private addProjectSubscriber: AddProjectSubscriber,
  ) {
    super(modalService, false, true);
    this.modalObject = this.modalService.object;
  }

  close() {
    this.selectAndClose();
  }

  selectAndClose() {
    if (this.selectedCalculationPosition) {
      this.addProjectSubscriber.selectCalculationPosition.next(this.selectedCalculationPosition);
    } else {
      this.addProjectSubscriber.selectSubProject.next(this.selectedSubProject);
    }
    super.closeSecond();
  }

  executeSave() {
    if (this.selectedStatus === null) {
      this.selectAndClose();
      return;
    }
    this.addProjectByKnumber.network.changeProjectStatus(this.getSubProjectId(), this.selectedStatus).subscribe(() => {
      this.translateService.get('Booking.labels.projectStatusSuccessfullyChanged').subscribe((message) => {
        this.notificationService.showSuccess(message);
      });
      this.addProjectByKnumber.projectReactivation = true;
      this.selectAndClose();
    });
  }

  getSubProjectId(): number {
    if (this.selectedSubProject) {
      return this.selectedSubProject.id;
    }
    return this.selectedCalculationPosition.subproject;
  }

  selectStatus(id: number) {
    this.selectedStatus = id;
  }

  isSelected(id: number): boolean {
    return this.selectedStatus === id;
  }
}
