<div class="add-by-customer">
  <troi-input-field [enabled]="false" row-value value="Auto.Cp"></troi-input-field>
  <div
    class="add-by-customer__btn"
    (click)="addProjectAssignment()"
    [ngClass]="{ 'add-by-customer__btn--disabled': false }"
  >
    {{ 'Booking.labels.add' | translate }}
  </div>
</div>
