import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BookingReceiptFullModel } from '../../../../models/booking-receipt-full.model';
import { LanguagesService } from '../../../../../../../core/services/languages.service';
import { CpModel } from '../../../../models/cp.model';
import { Money } from '../../../../../../../shared/troi-money/money';
import { AccountingSettingsService } from '../../../../../../accounts/common/services/accounting-settings.service';

@Component({
  selector: 'troi-accounting-list',
  templateUrl: './accounting-list.component.html',
  styleUrls: ['./accounting-list.component.scss'],
})
export class AccountingListComponent implements OnInit, OnChanges {
  open = true;

  singlePACp: CpModel;

  @Input() bookingModel: BookingReceiptFullModel;

  @Input() amount: Money;

  @Input() tax;

  @Input() year;

  @Input() supplier;

  @Input() enabled = true;

  @Output() recalculateAmount = new EventEmitter();

  constructor(public languagesService: LanguagesService, private accountingSettings: AccountingSettingsService) {}

  ngOnInit() {
    if (!this.accountingSettings.settings) {
      this.accountingSettings.prepareSettings(this.bookingModel.getClient());
    }
    this.singlePACp = this.getCPModelForSingleProjectAssignment();
    this.bookingModel.formValues.valueChanges.subscribe((result) => {
      this.singlePACp.postingText = result.postingText;
      this.singlePACp.customer = result.customer;
      this.singlePACp.project = result.project;
      this.singlePACp.subproject = result.subproject;
      this.singlePACp.cp = result.cp;
      this.singlePACp.account = result.account;
      this.singlePACp.counterAccount = result.counterAccount;
      this.singlePACp.costCenter = result.costCenter;
      this.singlePACp.ksk = result.ksk;
      this.singlePACp.notBillable = result.notBillable !== null ? result.notBillable : result.cp?.notBillable;
    });
  }

  getCPModelForSingleProjectAssignment(): CpModel {
    const model = new CpModel(
      null,
      this.bookingModel.amount.tax,
      this.bookingModel.amount.totalNetAmount,
      this.bookingModel.amount.totalPrice,
      this.bookingModel.getClient(),
    );
    model.amount = this.bookingModel.amount.totalNetAmount;
    model.postingText = this.bookingModel.getFormValueIdForKey('postingText');
    model.account = this.bookingModel.getFormValueIdForKey('account');
    model.counterAccount = this.bookingModel.getFormValueIdForKey('counterAccount');
    model.costCenter = this.bookingModel.getFormValueIdForKey('costCenter');
    model.ksk = this.bookingModel.getFormValueIdForKey('ksk');
    model.notBillable = this.bookingModel.getFormValueIdForKey('notBillable');
    return model;
  }

  onSingleDataChanged(data: CpModel) {
    this.bookingModel.formValues.patchValue({
      postingText: data.postingText,
      account: data.account,
      counterAccount: data.counterAccount,
      costCenter: data.costCenter,
      ksk: data.ksk,
      notBillable: data.notBillable,
    });
    this.bookingModel.changeBaseDataTax(data.tax);
    this.recalculateAmount.emit();
  }

  onMultipleDataChanged(sourceCp: CpModel, targetCp: CpModel) {
    sourceCp.costCenter = targetCp.costCenter;
    sourceCp.counterAccount = targetCp.counterAccount;
    sourceCp.postingText = targetCp.postingText;
    sourceCp.ksk = targetCp.ksk;
    sourceCp.notBillable = targetCp.notBillable;
    this.bookingModel.checkIfSplitsHasDiffTax();
  }

  ngOnChanges(changes): void {
    if (changes.amount && this.singlePACp) {
      this.singlePACp.amount = changes.amount.currentValue;
    }
    if (changes.tax && this.singlePACp) {
      this.singlePACp.tax = changes.tax.currentValue;
    }
  }

  getLangValue(object): string {
    return object && object.name ? this.languagesService.getLanguageValue(object.name) : '';
  }
}
