import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input } from '@angular/core';
import { BookingSettingsInterface } from '../../modules/bookings/common/interfaces/booking-settings.interface';
import { BookingReceiptModel } from '../../modules/bookings/common/models/booking-receipt.model';
import { BookingSettingsService } from '../../modules/bookings/common/services/booking-settings.service';
import { ModalService } from '../troi-modals/modal.service';
import { TroiDiReportsEnum } from './enums/troi-di-reports.enum';
import { DigitalInvoiceReport } from './interfaces/troi-di-reports.interface';
import { TroiDiReportsModalComponent } from './modal/troi-di-reports-modal.component';

@Component({
  selector: 'troi-di-reports',
  templateUrl: './troi-di-reports.component.html',
  styleUrls: ['./troi-di-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TroiDiReportsComponent {
  @Input()
  public set bookingReceipt(bookingReceipt: BookingReceiptModel) {
    this._bookingReceipt = bookingReceipt;
    this.generateReports();
  }
  public get bookingReceipt(): BookingReceiptModel {
    return this._bookingReceipt;
  }
  private _bookingReceipt: BookingReceiptModel;

  @Input()
  public set reloadListEmitter(reloadListEmitter: EventEmitter<any>) {
    this._reloadListEmitter = reloadListEmitter;
  }
  public get reloadListEmitter(): EventEmitter<any> {
    return this._reloadListEmitter;
  }
  private _reloadListEmitter: EventEmitter<any>;

  public reports: DigitalInvoiceReport[] = [];

  private get settings(): BookingSettingsInterface {
    return this._settings.settings;
  }

  constructor(
    private _settings: BookingSettingsService,
    private _cdRef: ChangeDetectorRef,
    private _modalService: ModalService,
  ) {}

  private generateReports(): void {
    this.reports = this.sortReports([
      ...this.checkForSuppliers(this.bookingReceipt),
      ...this.checkForProject(this.bookingReceipt),
      ...this.checkForAccounts(this.bookingReceipt),
      ...this.checkForCostCenter(this.bookingReceipt),
    ]);

    this._cdRef.markForCheck();
  }

  private sortReports(reports: DigitalInvoiceReport[]): DigitalInvoiceReport[] {
    return reports.sort((a, b) => {
      return a.isForChild === b.isForChild ? a.childIndex - b.childIndex : a.isForChild ? 1 : -1;
    });
  }

  /**
   * Check if object accounts are present in booking receipt.
   *
   * @param bookingReceipt Booking receipt to check.
   */
  private checkForAccounts(bookingReceipt: BookingReceiptModel): DigitalInvoiceReport[] {
    const shouldHaveAccounts = this.settings.settings.bookkeepingAccountAssignment;
    if (!shouldHaveAccounts) {
      return [];
    }

    if (bookingReceipt?.children?.length > 0) {
      const childReports: DigitalInvoiceReport[] = [];

      bookingReceipt.children.forEach((child, index) => {
        childReports.push(
          ...this.checkForAccounts(child).map((report) => ({
            ...report,
            isForChild: true,
            childIndex: index,
            childBookingReceipt: child,
          })),
        );
      });

      return childReports;
    }

    const accountReports: DigitalInvoiceReport[] = [];

    if (!bookingReceipt.account) {
      accountReports.push({
        message: TroiDiReportsEnum.ACCOUNT_MISSING,
        bookingReceipt,
      });
    }

    if (!bookingReceipt.counterAccount) {
      accountReports.push({
        message: TroiDiReportsEnum.COUNTER_ACCOUNT_MISSING,
        bookingReceipt,
      });
    }

    return accountReports;
  }

  /**
   * Check if object cost center is present in booking receipt.
   *
   * @param bookingReceipt Booking receipt to check.
   */
  private checkForCostCenter(bookingReceipt: BookingReceiptModel): DigitalInvoiceReport[] {
    const bookkeepingCostCenterIsMandatory = this.settings.settings.bookkeepingCostCenterIsMandatory;
    if (!bookkeepingCostCenterIsMandatory) {
      return [];
    }

    if (bookingReceipt?.children?.length > 0) {
      const childReports: DigitalInvoiceReport[] = [];

      bookingReceipt.children.forEach((child, index) => {
        childReports.push(
          ...this.checkForCostCenter(child).map((report) => ({
            ...report,
            isForChild: true,
            childIndex: index,
            childBookingReceipt: child,
          })),
        );
      });

      return childReports;
    }

    const costCenterReports: DigitalInvoiceReport[] = [];

    if (!bookingReceipt.costCenter) {
      costCenterReports.push({
        message: TroiDiReportsEnum.COST_CENTER_MISSING,
        bookingReceipt,
      });
    }

    return costCenterReports;
  }

  /**
   * Check if object project is present in booking receipt.
   *
   * @param bookingReceipt Booking receipt to check.
   */
  private checkForProject(bookingReceipt: BookingReceiptModel): DigitalInvoiceReport[] {
    if (bookingReceipt?.children?.length > 0) {
      const childReports: DigitalInvoiceReport[] = [];

      bookingReceipt.children.forEach((child, index) => {
        childReports.push(
          ...this.checkForProject(child).map((report) => ({
            ...report,
            isForChild: true,
            childIndex: index,
            childBookingReceipt: child,
          })),
        );
      });

      return childReports;
    }

    const projectReports: DigitalInvoiceReport[] = [];

    if (!bookingReceipt.project) {
      projectReports.push({
        message: TroiDiReportsEnum.PROJECT_MISSING,
        bookingReceipt,
      });
    }

    return projectReports;
  }

  /**
   * Check if object supplier is present in booking receipt.
   *
   * @param bookingReceipt Booking receipt to check.
   */
  private checkForSuppliers(bookingReceipt: BookingReceiptModel): DigitalInvoiceReport[] {
    const supplierReports: DigitalInvoiceReport[] = [];

    if (!bookingReceipt.supplier) {
      supplierReports.push({
        message: TroiDiReportsEnum.SUPPLIER_MISSING,
        bookingReceipt,
      });
    }

    return supplierReports;
  }

  public reportsButtonClicked(): void {
    if (this.reports.length === 0) {
      return;
    }

    this._modalService.object = {
      digitalInvoiceReports: this.reports,
      reloadListEmitter: this.reloadListEmitter,
    };

    this._modalService.init(TroiDiReportsModalComponent, {}, {});
  }
}
