<div class="add-by-customer">
  <troi-input-field
    [enabled]="enabled"
    row-value
    (keyup)="searchInputSubject.next($event)"
    [height]="'30px'"
    [(ngModel)]="searchService.search"
    *ngIf="!searchService.selectedSubproject"
  ></troi-input-field>
  <div class="add-by-customer__selected-value" *ngIf="searchService.selectedSubproject">
    <input [value]="getValueForSelectedOption()" disabled />
    <troi-icon [small]="true" icon="icon-close" (click)="clearValue()"></troi-icon>
  </div>
  <div
    class="add-by-customer__btn"
    (click)="addProjectAssignment()"
    [ngClass]="{ 'add-by-customer__btn--disabled': !enabled }"
  >
    {{ 'Booking.labels.add' | translate }}
  </div>
  <troi-add-project-list
    *ngIf="open"
    (openChange)="openChange($event)"
    [searchService]="searchService"
  ></troi-add-project-list>
</div>
