import { BookingCP, BookingProject } from '../interfaces/booking-receipt-full.interface';
import { PABasicModel } from './PA-basic.model';
import { Money } from '../../../../shared/troi-money/money';
import { BookingAccount, BookingCostCenter } from '../interfaces/booking-receipt.interface';

export class CpModel extends PABasicModel {
  public client: number;

  public customer: string;

  public project: number;

  public subproject: number;

  public nameToCreate: string;

  public isNew = false;

  public tax = null;

  public amount: Money;

  public amountNet: Money;

  public queueId: number;

  public unit: number;

  public postingText = '';

  public account = null;

  public costCenter = null;

  public ksk = false;

  public splitId = null;

  public notBillable = null;

  public cp: BookingCP;

  constructor(
    cp: BookingCP,
    tax = null,
    amount: Money = null,
    amountNet: Money = null,
    client = null,
    public counterAccount = null,
  ) {
    super();
    this.cp = cp;
    this.tax = tax;
    this.amount = amount;
    this.amountNet = amountNet;
    this.client = client;
  }

  change(data: BookingCP) {
    this.cp = data;
  }

  getCpId() {
    return this.getDropdownId(this.cp);
  }

  getTaxId() {
    return this.getDropdownId(this.tax);
  }

  getCounterAccountId() {
    return this.getDropdownId(this.counterAccount);
  }

  getDropdownId(data) {
    return data ? (data.id ? data.id : data) : null;
  }

  getNbiDefaultValue() {
    return this.notBillable !== null ? this.notBillable : this.cp?.notBillable;
  }
}
