<div class="booking-accounting-table">
  <div class="booking-accounting-table__cell cp">
    <div class="booking-accounting-table__label label__center">
      {{ 'Booking.labels.accountingCpsHeader' | translate }}
    </div>
  </div>
  <div class="booking-accounting-table__cell">
    <div class="booking-accounting-table__label label_left">
      {{ 'Booking.labels.postingText' | translate }}
    </div>
  </div>
  <div class="booking-accounting-table__cell">
    <div class="booking-accounting-table__label label_left">
      {{ 'Booking.labels.Account' | translate }}
    </div>
  </div>
  <div class="booking-accounting-table__cell">
    <div class="booking-accounting-table__label label_left">
      {{ 'Booking.labels.counterAccount' | translate }}
    </div>
  </div>
  <div class="booking-accounting-table__cell">
    <div class="booking-accounting-table__label label_left">
      {{ 'Booking.labels.costCenter' | translate }}
    </div>
  </div>
  <div class="booking-accounting-table__cell tax">
    <div class="booking-accounting-table__label label_left">
      {{ 'Booking.labels.taxKey' | translate }}
    </div>
  </div>
  <div class="booking-accounting-table__cell amount">
    <div class="booking-accounting-table__label label_left">
      {{ 'Booking.labels.amount' | translate }} (N)
    </div>
  </div>
  <div class="booking-accounting-table__cell options">
    <div class="booking-accounting-table__label label_left">
      {{ 'Booking.labels.options' | translate }}
    </div>
  </div>
</div>
