import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMaskModule } from 'ngx-mask';
import { LegacyAjaxServerNetworkService } from '../core/network/legacyAjaxServer.network.service';
import { TroiDatePipe } from '../core/pipes/date.pipe';
import { LinkifyPipe } from '../core/pipes/linkify.pipe';
import { NumberPipe } from '../core/pipes/number.pipe';
import { SafePipe } from '../core/pipes/safe.pipe';
import { SingularPluralPipe } from '../core/pipes/singularPlural.pipe';
import { TimePipe } from '../core/pipes/time.pipe';
import { WorkingTimeService } from '../modules/desk/widgets/working-time-recording/working-time.service';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatepickerPositionService } from './datepicker/datepickerPosition.service';
import { FocusDirective } from './focus/focus.directive';
import { RepeatDirective } from './repeat/repeat.directive';
import { SearchSelectComponent } from './search-select/search-select.component';
import { SelectComponent } from './select/select.component';
import { TroiBarChartComponent } from './troi-bar-chart/troi-bar-chart.component';
import { TroiBreadcrumbsComponent } from './troi-breadcrumbs/troi-breadcrumbs.component';
import { TroiBtnComponent } from './troi-btn/troi-btn.component';
import { TroiBudgetStatusComponent } from './troi-budget-status/troi-budget-status.component';
import { TroiCheckboxComponent } from './troi-checkbox/troi-checkbox.component';
import { TroiCheckmarkComponent } from './troi-checkmark/troi-checkmark.component';
import { TroiCircleIconComponent } from './troi-circle-icon/troi-circle-icon.component';
import { TroiColumnsListComponent } from './troi-columns-selector/troi-columns-list/troi-columns-list.component';
import { TroiColumnsSelectorComponent } from './troi-columns-selector/troi-columns-selector.component';
import { TroiDataListingFiltersComponent } from './troi-data-listing-filters/troi-data-listing-filters.component';
import { TroiDataListingTabsComponent } from './troi-data-listing-tabs/troi-data-listing-tabs.component';
import { TroiDataTabsComponent } from './troi-data-tabs/troi-data-tabs.component';
import { TroiDaterangepickerComponent } from './troi-daterangepicker/troi-daterangepicker.component';
import { TroiDaterangepickerDirective } from './troi-daterangepicker/troi-daterangepicker.directive';
import { TroiDiReportsModalComponent } from './troi-di-reports/modal/troi-di-reports-modal.component';
import { TroiDiReportsComponent } from './troi-di-reports/troi-di-reports.component';
import { TroiDonutChartComponent } from './troi-donut-chart/troi-donut-chart.component';
import { TroiDropDownCloseService } from './troi-dropdown-list/services/troi-dropdown-close.service';
import { TroiDropdownListComponent } from './troi-dropdown-list/troi-dropdown-list.component';
import { TroiDropdownOptionComponent } from './troi-dropdown-option/troi-dropdown-option.component';
import { TroiDropdownSelectLazyComponent } from './troi-dropdown-select-lazy/troi-dropdown-select-lazy.component';
import { TroiDropdownSelectComponent } from './troi-dropdown-select/troi-dropdown-select.component';
import { TroiFilterChipsComponent } from './troi-filter-chips/troi-filter-chips.component';
import { FilterSetsFactory } from './troi-filter-sets/factories/filter-sets.factory';
import { DeleteConfirmationComponent } from './troi-filter-sets/modals/delete-confirmation/delete-confirmation.component';
import { SaveFilterSetComponent } from './troi-filter-sets/modals/save-filter-set/save-filter-set.component';
import { SaveFilterSetFormService } from './troi-filter-sets/modals/save-filter-set/services/save-filter-set-form.service';
import { TroiFilterSetsNetworkService } from './troi-filter-sets/network/troi-filter-sets.network';
import { TroiFilterSetsService } from './troi-filter-sets/services/troi-filter-sets.service';
import { TroiFilterSetsComponent } from './troi-filter-sets/troi-filter-sets.component';
import { TroiFilterModalComponent } from './troi-filter-with-modal/troi-filter-modal/troi-filter-modal.component';
import { TroiFilterWithModalComponent } from './troi-filter-with-modal/troi-filter-with-modal.component';
import { TroiFilterComponent } from './troi-filter/troi-filter.component';
import { FooterComponent } from './troi-footer/footer.component';
import { SettingsModalComponent } from './troi-footer/modal/settings-modal/settings-modal.component';
import { FooterNetworkService } from './troi-footer/network/footer.network.service';
import { FooterService } from './troi-footer/services/footer.service';
import { TroiIconButtonComponent } from './troi-icon-button/troi-icon-button.component';
import { TroiIconComponent } from './troi-icon/troi-icon.component';
import { VirtualEventStrategiesFactory } from './troi-inline-edit/eventStrategies/virtual-event-strategies.factory';
import { TroiInlineTabService } from './troi-inline-edit/services/troi-inline-tab.service';
import { TroiInlineEditComponent } from './troi-inline-edit/troi-inline-edit.component';
import { TroiInlineTabDirective } from './troi-inline-edit/troi-inline-tab.directive';
import { TroiInputFieldComponent } from './troi-input-field/troi-input-field.component';
import { TroiInputWithMaskComponent } from './troi-input-with-mask/troi-input-with-mask.component';
import { TroiInputComponent } from './troi-input/troi-input.component';
import { TroiLinearProgressComponent } from './troi-linear-progress/troi-linear-progress.component';
import { TroiLoadingSpinnerComponent } from './troi-loading-spinner/troi-loading-spinner.component';
import { TroiLoadingComponent } from './troi-loading/troi-loading.component';
import { TroiModalContentComponent } from './troi-modal-content/troi-modal-content.component';
import { TroiModalFooterComponent } from './troi-modal-footer/troi-modal-footer.component';
import { TroiModalHeaderComponent } from './troi-modal-header/troi-modal-header.component';
import { TroiModalRowComponent } from './troi-modal-row/troi-modal-row.component';
import { TroiModalSectionComponent } from './troi-modal-section/troi-modal-section.component';
import { DomService } from './troi-modals/dom.service';
import { ModalService } from './troi-modals/modal.service';
import { MoneyDirective } from './troi-money/money.directive';
import { TroiMyProjectsFilterModalComponent } from './troi-my-projects-filter/troi-my-projects-filter-modal/troi-my-projects-filter-modal.component';
import { TroiMyProjectsFilterComponent } from './troi-my-projects-filter/troi-my-projects-filter.component';
import { TroiMyProjectsComponent } from './troi-my-projects/troi-my-projects.component';
import { TroiRecordProjectTimeComponent } from './troi-my-projects/troi-record-project-time/troi-record-project-time.component';
import { ObjectChangesService } from './troi-original-value-tooltip/object-changes.service';
import { OriginalValueTooltipComponent } from './troi-original-value-tooltip/original-value-tooltip.component';
import { TroiPaginatorComponent } from './troi-paginator/troi-paginator.component';
import { TroiPersonComponent } from './troi-person/troi-person.component';
import { TroiPopoverComponent } from './troi-popover/troi-popover.component';
import { TroiProgressBarComponent } from './troi-progress-bar/troi-progress-bar.component';
import { TroiProjectTooltipComponent } from './troi-project-tooltip/troi-project-tooltip.component';
import { TroiRadioIconsComponent } from './troi-radio-icons/troi-radio-icons.component';
import { DateDirective } from './troi-range-date/date.directive';
import { TroiRangeDateComponent } from './troi-range-date/troi-range-date.component';
import { TroiRangeStringComponent } from './troi-range-string/troi-range-string.component';
import { TroiRangeComponent } from './troi-range/troi-range.component';
import { TroiPopupSearchProjectComponent } from './troi-record-project-time-table/troi-popup-search-project/troi-popup-search-project.component';
import { TroiRecordProjectTimeTableComponent } from './troi-record-project-time-table/troi-record-project-time-table.component';
import { TroiRecordWorkingTimeTableComponent } from './troi-record-working-time-table/troi-record-working-time-table.component';
import { TroiSliderComponent } from './troi-slider/troi-slider.component';
import { TroiSwitchComponent } from './troi-switch/troi-switch.component';
import { TroiTableColumnComponent } from './troi-table/row/column/column.component';
import { TroiTableRowComponent } from './troi-table/row/row.component';
import { ComponentFactoryService } from './troi-table/services/component-factory.service';
import { StringComponent } from './troi-table/string/string.component';
import { TroiTableComponent } from './troi-table/troi-table.component';
import { TroiTextareaComponent } from './troi-textarea/troi-textarea.component';
import { TroiTextfieldComponent } from './troi-textfield/troi-textfield.component';
import { TroiTimeInputComponent } from './troi-time-input/troi-time-input.component';
import { TroiTimeSwitchComponent } from './troi-time-switch/troi-time-switch.component';
import { TroiTimepickerComponent } from './troi-timepicker/troi-timepicker.component';
import { TroiTopBarBtnsComponent } from './troi-top-bar-btns/troi-top-bar-btns.component';
import { TroiUploadIconComponent } from './troi-upload-icon/troi-upload-icon.component';
import { TroiUserConfirmationComponent } from './troi-user-confirmation/troi-user-confirmation.component';
import { UserConfirmationSubscriber } from './troi-user-confirmation/user-confirmation.subscriber';
import { TroiWeekCalendarComponent } from './troi-week-calendar/troi-week-calendar.component';
import { TimelineviewViewSwitchComponent } from './view-switch/view-switch.component';

const components = [
  TroiSwitchComponent,
  TroiSliderComponent,
  TroiIconComponent,
  TroiUploadIconComponent,
  TroiInputComponent,
  TroiInlineEditComponent,
  TroiDropdownListComponent,
  TroiDropdownOptionComponent,
  TroiDropdownSelectComponent,
  TroiModalHeaderComponent,
  TroiModalContentComponent,
  TroiModalFooterComponent,
  TroiModalRowComponent,
  TroiModalSectionComponent,
  TroiBtnComponent,
  TroiInputFieldComponent,
  TroiTextareaComponent,
  TroiTextfieldComponent,
  TroiFilterComponent,
  FocusDirective,
  MoneyDirective,
  TroiInlineTabDirective,
  OriginalValueTooltipComponent,
  TroiLoadingComponent,
  RepeatDirective,
  TroiDataListingTabsComponent,
  TroiDataListingFiltersComponent,
  TroiFilterModalComponent,
  TroiBreadcrumbsComponent,
  TroiFilterWithModalComponent,
  TroiColumnsSelectorComponent,
  TroiColumnsListComponent,
  TroiTableComponent,
  TroiTableRowComponent,
  TroiTableColumnComponent,
  TroiRangeComponent,
  StringComponent,
  TroiFilterChipsComponent,
  TroiCheckboxComponent,
  TroiPaginatorComponent,
  TroiLoadingSpinnerComponent,
  SafePipe,
  NumberPipe,
  TroiDatePipe,
  TimePipe,
  SingularPluralPipe,
  LinkifyPipe,
  TroiRangeStringComponent,
  TroiCheckmarkComponent,
  TroiDropdownSelectLazyComponent,
  DateDirective,
  TroiInputWithMaskComponent,
  TroiUserConfirmationComponent,
  TroiDataTabsComponent,
  TroiPersonComponent,
  TroiBudgetStatusComponent,
  TroiFilterSetsComponent,
  DeleteConfirmationComponent,
  SaveFilterSetComponent,
  SearchSelectComponent,
  SelectComponent,
  DatepickerComponent,
  TroiRadioIconsComponent,
  TroiTopBarBtnsComponent,
  TroiProgressBarComponent,
  TroiPopoverComponent,
  TimelineviewViewSwitchComponent,
  TroiTimepickerComponent,
  TimelineviewViewSwitchComponent,
  TroiBarChartComponent,
  TroiDonutChartComponent,
  TroiWeekCalendarComponent,
  TroiTimeSwitchComponent,
  TroiMyProjectsComponent,
  TroiRecordProjectTimeTableComponent,
  TroiRecordWorkingTimeTableComponent,
  TroiProjectTooltipComponent,
  TroiIconButtonComponent,
  TroiDaterangepickerComponent,
  TroiDaterangepickerDirective,
  TroiLinearProgressComponent,
  TroiDiReportsComponent,
  TroiDiReportsModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ClickOutsideModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMaskModule.forRoot(),
    NgxSliderModule,
    NgbModule,
    ContenteditableValueAccessorModule,
    ScrollingModule,
  ],
  declarations: [
    ...components,
    FooterComponent,
    TroiFilterChipsComponent,
    SettingsModalComponent,
    TroiRangeDateComponent,
    TroiCircleIconComponent,
    TroiMyProjectsFilterComponent,
    TroiMyProjectsFilterModalComponent,
    TroiRecordProjectTimeComponent,
    TroiRecordProjectTimeTableComponent,
    TroiRecordWorkingTimeTableComponent,
    TroiPopupSearchProjectComponent,
    TroiProjectTooltipComponent,
    TroiTimeInputComponent,
  ],
  bootstrap: [],
  providers: [
    TroiDropDownCloseService,
    ObjectChangesService,
    TroiInlineTabService,
    DomService,
    VirtualEventStrategiesFactory,
    ComponentFactoryService,
    FooterNetworkService,
    FooterService,
    LegacyAjaxServerNetworkService,
    ModalService,
    UserConfirmationSubscriber,
    DecimalPipe,
    NumberPipe,
    TroiDatePipe,
    TimePipe,
    TroiFilterSetsService,
    TroiFilterSetsNetworkService,
    SaveFilterSetFormService,
    FilterSetsFactory,
    WorkingTimeService,
    DatePipe,
    DatepickerPositionService,
  ],
  exports: [
    ...components,
    TranslateModule,
    FooterComponent,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TroiRangeDateComponent,
    TroiCircleIconComponent,
    TroiTimeInputComponent,
  ],
})
export class SharedModule {}
