<troi-pa-project
  *ngFor="let project of bookingModel.projects; let i = index"
  [project]="project"
  [client]="client"
  [index]="i + 1"
  (remove)="removeProject($event)"
  (taxChanged)="taxChanged.emit($event)"
  [unassignedAmount]="bookingModel.unassignedAmount"
  [isNet]="bookingModel.amount.isNet"
  [netTotalAmount]="bookingModel.amount.totalNetAmount"
  [enabled]="enabled"
></troi-pa-project>
<troi-modal-row *ngIf="loading">
  <div row-label></div>
  <div row-value class="booking-form__row">
    <troi-loading-spinner width="500px" height="50px"></troi-loading-spinner>
  </div>
</troi-modal-row>
<troi-modal-row class="add-project">
  <div row-label>{{ 'Booking.labels.addProject' | translate }}</div>
  <div row-value class="booking-form__row">
    <troi-add-project
      [client]="client"
      (dataSelected)="fillDropdownData($event)"
      [enabled]="enabled"
      [multipleCps]="bookingModel.multipleCps"
    ></troi-add-project>
  </div>
</troi-modal-row>
