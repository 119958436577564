<div class="cp">
  <div class="cp__row cps">
    <div class="cps__unassigned" *ngIf="showLabels">
      <span class="cps__description__cp"
        >{{ 'Booking.labels.totalUnassignedAmount' | translate }}
        <strong>{{ netTotalAmount?.formattedValueWithCurrencyMark }}</strong></span
      >
    </div>
    <div class="container-fluid">
      <div class="row cps__description" *ngIf="showLabels">
        <div class="col-6 p-0">
          <span class="cps__description__cp">{{ 'Common.labels.cp' | translate }} </span>
        </div>
        <div class="col-3 p-0">
          <span class="cps__description__tax">{{ 'Booking.labels.tax' | translate }} </span>
        </div>
        <div class="col-1 p-0 flex-auto">
          <span class="cps__description__amount"
            >{{ 'Booking.labels.amount' | translate }} ({{
              'Booking.labels.bookingFormNetLabel' | translate
            }})</span
          >
        </div>
        <div class="col-1 p-0 flex-auto">
          <span class="cps__description__amount"
            >{{ 'Booking.labels.amount' | translate }} ({{
              'Booking.labels.bookingFormGrossLabel' | translate
            }})</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-6 p-0">
          <troi-cp-data
            [enabled]="subproject !== null && enabled"
            [filterObject]="subproject"
            [secondFilterObject]="client"
            [initValue]="cp?.getCpId()"
            [preloadedOptions]="projectAssignmentData.generateDropdownOption(cp?.cp)"
            [reloadInitData]="subproject"
            [lang]="projectAssignmentData.languagesService.getLanguage()"
            (cpChanged)="cpChanged($event)"
          ></troi-cp-data>
        </div>
        <div class="col-3 p-0">
          <troi-dropdown-select-lazy
            [enabled]="subproject !== null && (hasAutoCustomer || enabled)"
            [filterObject]="client"
            dataType="TAX_RATES"
            [translatableName]="true"
            [initValue]="cp.getTaxId()"
            [withSearch]="false"
            class="cps__values__tax"
            [initLazyOptionOnInit]="true"
            [returnSelectedObject]="true"
            (selected)="onTaxChanged($event?.fullObject)"
            placeholder="Booking.labels.0none"
            [pageSize]="50"
            [width]="'300px'"
          ></troi-dropdown-select-lazy>
        </div>
        <div class="col-1 p-0 flex-auto">
          <troi-input-field
            class="add-edit__row__inline__input"
            [numbersOnly]="true"
            [enabled]="subproject !== null && (hasAutoCustomer || enabled)"
            [value]="cp?.amountNet?.formattedValue"
            (change)="updateAmount($event.target.value)"
            money-format
            [money]="cp?.amountNet"
            [currency]="projectAssignmentData.bookingSettingsService.systemCurrency"
          ></troi-input-field>
        </div>
        <div class="col-1 p-0 flex-auto">
          <troi-input-field
            class="add-edit__row__inline__input"
            [numbersOnly]="true"
            [enabled]="false"
            [value]="cp?.amount?.formattedValue"
            money-format
            [money]="cp?.amount"
            [currency]="projectAssignmentData.bookingSettingsService.systemCurrency"
          ></troi-input-field>
        </div>
        <div class="w-auto p-0">
          <div
            class="cps-delete"
            (click)="delete()"
            *ngIf="showDeleteBtn && (hasAutoCustomer || enabled)"
          >
            <troi-icon icon="icon-trash-can" [small]="true"></troi-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
