import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { TroiDropDownCloseService } from '../../../../../../../../shared/troi-dropdown-list/services/troi-dropdown-close.service';
import { ProjectOptionInterface } from '../../../../../interfaces/project-list.interface';
import { LanguagesService } from '../../../../../../../../core/services/languages.service';
import { SearchProjectService } from '../../../../../services/search-project.service';

@Component({
  selector: 'troi-add-project-list',
  templateUrl: './project-result-list.component.html',
  styleUrls: ['./project-result-list.component.scss'],
})
export class ProjectResultListComponent {
  private element: ElementRef;

  public openState = false;

  public openSublist = false;

  @ViewChild('optionList') optionList;

  @ViewChild('optionWrapper') optionWrapper;

  @Output() openChange = new EventEmitter<boolean>();

  @Input() searchService: SearchProjectService;

  constructor(
    element: ElementRef,
    private troiDropDownCloseService: TroiDropDownCloseService,
    public languagesService: LanguagesService,
  ) {
    this.element = element;
    this.troiDropDownCloseService.getEmitter().subscribe((event) => {
      const clickedElement = _.find(
        event.path || event.composedPath(),
        (path) => path === this.element.nativeElement || path === this.element.nativeElement.parentElement,
      );
      if (!clickedElement) {
        this.hideModal();
      }
      event.stopPropagation();
    });
  }

  public calculateScrollPosition() {
    const currentScrollValue =
      this.optionList.nativeElement.scrollHeight +
      20 -
      (this.optionWrapper.nativeElement.scrollTop + this.optionWrapper.nativeElement.clientHeight);
    if (!this.searchService.isLoading && currentScrollValue <= 15) {
      this.searchService.incrementPageForProjects();
    }
  }

  hideModal() {
    this.openState = false;
    this.openChange.emit(this.openState);
  }

  onSubprojectSelected() {
    this.openSublist = false;
    this.hideModal();
  }

  onOpenSublist(project: ProjectOptionInterface) {
    this.searchService.extraLoadedSubprojects = [];
    this.searchService.canLoadMoreSubprojects = true;
    this.searchService.sublistPage = 1;
    this.searchService.selectedProject = project;
    this.openSublist = true;
  }

  hasProjectSubProjects = (project: ProjectOptionInterface): boolean => {
    return project.subProjects.length > 0;
  };
}
