import * as _ from 'lodash';
import { Money } from '../../../../shared/troi-money/money';
import { BookingTax } from '../interfaces/booking-receipt.interface';

export class AmountModel {
  constructor(
    public quantity: string,
    public unit: number,
    public pricePerUnit: Money,
    public totalPrice: Money,
    public isNet: boolean,
    public tax: any,
    public isQuantified: boolean,
    public totalNetAmount: Money = null,
    public netPricePerUnit: Money = null,
  ) {}

  toBackendRequest() {
    return {
      quantity: this.quantity?.replace(',', '.'),
      unit: this.unit,
      pricePerUnit: this.pricePerUnit.forBackend,
      totalPrice: this.totalPrice.forBackend,
      isNet: this.isNet,
      tax: !this.isEmptyTax() && this.tax.id !== -1 ? this.tax.id : null,
      isQuantified: this.isQuantified,
    };
  }

  getValidTax() {
    return !this.isEmptyTax() && this.tax.id !== -1 ? this.tax : null;
  }

  getTaxId(): number {
    return this.tax && this.tax.id ? (this.tax.id === -1 ? null : this.tax.id) : this.tax;
  }

  isMultipleTaxOptionSelected(): boolean {
    return this.tax && this.tax.id === -1;
  }

  isValid(): boolean {
    return !this.totalPrice.isZero();
  }

  isEmptyTax(): boolean {
    return _.isNull(this.tax) || this.tax.toString() === '' || _.isNull(this.tax.id);
  }
}
