<div class="add-by-ordernumber">
  <div class="add-by-ordernumber__container">
    <span
      class="add-by-ordernumber__placeholder"
      [ngClass]="{ 'add-by-ordernumber__placeholder--disabled': !enabled }"
    ></span>
    <troi-input-with-mask
      [enabled]="enabled"
      [value]="addProjectByOrderNumber.search"
      (valueChanged)="addProjectByOrderNumber.search = $event"
      *ngIf="!addProjectByOrderNumber.selectedCP"
      (keyup)="searchInputSubject.next($event)"
    ></troi-input-with-mask>
    <div class="add-by-ordernumber__selected-value" *ngIf="addProjectByOrderNumber.selectedCP">
      <input [value]="getValueForSelectedOption()" disabled />
      <troi-icon [small]="true" icon="icon-close" (click)="clearValue()"></troi-icon>
    </div>
    <troi-btn
      class="add-by-ordernumber__btn"
      [ngClass]="{ 'add-by-ordernumber__btn--disabled': !enabled }"
      (click)="addProjectAssignment()"
    >
      {{ 'Booking.labels.add' | translate }}
    </troi-btn>
    <troi-add-project-by-order-number-list
      *ngIf="open"
      (openChange)="openChange($event)"
    ></troi-add-project-by-order-number-list>
  </div>

  <div class="add-by-ordernumber__btn-container">
    <troi-btn
      class="add-by-ordernumber__redirection-btn"
      (click)="onGoToOrderItem()"
      *ngIf="addProjectByOrderNumber.selectedCP && addProjectByOrderNumber.selectedCP.id"
      >{{ 'Booking.labels.orderItem' | translate }}</troi-btn
    >
    <troi-btn class="add-by-ordernumber__redirection-btn" (click)="onGoToOrderList()">{{
      'Booking.labels.orderList' | translate
    }}</troi-btn>
  </div>
</div>
