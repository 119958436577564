import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AddProjectSubscriber } from '../add-project.subscriber';
import { Subject, Subscription } from 'rxjs';
import { AddProjectByOrderNumberService } from '../../../../services/add-project-by-order-number.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AddProjectResultInterface } from '../../../../interfaces/add-project-result.interface';
import { CPOptionInterface } from '../../../../interfaces/project-list.interface';

@Component({
  selector: 'troi-add-project-by-order-number',
  templateUrl: './add-project-by-order-number.component.html',
  styleUrls: ['./add-project-by-order-number.component.scss'],
})
export class AddProjectByOrderNumberComponent implements OnInit, OnDestroy {
  searchInputSubject = new Subject<string>();

  private addProjectSubscription: Subscription;

  open = false;

  private ORDER_LIST_URL = '/site/index.php?page=order_list';

  @Input() client: number;

  @Input() enabled = true;

  @Output() public dataSelected = new EventEmitter<AddProjectResultInterface>();

  constructor(
    public addProjectByOrderNumber: AddProjectByOrderNumberService,
    public addProjectSubscriber: AddProjectSubscriber,
  ) {
    this.searchInputSubject.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      if (this.addProjectByOrderNumber.search.length > 0) {
        this.addProjectByOrderNumber.initialLoadCPs();
        this.openChange(true);
      }
    });
  }

  openChange(state: boolean) {
    if (!this.enabled) {
      return;
    }
    this.open = state;
  }

  ngOnInit(): void {
    this.addProjectSubscription = this.addProjectSubscriber.selectCalculationPosition.subscribe(
      (selectedCp: CPOptionInterface) => {
        this.addProjectByOrderNumber.selectedCP = selectedCp;
      },
    );
    this.addProjectByOrderNumber.client = this.client;
    this.addProjectByOrderNumber.clearValue();
  }

  ngOnDestroy() {
    this.addProjectSubscription.unsubscribe();
  }

  getValueForSelectedOption() {
    return this.addProjectByOrderNumber.getTransValue(this.addProjectByOrderNumber.selectedCP.name);
  }

  clearValue() {
    this.addProjectByOrderNumber.clearValue();
  }

  addProjectAssignment() {
    if (!this.addProjectByOrderNumber.selectedCP) {
      return;
    }
    this.dataSelected.emit({
      projectReactivation: this.addProjectByOrderNumber.projectReactivation,
      customer: this.addProjectByOrderNumber.selectedCP.customer,
      project: this.addProjectByOrderNumber.selectedCP.project,
      subproject: this.addProjectByOrderNumber.selectedCP.subproject,
      cp: this.addProjectByOrderNumber.selectedCP.id,
    });
    this.clearValue();
  }

  onGoToOrderItem(): void {
    window.open(this.addProjectByOrderNumber.selectedCP.orderLink, '_blank');
  }

  onGoToOrderList(): void {
    window.open(this.ORDER_LIST_URL, '_blank');
  }
}
