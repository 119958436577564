import { Injectable } from '@angular/core';
import { FiltersServiceInterface } from '../../../../core/interfaces/filters-service.interface';
import { BasicFiltersService } from '../../../../core/services/basic-filters.service';
import { FooterSettingsService } from '../../../../core/services/footer-settings.service';
import { ParentWindowRef } from '../../../../core/services/parent-window-ref.service';
import { TabIdsEnum, TabsService } from '../../../../core/services/tabs.service';
import { StorageService } from '../../../../core/storage/storage.service';
import { TroiFilterSetsNetworkService } from '../../../../shared/troi-filter-sets/network/troi-filter-sets.network';
import { BookingsCommonNetwork } from '../../common/networks/bookings-common.network';
import { ApprovalStateService } from '../../common/services/approval-state.service';
import { BookingSettingsService } from '../../common/services/booking-settings.service';
import { BookingReceiptsFilters } from '../../common/services/filters.service';
import { PaymentMethodsService } from '../../common/services/payment-methods.service';

@Injectable()
export class FiltersService extends BookingReceiptsFilters implements FiltersServiceInterface {
  activeTab = TabIdsEnum.DIGITAL_INVOICE;

  constructor(
    public tabsService: TabsService,
    public localStorage: StorageService,
    public settingsService: BookingSettingsService,
    public parentWindowRef: ParentWindowRef,
    public basicFiltersService: BasicFiltersService,
    public footerSettingsService: FooterSettingsService,
    public commonNetwork: BookingsCommonNetwork,
    public paymentMethods: PaymentMethodsService,
    protected filterSetsNetworkService: TroiFilterSetsNetworkService,
    public approvalState: ApprovalStateService,
  ) {
    super(
      basicFiltersService,
      parentWindowRef,
      footerSettingsService,
      settingsService,
      localStorage,
      paymentMethods,
      tabsService,
      commonNetwork,
      filterSetsNetworkService,
    );
    this.actualFilters = this.defaultFilterValues();
    this.pageUrl = this.parentWindowRef.nativeWindow.location;
    this.basicFiltersService.clientsLoaded.subscribe(() => {
      this.actualFilters.dropdownFirst = this.getDefaultClientId();
      this.applyFiltersFromUrl();
      this.defaultClientAssigned.next(true);
    });
  }
}
