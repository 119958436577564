import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as _ from 'lodash';
import { TroiDropdownListModel } from '../../../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { DropdownEventInterface } from '../../../../../../../shared/troi-dropdown-select-lazy/interfaces/dropdown-event.interface';
import { UploadedFilesService } from '../../../../services/uploaded-files.service';

@Component({
  selector: 'attach-files-dropdown',
  templateUrl: './attach-files-dropdown.component.html',
  styleUrls: ['./attach-files-dropdown.component.scss'],
})
export class AttachFilesDropdownComponent implements OnChanges {
  optionsLoading = false;

  totalOptions = 0;

  pageSize = 10;

  page = 1;

  searchPhrase = '';

  options: Array<TroiDropdownListModel> = [];

  touched = false;

  @Input() initValues = [];

  @Input() preloadedOptions: Array<TroiDropdownListModel> = [];

  @Input() client;

  @Input() year;

  @Input() enabled = true;

  @Input() reloadPreloadedOptions;

  @Output() selected = new EventEmitter<Record<string, unknown>>();

  constructor(private uploadFilesService: UploadedFilesService) {}

  ngOnChanges(changes) {
    if (changes.year) {
      this.resetFileDropdown();
    }
    if (changes.preloadedOptions || changes.initValues || changes.year) {
      this.loadData();
    }
  }

  loadData(concat = true) {
    this.touched = true;
    this.optionsLoading = true;
    this.uploadFilesService
      .loadUnusedFiles(this.client, this.year, this.searchPhrase, this.page, this.pageSize)
      .subscribe((result) => {
        this.options = concat
          ? _.uniqBy(this.preloadedOptions.concat(this.options.concat(this.buildOptionList(result.data))), 'value')
          : this.preloadedOptions.concat(this.buildOptionList(result.data));
        this.totalOptions = result.totalCount;
        this.optionsLoading = false;
      });
  }

  buildOptionList(data): Array<TroiDropdownListModel> {
    const list = [];

    _.forEach(data, (element) => {
      list.push({
        label: element.isGroup ? element.name : `${element.name}.${element.extension}`,
        value: element.id,
        active: true,
        disabled: false,
        group: element.isGroup,
        fullObject: element,
      });
      _.forEach(element.children, (child) => {
        list.push({
          label: `${child.name}.${child.extension}`,
          value: child.id,
          active: true,
          disabled: true,
        });
      });
    });
    return list;
  }

  eventTriggered(event: DropdownEventInterface) {
    this.page = event.page;
    const searchTriggered = this.searchPhrase !== event.searchPhrase;
    this.searchPhrase = event.searchPhrase;
    this.loadData(!searchTriggered);
  }

  resetFileDropdown() {
    this.optionsLoading = false;
    this.totalOptions = 0;
    this.searchPhrase = '';
    this.options = [];
    this.touched = false;
  }
}
