import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectAssignmentDataService } from '../../../../../services/project-assignment-data.service';
import { SubprojectModel } from '../../../../../models/subproject.model';
import { Money } from '../../../../../../../../shared/troi-money/money';

@Component({
  selector: 'troi-pa-subproject',
  templateUrl: './pa-subproject.component.html',
  styleUrls: ['./pa-subproject.component.scss'],
})
export class PaSubprojectComponent {
  @Input() client: number;

  @Input() customer: string;

  @Input() parent: number;

  @Input() subproject: SubprojectModel;

  @Input() index: number;

  @Input() unassignedAmount: Money;

  @Input() isNet: boolean;

  @Input() netTotalAmount: Money;

  @Input() enabled = true;

  @Input() hasAutoCustomer = true;

  @Output() remove = new EventEmitter();

  @Output() taxChanged = new EventEmitter();

  constructor(public projectAssignmentData: ProjectAssignmentDataService) {}

  onSubprojectChange(data) {
    if (!this.enabled) {
      return;
    }
    this.subproject.change(data.fullObject);
    this.projectAssignmentData.splitAmountChanged.next(true);
  }

  addCP() {
    const cp = this.hasAutoCustomer ? this.projectAssignmentData.bookingSettingsService.getFallbackObjects().cp : null;

    if (this.subproject.getSubprojectId() !== null) {
      this.subproject.addNextCP(cp);
    }
  }

  delete() {
    this.remove.emit({
      id: this.subproject.internalId,
      containsData: this.subproject.cps.length > 0,
    });
  }

  removeCp(id: string) {
    if (!this.enabled && !this.hasAutoCustomer) {
      return;
    }
    this.subproject.removeCP(id);
    this.projectAssignmentData.splitAmountChanged.next(true);
  }
}
