<div class="attach-files">
  <troi-dropdown-select
    [multipleSelect]="true"
    [isLoading]="optionsLoading"
    [size]="'11px'"
    [lazyLoading]="true"
    [search]="true"
    placeholder="{{ 'Booking.labels.pleaseSelect' | translate }}"
    class="troi-dropdown-select--full"
    (searchEvent)="eventTriggered($event)"
    [totalOptions]="totalOptions"
    [options]="options"
    [clearButton]="true"
    [returnSelectedObject]="true"
    [initValue]="initValues"
    [predefinedOptions]="preloadedOptions"
    [reloadPreloadedOptions]="reloadPreloadedOptions"
    [onlyOneGroupSelect]="true"
    [enabled]="enabled"
    (selected)="selected.emit($event)"
  >
  </troi-dropdown-select>
</div>
