import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { firstValueFrom, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TranslationMode } from '../../../../../core/enums/translationMode';
import { CpAmounts } from '../../../../../core/interfaces/math-operation.interface';
import { StorageNotificationService } from '../../../../../core/notifications/storageNotification.service';
import { NumberPipe } from '../../../../../core/pipes/number.pipe';
import { AccessRightService } from '../../../../../core/services/access-right.service';
import { BasicFiltersService } from '../../../../../core/services/basic-filters.service';
import { LanguagesService } from '../../../../../core/services/languages.service';
import { MathOperationsService } from '../../../../../core/services/math-operations.service';
import { StorageListener } from '../../../../../core/storage/storage.listener';
import { StorageService } from '../../../../../core/storage/storage.service';
import { UserService } from '../../../../../core/user/user.service';
import { BaseModalDirective } from '../../../../../shared/troi-base-modal/baseModal.component';
import { TroiDiReportsEnum } from '../../../../../shared/troi-di-reports/enums/troi-di-reports.enum';
import { TroiDropdownListModel } from '../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { DomService } from '../../../../../shared/troi-modals/dom.service';
import { ModalService } from '../../../../../shared/troi-modals/modal.service';
import { TroiUserConfirmationComponent } from '../../../../../shared/troi-user-confirmation/troi-user-confirmation.component';
import { UserConfirmationEventEnum } from '../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { UserConfirmationSubscriber } from '../../../../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { AddProjectResultInterface } from '../../interfaces/add-project-result.interface';
import { BookingFormPermissionInterface } from '../../interfaces/booking-form-permission.interface';
import { BookingReceiptFullInterface } from '../../interfaces/booking-receipt-full.interface';
import { BookingSupplier, BookingTax } from '../../interfaces/booking-receipt.interface';
import { AmountModel } from '../../models/amount.model';
import { BookingReceiptFullModel } from '../../models/booking-receipt-full.model';
import { CpModel } from '../../models/cp.model';
import { ProjectModel } from '../../models/project.model';
import { SubprojectModel } from '../../models/subproject.model';
import { BookingsCommonNetwork } from '../../networks/bookings-common.network';
import { BookingSettingsService } from '../../services/booking-settings.service';
import { BookingsFormService } from '../../services/bookings-form.service';
import { CpService } from '../../services/cp.service';
import { PaymentMethodsService } from '../../services/payment-methods.service';
import { ProjectAssignmentDataService } from '../../services/project-assignment-data.service';
import { RefreshFormEmitter } from '../../services/refresh-form.emitter';
import { PaymentTermsInterface } from './terms/payment-terms.interface';

interface NumberConfig {
  digitsInfo: string;
  decimalMark: string;
  thousandsMark: string;
}

@Component({
  selector: 'booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
})
export class BookingFormComponent extends BaseModalDirective implements OnInit, OnDestroy {
  public modalObject: any;

  public submitted = false;

  public sectionOpened = 0;

  public supplierChangedLSKey = 'supplierWasChangedInBookingContext';

  public supplierInitData: Array<TroiDropdownListModel> = [];

  public reloadSupplierData;

  public bookingModel: BookingReceiptFullModel;

  public projectAssignmentDataLoading = false;

  public splitAmountChanged: Subscription;

  public permissions: BookingFormPermissionInterface;

  public paymentTerms;
  public basePaymentTerms;
  public approvalStatus;

  public translationMode = TranslationMode;

  @Output() reloadList;

  public paymentMethodsList: Array<TroiDropdownListModel> = [];

  public constructor(
    public bookingSettingsService: BookingSettingsService,
    public modalService: ModalService,
    public formService: BookingsFormService,
    public accessRightService: AccessRightService,
    public basicFilters: BasicFiltersService,
    public paymentMethods: PaymentMethodsService,
    public languagesService: LanguagesService,
    private storageListener: StorageListener,
    private commonNetwork: BookingsCommonNetwork,
    private mathOperations: MathOperationsService,
    private domService: DomService,
    private confirmationSubscriber: UserConfirmationSubscriber,
    private notificationService: StorageNotificationService,
    private translateService: TranslateService,
    private cpService: CpService,
    private refreshFormEmitter: RefreshFormEmitter,
    private projectAssignmentData: ProjectAssignmentDataService,
    private storageService: StorageService,
    private userService: UserService,
  ) {
    super(modalService, false);
    this.modalObject = this.modalService.object;
    this.permissions = this.modalObject.permissions;
    if (!this.modalObject.client) {
      this.modalObject.client = this.bookingSettingsService.settings.client;
    }

    if (this.modalObject.reportMessage) {
      switch (this.modalObject.reportMessage) {
        case TroiDiReportsEnum.SUPPLIER_MISSING:
          this.sectionOpened = 0;
          break;

        case TroiDiReportsEnum.PROJECT_MISSING:
          this.sectionOpened = 1;
          break;
        case TroiDiReportsEnum.COST_CENTER_MISSING:
        case TroiDiReportsEnum.ACCOUNT_MISSING:
        case TroiDiReportsEnum.COUNTER_ACCOUNT_MISSING:
          this.sectionOpened = 2;
          break;
        default:
          this.sectionOpened = 0;
          break;
      }
    }

    this.paymentMethodsList = this.paymentMethods.buildPaymentMethods(this.modalObject.client);
  }

  ngOnInit(): void {
    this.splitAmountChanged = this.projectAssignmentData.splitAmountChanged.subscribe(() => {
      this.onSplitAmountChange();
    });
    this.formService.submitted = false;

    if (this.isNew() && this.loggedUserData()) {
      this.initBookingModel(this.loggedUserData().id);
    } else {
      this.initBookingModel();
    }

    if (!this.isNew()) {
      this.formService.get(this.modalObject.id).subscribe((result) => {
        this.updateBookingForm(result.data);
        this.bookingModel.updateFilesFromResponse(result.data.files);
        this.paymentTerms = this.formService.buildPaymentTerms(
          this.bookingModel.getFormValueIdForKey('paymentTerms'),
          this.getNumberOfCashDiscounts(),
        );
        this.basePaymentTerms = { ...this.bookingModel.getFormValueIdForKey('paymentTerms') };
        if (result.data.supplier) {
          this.supplierInitData.push(
            this.generateSupplierOption(
              result.data.supplier.name,
              result.data.supplier.id,
              result.data.supplier.hasCreditorAccount,
            ),
          );
        }
      });
    } else {
      this.formService
        .fetchNextBookingIntNumber(this.bookingModel.getClient(), this.modalObject.year)
        .subscribe((result) => {
          this.bookingModel.internalNumber = result.data.nextEmptyInternalNumber;
        });
    }
    this.bookingSettingsService.prepareSettings(this.modalObject.client, true);
    this.storageListener.storageChanges.subscribe((changesData: any) => {
      if (changesData.key === this.supplierChangedLSKey) {
        this.supplierChangedInLegacy(changesData.value.id);
      }
    });
  }

  get numberConfig(): NumberConfig {
    const decimalPlacesNumber = this.bookingSettingsService.settings.settings.decimalPlacesNumber;
    return {
      digitsInfo: `1.${decimalPlacesNumber}-${decimalPlacesNumber}`,
      decimalMark: ',',
      thousandsMark: '',
    };
  }

  generateSupplierOption(name: string, id: string, hasCreditorAccount: boolean): TroiDropdownListModel {
    return {
      label: name,
      value: id,
      active: true,
      hasCreditorAccount,
      fullObject: { id, name },
    };
  }

  setSupplierPaymentTerms(supplier: BookingSupplier) {
    this.paymentTerms = this.formService.buildPaymentTerms(
      supplier?.paymentTerms[0] || this.basePaymentTerms,
      this.getNumberOfCashDiscounts(),
    );
    this.termsChanged(this.paymentTerms);
  }

  isNew(): boolean {
    return _.isUndefined(this.modalObject.id);
  }

  isBookingNumberCorrect(): boolean {
    return (
      this.bookingSettingsService.settings.settings.bookingReceiptInvoiceNumberIsOptional ||
      this.bookingModel.getFormValueIdForKey('number') !== ''
    );
  }

  initBookingModel(defaultPaidBy = null) {
    this.bookingModel = new BookingReceiptFullModel(
      this.formService.initForm(this.modalObject.client, defaultPaidBy),
      new AmountModel(
        null,
        null,
        this.formService.initMoneyValue('0', this.bookingSettingsService),
        this.formService.initMoneyValue('0', this.bookingSettingsService),
        true,
        null,
        false,
      ),
      {
        baseDataFinalized: false,
        projectAssignmentFinalized: false,
        accountingFinalized: false,
      },
      [],
    );
    this.bookingModel.supplierHasNotCreditAccount = false;
    this.bookingModel.cpService = this.cpService;
    this.bookingModel.unassignedAmount = this.formService.initMoneyValue('0', this.bookingSettingsService);
    this.bookingModel.accountingTotalForSplits = this.formService.initMoneyValue('0', this.bookingSettingsService);
    this.bookingModel.amount.totalNetAmount = this.formService.initMoneyValue('0', this.bookingSettingsService);
    this.bookingModel.notBillable = null;
    this.paymentTerms = this.formService.buildPaymentTerms(
      this.bookingModel.getFormValueIdForKey('paymentTerms'),
      this.getNumberOfCashDiscounts(),
    );
  }

  updateBookingForm(response: BookingReceiptFullInterface) {
    const pricePerUnit = response.isNet
      ? this.formService.initMoneyValue(response.netPricePerUnit, this.bookingSettingsService)
      : this.formService.initMoneyValue(response.pricePerUnit, this.bookingSettingsService);
    const totalPrice = response.isNet
      ? this.formService.initMoneyValue(response.netTotalPrice, this.bookingSettingsService)
      : this.formService.initMoneyValue(response.totalPrice, this.bookingSettingsService);
    this.bookingModel.updateModel(
      response,
      new AmountModel(
        response.quantity,
        response.unit ? response.unit.id : null,
        pricePerUnit,
        totalPrice,
        response.isNet,
        response.isMultipleTax ? this.getMultipleTaxObject() : response.tax,
        response.isQuantified,
        this.formService.initMoneyValue(response.netTotalPrice, this.bookingSettingsService),
        this.formService.initMoneyValue(response.netPricePerUnit, this.bookingSettingsService),
      ),
      this.bookingSettingsService,
    );
    this.refreshFormEmitter.refresh();
    this.onSplitAmountChange();
  }

  canCreateSupplier(): boolean {
    return this.accessRightService.isWriteAccess(this.bookingSettingsService.settings.permissions.supplier);
  }

  sectionStateChanged(data) {
    if (data.state === true) {
      this.sectionOpened = data.id;
    }
  }

  shouldSectionBeOpened(id: number) {
    return this.sectionOpened === id;
  }

  showQuantityData(): boolean {
    return this.bookingSettingsService.settings.settings.allowQuantity && this.bookingModel.amount.isQuantified;
  }

  refreshTotalPrice() {
    this.bookingModel.amount.totalPrice.initWithNewValue('0');
  }

  async supplierSelected(supplier: BookingSupplier) {
    const originalValue = this.bookingModel.getFormValueIdForKey('supplier');
    this.bookingModel.formValues.patchValue({
      supplier,
    });
    this.bookingModel.supplierHasNotCreditAccount = supplier && !supplier.hasCreditorAccount;
    this.bookingModel.creditorAccount = supplier ? supplier.creditorAccount : null;
    this.bookingModel.counterAccount = supplier ? supplier.counterAccount : null;

    if (supplier && originalValue === null && this.modalObject.isDigitalInvoice) {
      await this.overwriteDigitalInvoiceTax(supplier.id);
    }

    if (supplier && this.bookingSettingsService.settings.settings.bookkeepingAccountAssignment) {
      this.bookingModel.formValues.patchValue({ account: null });
      if (this.bookingModel.isMultipleCps()) {
        this.replaceCounterAccountsForSplits(supplier);
      } else {
        this.replaceCounterAccountForSingle(supplier);
      }
    }

    this.setSupplierPaymentTerms(supplier);
  }

  automaticReplaceData(): boolean {
    return !this.bookingSettingsService.settings.settings.bookkeepingIgnoreAutomaticDataEntry;
  }

  async overwriteDigitalInvoiceTax(supplierId: string): Promise<void> {
    const result = await firstValueFrom(
      this.projectAssignmentData
        .openConfirmationPopup(
          'Booking.digitalInvoice.overwriteTax.title',
          'Booking.digitalInvoice.overwriteTax.confirm',
          'Common.labels.cancel',
          'Booking.digitalInvoice.overwriteTax.description',
          '666px',
        )
        .pipe(first()),
    );

    if (result !== UserConfirmationEventEnum.EXECUTE) return;

    await firstValueFrom(this.formService.patchSupplierTax(supplierId, this.modalObject.id).pipe(first()));

    return;
  }

  replaceCounterAccountForSingle(supplier: BookingSupplier) {
    const actualAccountId = this.bookingModel.getFormValueIdForKey('counterAccount');
    const replaceData =
      actualAccountId !== null &&
      (supplier.creditorAccount === null || actualAccountId !== supplier.creditorAccount.id);
    if (replaceData) {
      this.projectAssignmentData
        .openConfirmationPopup(
          'Booking.labels.resetCounterAccount',
          'Booking.labels.reset',
          'Common.labels.cancel',
          'Booking.labels.doYouReallyWantsResetCounterAccount',
        )
        .pipe(first())
        .subscribe((result) => {
          if (result === UserConfirmationEventEnum.EXECUTE) {
            this.bookingModel.formValues.patchValue({
              account: supplier.counterAccount,
              counterAccount: supplier.creditorAccount,
            });
          }
        });
    } else {
      this.bookingModel.formValues.patchValue({
        account: supplier.counterAccount,
        counterAccount: supplier.creditorAccount,
      });
    }
  }

  replaceCounterAccountsForSplits(supplier: BookingSupplier) {
    if (this.bookingModel.hasAtLeastOneDifferentCounterAccountInSplits(supplier.creditorAccount.id)) {
      this.projectAssignmentData
        .openConfirmationPopup(
          'Booking.labels.resetCounterAccount',
          'Booking.labels.reset',
          'Common.labels.cancel',
          'Booking.labels.doYouReallyWantsResetCounterAccount',
        )
        .pipe(first())
        .subscribe((result) => {
          if (result === UserConfirmationEventEnum.EXECUTE) {
            this.bookingModel.updateSplitAccountAndCounterAccounts(supplier.counterAccount, supplier.creditorAccount);
          }
        });
    } else {
      this.bookingModel.updateSplitAccountAndCounterAccounts(supplier.counterAccount, supplier.creditorAccount);
    }
  }

  openUpdateSupplierWindow() {
    const url = `/prog/lieferant_details.php?id_lieferant=${this.bookingModel.getFormValueIdForKey(
      'supplier',
    )}&readonly=0&context=booking`;
    window.open(url, 'name', 'width=600,height=400');
  }

  openCreateSupplierWindow() {
    if (!this.canWriteBaseData()) {
      return;
    }
    const url = `/prog/lieferant_details.php?formaction=new_supplier&context=booking&client_id=${this.modalObject.client}`;
    window.open(url, 'name', 'width=600,height=400');
  }

  supplierChangedInLegacy(id: string) {
    this.commonNetwork.getDataForSelected(null, null, null, id, null, null).subscribe((result) => {
      this.supplierInitData = [];
      this.supplierInitData.push(
        this.generateSupplierOption(result.supplier.name, result.supplier.id, result.supplier.hasCreditorAccount),
      );
      this.supplierSelected(result.supplier);
      this.reloadSupplierData = Math.random();
      if (id === this.bookingModel.getFormValueIdForKey('supplier')) {
        this.bookingModel.supplierHasNotCreditAccount = !result.supplier.hasCreditorAccount;
      }
    });
  }

  save(closeWhenSavingSucceed = false) {
    this.formService.submitted = true;
    if (!this.canSave()) {
      return;
    }
    if (this.bookingModel.multipleCps && !this.bookingModel.unassignedAmount.isZero()) {
      this.multipleCpsIsNotBalancedModal(closeWhenSavingSucceed);
      return;
    }
    this.saveForm(closeWhenSavingSucceed);
  }

  saveForm(closeWhenSavingSucceed) {
    this.bookingModel.startSavingCps.pipe(first()).subscribe(() => {
      this.setIsAccountingUpdate();
      this.formService.save(this.bookingModel.toBackendRequest(), this.bookingModel.isNew()).subscribe((result) => {
        this.updateBookingForm(result.data);
        const transMessage = this.bookingModel.hasSelectedAtLeastOneNotAutomaticCp(this.bookingSettingsService)
          ? 'Booking.labels.dataSuccessfullySavedWithBilling'
          : 'Common.labels.dataSuccessfullySaved';
        this.translateService.get(transMessage).subscribe((message) => {
          this.notificationService.showSuccess(message);
        });

        this.formService.assignFilesService
          .assignFilesToBR(result.data.id, this.bookingModel.filesToBackendRequest())
          .subscribe((resultFiles) => {
            this.bookingModel.updateFilesFromResponse(resultFiles.data.files);
            this.reloadList.emit(true);
          });

        if (closeWhenSavingSucceed) {
          this.wantCloseModal();
        }
      });
    });
    this.bookingModel.saveAndUpdateCPs();
  }

  canSave(): boolean {
    return (
      this.bookingModel.formValid() &&
      this.isBookingNumberCorrect() &&
      this.isPaidByEmployeeValid() &&
      !this.isReadOnlyMode() &&
      !this.isBaseDataTaxInvalid()
    );
  }

  baseDataFilledCorrectly() {
    return this.bookingModel.formValid() && this.isBookingNumberCorrect() && !this.isBaseDataTaxInvalid();
  }

  termsChanged(terms: PaymentTermsInterface) {
    this.bookingModel.formValues.patchValue({
      paymentTerms: {
        overduePaymentLimit: terms.net,
        discount1Days: terms.discounts[0] ? terms.discounts[0].days : 0,
        discount1Rate: terms.discounts[0] ? terms.discounts[0].rate : '0',
        discount2Days: terms.discounts[1] ? terms.discounts[1].days : 0,
        discount2Rate: terms.discounts[1] ? terms.discounts[1].rate : '0',
        discount3Days: terms.discounts[2] ? terms.discounts[2].days : 0,
        discount3Rate: terms.discounts[2] ? terms.discounts[2].rate : '0',
        discount4Days: terms.discounts[3] ? terms.discounts[3].days : 0,
        discount4Rate: terms.discounts[3] ? terms.discounts[3].rate : '0',
      },
    });

    this.paymentTerms = terms;
  }

  recalculateAmount() {
    if (_.isNull(this.bookingModel.amount.quantity)) {
      return;
    }
    this.mathOperations
      .multiply(this.bookingModel.amount.quantity.replace(',', '.'), this.bookingModel.amount.pricePerUnit.forBackend)
      .subscribe((result) => {
        this.bookingModel.amount.totalPrice.initWithNewValue(result.data.result);
        this.updateNetAmount();
      });
  }

  resetQuantityFields() {
    this.bookingModel.amount.quantity = null;
    this.bookingModel.amount.pricePerUnit.update('0');
  }

  totalAmountChanged(value) {
    this.bookingModel.amount.totalPrice.update(value, true);
    if (!this.bookingModel.amount.isQuantified) {
      this.resetQuantityFields();
    }
    this.updateNetAmount();
  }

  updateNetAmount() {
    if (this.bookingModel.amount.isNet || this.bookingModel.amount.isEmptyTax()) {
      this.bookingModel.amount.totalNetAmount = _.cloneDeep(this.bookingModel.amount.totalPrice);
    } else {
      this.mathOperations
        .calculateNetGrossValues(
          this.bookingModel.amount.totalPrice.forBackend,
          this.bookingModel.amount.isNet,
          this.bookingModel.amount.getTaxId(),
        )
        .subscribe((result) => {
          this.bookingModel.amount.totalNetAmount = this.formService.initMoneyValue(
            result.data.netValue,
            this.bookingSettingsService,
          );
        });
    }
    if (this.bookingModel.isMultipleCps()) {
      this.onSplitAmountChange();
    }
  }

  updateQuantity(value) {
    this.bookingModel.amount.quantity = value;
    this.recalculateAmount();
  }

  getFormattedNumber(value) {
    if (!value) {
      return '';
    }
    const _value = String(value).replace(',', '.');
    return new NumberPipe(new DecimalPipe('en-US')).transform(_value, this.numberConfig);
  }

  updatePricePerUnit(value) {
    this.bookingModel.amount.pricePerUnit.update(value, true);
    this.recalculateAmount();
  }

  updateBookingDate(value) {
    this.bookingModel.validDate = value.isValid;
    this.bookingModel.formValues.patchValue({
      bookingDate: value.date[0],
    });
  }

  updateProvisionDate(value) {
    this.bookingModel.formValues.patchValue({
      provisionDate: value.date[0],
    });
  }

  getNumberOfCashDiscounts(): number {
    return Math.min(4, this.bookingSettingsService.settings.settings.numberOfCashDiscounts);
  }

  generateDropdownOption(data): Array<TroiDropdownListModel> {
    return data === null || !data.name
      ? []
      : [
          {
            label: !data.name.en ? data.name : this.languagesService.getLanguageValue(data.name),
            value: data.id,
            active: true,
            fullObject: data,
          },
        ];
  }

  generateMultipleTaxOption(): Array<TroiDropdownListModel> {
    const data = this.getMultipleTaxObject();
    return this.generateDropdownOption({
      name: data.name,
      id: data.id,
      fullObject: data,
    });
  }

  getMultipleTaxObject(): BookingTax {
    return {
      id: -1,
      name: this.translateService.instant('Booking.labels.multipleTax'),
    };
  }

  onProjectAssignmentSelected(data: AddProjectResultInterface) {
    this.bookingModel.projectReactivation = data.projectReactivation;
    if (!this.isSingleProjectAssignmentFilled()) {
      this.fillDropdownData(data);
      return;
    }
    this.projectAssignmentData
      .openConfirmationPopup()
      .pipe(first())
      .subscribe((result) => {
        if (result === UserConfirmationEventEnum.EXECUTE) {
          this.fillDropdownData(data);
        }
      });
  }

  onMultipleCpCheckboxChange() {
    if (!this.canWriteProjectAssignment()) {
      return;
    }
    const value = !this.bookingModel.isMultipleCps();
    if (value) {
      this.onSplitAmountChange();
    }
    if ((value && this.bookingModel.isSinglePATouched()) || (!value && this.bookingModel.projects.length > 0)) {
      this.projectAssignmentData
        .openConfirmationPopup()
        .pipe(first())
        .subscribe((result) => {
          if (result === UserConfirmationEventEnum.EXECUTE) {
            this.bookingModel.multipleCps = value;
            this.bookingModel.onMultipleCpCheckboxChange();
          }
        });
    } else {
      this.bookingModel.multipleCps = value;
    }
  }

  fillDropdownData(data: AddProjectResultInterface) {
    this.projectAssignmentDataLoading = true;
    this.bookingModel.showProjectAssignmentRowsForSingle = false;
    this.formService
      .fetchSelected([
        {
          key: 'customer',
          value: data.customer,
        },
        {
          key: 'project',
          value: data.project,
        },
        {
          key: 'subproject',
          value: data.subproject,
        },
        {
          key: 'cp',
          value: data.cp ? data.cp : null,
        },
      ])
      .subscribe((resultData) => {
        this.projectAssignmentDataLoading = false;
        this.bookingModel.showProjectAssignmentRowsForSingle = true;
        this.bookingModel.formValues.patchValue({
          customer: resultData.customer,
          project: resultData.project,
          subproject: resultData.subproject,
          cp: resultData.cp,
          notBillable: resultData.cp?.notBillable,
        });
      });
  }

  showSinglePADropdowns(): boolean {
    return this.bookingModel.showProjectAssignmentRowsForSingle && !this.bookingModel.isMultipleCps();
  }

  isSingleProjectAssignmentFilled(): boolean {
    return this.bookingModel.formValues.get('customer').value !== null;
  }

  getFormValueForKey(key: string) {
    return this.bookingModel.formValues.get(key).value;
  }

  setFormValueForKey(key: string, value) {
    this.bookingModel.formValues.get(key).setValue(value);
  }

  onCustomerChange(id) {
    this.onProjectChange(null);
    this.setFormValueForKey('customer', id);
  }

  onProjectChange(id) {
    this.onSubprojectChange(null);
    this.setFormValueForKey('project', id);
  }

  onSubprojectChange(id) {
    this.setFormValueForKey('cp', null);
    this.setFormValueForKey('subproject', id);
  }

  getIdFromDropdown(dropdownName: string) {
    const value = this.getFormValueForKey(dropdownName);
    return value ? (value.id ? value.id : value) : null;
  }

  shouldReloadSubProjects(): boolean {
    return !!this.getFormValueForKey('project');
  }

  multipleCpsIsNotBalancedModal(closeWhenSavingSucceed) {
    const secondModalService = new ModalService(this.domService);
    const translations = {
      title: 'Common.labels.attention',
      executeBtnTitle: 'Common.labels.continue',
      cancelBtnTitle: 'Common.labels.cancel',
      description: 'Booking.labels.invoiceAmountDoesntMatchSprintAmount',
    };
    secondModalService.init(TroiUserConfirmationComponent, { translations }, {}, '400px', '500px', true);
    this.confirmationSubscriber.action.pipe(first()).subscribe((result) => {
      if (result === UserConfirmationEventEnum.EXECUTE) {
        this.saveForm(closeWhenSavingSucceed);
      }
    });
  }

  wantCloseModal() {
    if (!this.bookingModel.modelHasChanged()) {
      this.close();
      return;
    }
    const secondModalService = new ModalService(this.domService);
    const translations = {
      title: 'Booking.labels.closeWithoutSaving',
      executeBtnTitle: 'Booking.labels.close',
      cancelBtnTitle: 'Common.labels.cancel',
      description: 'Booking.labels.doYouReallyWantToCloseWithoutSaving',
    };
    secondModalService.init(TroiUserConfirmationComponent, { translations }, {}, '400px', '500px', true);
    this.confirmationSubscriber.action.pipe(first()).subscribe((result) => {
      if (result === UserConfirmationEventEnum.EXECUTE) {
        this.close();
      }
    });
  }

  showModel() {
    console.log(this.bookingModel.files);
  }

  onCpChanged(data) {
    if (!data.isNew) {
      this.setFormValueForKey('cp', data.cp);
      this.setFormValueForKey('notBillable', data.cp.notBillable);
    } else {
      const model = new CpModel(null);
      model.isNew = true;
      model.client = this.bookingModel.getClient();
      model.customer = this.bookingModel.getFormValueIdForKey('customer');
      model.project = this.bookingModel.getFormValueIdForKey('project');
      model.subproject = this.bookingModel.getFormValueIdForKey('subproject');
      model.nameToCreate = data.name;
      model.counterAccount = this.bookingModel.creditorAccount;
      model.notBillable = false;
      this.setFormValueForKey('cp', model);
      this.setFormValueForKey('notBillable', model.notBillable);
    }
  }

  onBaseDataTaxChange(tax) {
    this.bookingModel.changeBaseDataTax(tax);
    this.updateNetAmount();
  }

  onTaxInMultipleCpChanged() {
    this.bookingModel.checkIfSplitsHasDiffTax();
    this.onSplitAmountChange();
  }

  isBaseDataTaxInvalid(): boolean {
    return (
      this.formService.submitted &&
      this.bookingModel.amount.tax &&
      this.bookingModel.amount.tax.id === -1 &&
      this.bookingModel.isTaxRequired()
    );
  }

  generateTaxPlaceholder(): string {
    return this.bookingModel.isMultipleCps() && this.bookingModel.hasDifferentTaxInSplits
      ? 'Booking.labels.multipleTax'
      : 'Booking.labels.0none';
  }

  generateAmountPlaceholder(): string {
    return '0,00';
  }

  generateTaxValidationMessage(): string {
    return !this.bookingModel.isMultipleCps() && this.bookingModel.amount.isMultipleTaxOptionSelected()
      ? 'Booking.labels.multipleTaxForSplitsAllowed'
      : 'Form.Error.Required';
  }

  onSplitAmountChange() {
    this.mathOperations
      .countUnassignedAmount(
        this.bookingModel.amount.isNet,
        this.bookingModel.amount.getTaxId(),
        this.bookingModel.amount.totalPrice.forBackend,
        this.bookingModel.getAllSplitAmounts(),
      )
      .subscribe((result) => {
        if (this.bookingModel.amount.isNet) {
          this.bookingModel.unassignedAmount = this.formService.initMoneyValue(
            result.data.unassignedNetAmount,
            this.bookingSettingsService,
          );
        } else {
          this.bookingModel.unassignedAmount = this.formService.initMoneyValue(
            result.data.unassignedGrossAmount,
            this.bookingSettingsService,
          );
        }
        this.updateSplitAmounts(result.data.rightOperands);
      });

    this.mathOperations.sum(this.bookingModel.getSplitAmounts()).subscribe((result) => {
      this.bookingModel.accountingTotalForSplits = this.formService.initMoneyValue(
        result.data.result,
        this.bookingSettingsService,
      );
    });
  }

  updateSplitAmounts(cpAmounts: CpAmounts[]): void {
    _.forEach(this.bookingModel.projects, (project: ProjectModel) => {
      _.forEach(project.subprojects, (subproject: SubprojectModel) => {
        _.forEach(subproject.cps, (cp: CpModel) => {
          const amountsToUpdate = cpAmounts.find((item) => item.internalId === cp.internalId);
          if (amountsToUpdate) {
            cp.amount = this.formService.initMoneyValue(amountsToUpdate.amount, this.bookingSettingsService);
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.splitAmountChanged.unsubscribe();
  }

  hasSelectedAutoSubproject(): boolean {
    const projectAssigmentSettings = {
      ...this.bookingSettingsService.settings.fallbackObjects.projectAssignment,
    };

    if (projectAssigmentSettings.isManualProjectAssignment) {
      return false;
    }

    return (
      projectAssigmentSettings.subProject.id === this.bookingModel.getFormValueIdForKey('subproject') &&
      !projectAssigmentSettings.areOwnFallbacks
    );
  }

  hasSelectedAutoProject(): boolean {
    const projectAssigmentSettings = {
      ...this.bookingSettingsService.settings.fallbackObjects.projectAssignment,
    };

    if (projectAssigmentSettings.isManualProjectAssignment) {
      return false;
    }

    return (
      projectAssigmentSettings.project.id === this.bookingModel.getFormValueIdForKey('project') &&
      !projectAssigmentSettings.areOwnFallbacks
    );
  }

  isPaidChanged(value) {
    this.bookingModel.formValues.patchValue({ paid: value });
  }

  paidByChanged(id) {
    this.bookingModel.formValues.patchValue({ paidBy: id });
  }

  initUserOption(): Array<TroiDropdownListModel> {
    const user = this.loggedUserData();
    const loggedUserOption = {
      label: `${user.name} ${user.surname}`,
      value: parseInt(user.id.toString()),
      active: true,
    };
    return !this.bookingModel.paidBy() || !this.bookingModel.paidBy().name
      ? [loggedUserOption]
      : [
          loggedUserOption,
          {
            label: this.bookingModel.paidBy().name,
            value: this.bookingModel.paidBy().id,
            active: true,
          },
        ];
  }

  loggedUserData() {
    return this.userService.getUserData();
  }

  isPaidByEmployeeValid(): boolean {
    return !this.bookingModel.getFormValueIdForKey('paidByEmployee') || this.bookingModel.paidBy() !== null;
  }

  canWriteBaseData(): boolean {
    return !this.permissions || this.accessRightService.isWriteAccess(this.permissions.baseData);
  }

  canWriteProjectAssignment(): boolean {
    return !this.permissions || this.accessRightService.isWriteAccess(this.permissions.projectAssignment);
  }

  canWriteAccounting(): boolean {
    return !this.permissions || this.accessRightService.isWriteAccess(this.permissions.accounting);
  }

  isReadOnlyMode(): boolean {
    return !this.canWriteBaseData() && !this.canWriteProjectAssignment() && !this.canWriteAccounting();
  }

  togglePaidByEmployee() {
    if (!this.bookingModel.getFormValueIdForKey('paidByEmployee')) {
      this.bookingModel.formValues.patchValue({ paidBy: this.loggedUserData()?.id });
    }
  }

  shouldDisableSaveButton(): boolean {
    return this.isReadOnlyMode();
  }

  onChangeApprovalStatus(): void {
    this.reloadList.emit(true);
  }

  setIsAccountingUpdate() {
    this.bookingModel.isAccountingUpdate =
      !this.canWriteBaseData() && !this.canWriteProjectAssignment() && this.canWriteAccounting();
  }
}
