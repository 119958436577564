import { Component, EventEmitter, Input, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { ProjectAssignmentDataService } from '../../../../services/project-assignment-data.service';
import { ProjectModel } from '../../../../models/project.model';
import { UserConfirmationEventEnum } from '../../../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { Money } from '../../../../../../../shared/troi-money/money';

@Component({
  selector: 'troi-pa-project',
  templateUrl: './pa-project.component.html',
  styleUrls: ['./pa-project.component.scss'],
})
export class PaProjectComponent {
  @Input() client: number;

  @Input() project: ProjectModel;

  @Input() index: number;

  @Input() unassignedAmount: Money;

  @Input() netTotalAmount: Money;

  @Input() enabled = true;

  @Input() isNet: boolean;

  @Output() remove = new EventEmitter<string>();

  @Output() taxChanged = new EventEmitter();

  constructor(public projectAssignmentData: ProjectAssignmentDataService) {}

  onCustomerChange(id) {
    if (!this.enabled) {
      return;
    }
    if (this.project.getProjectId() === null) {
      this.project.customerChange(id);
      return;
    }
    this.projectAssignmentData
      .openConfirmationPopup()
      .pipe(first())
      .subscribe((result) => {
        if (result === UserConfirmationEventEnum.EXECUTE) {
          this.project.customerChange(id);
          this.projectAssignmentData.splitAmountChanged.next(true);
        }
      });
  }

  onProjectChange(id) {
    if (!this.enabled) {
      return;
    }
    if (this.project.subprojects.length === 0) {
      this.project.change(id);
      return;
    }
    this.projectAssignmentData
      .openConfirmationPopup()
      .pipe(first())
      .subscribe((result) => {
        if (result === UserConfirmationEventEnum.EXECUTE) {
          this.project.change(id);
          this.projectAssignmentData.splitAmountChanged.next(true);
        }
      });
  }

  addSubproject() {
    if (this.project.getProjectId() !== null) {
      this.project.collapseSubprojectSections();
      this.project.addNextSubproject();
    }
  }

  delete() {
    this.projectAssignmentData
      .openConfirmationPopup()
      .pipe(first())
      .subscribe((result) => {
        if (result === UserConfirmationEventEnum.EXECUTE) {
          this.remove.emit(this.project.internalId);
        }
      });
  }

  removeSubproject(data) {
    if (!this.enabled) {
      return;
    }
    if (data.containsData === false) {
      this.project.removeSubproject(data.id);
      this.projectAssignmentData.splitAmountChanged.next(true);
      return;
    }
    this.projectAssignmentData
      .openConfirmationPopup()
      .pipe(first())
      .subscribe((result) => {
        if (result === UserConfirmationEventEnum.EXECUTE) {
          this.project.removeSubproject(data.id);
          this.projectAssignmentData.splitAmountChanged.next(true);
        }
      });
  }

  get hasSelectedAutoCustomer(): boolean {
    const projectAssigmentSettings = {
      ...this.projectAssignmentData.bookingSettingsService.settings.fallbackObjects.projectAssignment,
    };

    if (projectAssigmentSettings.isManualProjectAssignment) {
      return false;
    }

    return (
      projectAssigmentSettings.customer.id === this.project.getCustomerId() && !projectAssigmentSettings.areOwnFallbacks
    );
  }
}
