import { Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AddProjectByKnumberService } from '../../../../../services/add-project-by-knumber.service';
import { BaseAddProjectListComponent } from '../../../base-add-project-list/base-add-project-list.component';

@Component({
  selector: 'troi-add-project-by-knumber-list',
  templateUrl: './../../../base-add-project-list/base-add-project-list.component.html',
  styleUrls: ['./../../../base-add-project-list/base-add-project-list.component.scss'],
})
export class AddProjectByKnumberListComponent extends BaseAddProjectListComponent {
  @ViewChild('optionList') optionList;
  @ViewChild('optionWrapper') optionWrapper;
  @Output() openChange = new EventEmitter<boolean>();

  constructor(element: ElementRef, injector: Injector, public addProjectByService: AddProjectByKnumberService) {
    super(element, injector, addProjectByService);
  }
}
